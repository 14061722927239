import { IStyles, pxToRem, pxTovW, theme } from '@geneo2-web/shared-ui';
import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
const styles: IStyles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    my: { xs: pxToRem(10), md: pxTovW(10) },
  },
};
interface IBloomLevel {
  title: string;
  Questions: number;
  color: string;
}
interface IProps {
  bloomLevelData: IBloomLevel[];
}
export const BloomLevelComponent = (props: IProps) => {
  const { bloomLevelData } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box sx={styles.root}>
      <Typography variant={isMobile ? 'h2' : 'h3'} fontWeight="bold">
        Bloom Level Breakdown
      </Typography>
      <Box
        sx={{
          display: 'flex',
          borderRadius: pxTovW(4),
          width: '100%',
        }}
      >
        {bloomLevelData.map((elem, index) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 'max-content',
                height: '100%',
                backgroundColor: elem.color,
                justifyContent: 'center',
                alignItems: 'center',
                gap: { xs: pxToRem(10), md: pxTovW(5) },
                paddingY: { xs: pxToRem(10), md: pxTovW(10) },
                paddingX: { xs: pxToRem(10), md: pxTovW(10) },
                flexGrow: 1,
              }}
            >
              <Typography
                variant="h3"
                fontWeight="bold"
                sx={{ color: 'white' }}
              >
                {elem.Questions.toFixed(0)}% Qs
              </Typography>
              <Typography variant="smallText" sx={{ color: 'white' }}>
                {elem.title}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const BloomLeveldata = [
  {
    title: 'Retention',
    Questions: 3,
    color: '#005DAA',
  },
  {
    title: 'Analysis',
    Questions: 1,
    color: '#F1A33B',
  },
  {
    title: 'Application',
    Questions: 2,
    color: '#009383',
  },
  {
    title: 'Comprehension',
    Questions: 4,
    color: '#5DC152',
  },
];
