import {
  callTeacherEventsApi,
  clearLocalStorageKeys,
  deleteOfflineAccessKeyFromCache,
  deserify,
  getDeviceType,
  getEnvConfig,
  getLocalStorage,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import {
  DeviceEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDownloadContext } from '../app/Context/DownloadContextProviderV2';
import { resetAnalyticsState } from '../pages/Analytics/reducer/analytics.slice';
import { aiLogoutEvent, interactionEvent } from '../pages/Auth/auth.events';
import {
  resetAuthState,
  setContactPopupOpen,
} from '../pages/Auth/reducer/auth.slice';
import { aiBookCloseEvent } from '../pages/Home/home.events';
import { resetHomeDashboardState } from '../pages/Home/reducer/homeDashboard.slice';
import { aiHomeworkCloseEvent } from '../pages/Homework/homework.events';

import { resetManageHWState } from '../pages/ManageHomework/reducer/manageHomework.slice';
import { resetConnectedClassState } from '../pages/Teach/reducer/connectedClass.slice';
import { resetTeachState } from '../pages/Teach/reducer/teach.slice';
import {
  aiChapterCloseEvent,
  aiLessonCloseEvent,
  aiTopicCloseEvent,
} from '../pages/Teach/teach.events';
import { useAppDispatch, useAppSelector } from '../reduxStore/reduxHooks';
import {
  CHANGE_PASSWORD,
  DISCLAIMER,
  DOWNLOAD_SETTINGS,
  HELP,
  LOGIN,
  PROFILE,
} from '../routeHandling/RoutesNomenclature';
import { resetAssessmentFlowState } from '../pages/Assessment/reducer/assessmentFlow.slice';

declare global {
  interface Window {
    Android: any;
  }
}

export const useSideBarOptions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const android = window.Android;
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const { chapter_resources, selected_topic_info, selected_lessons_info } =
    deserify(useAppSelector((state) => state.teach));
  const { selected_assessment_id } = deserify(
    useAppSelector((state) => state.manageHomework)
  );
  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    UMSLoginAPIServiceV1ClientWithStatusCodeHandler,
    UmsUtilityAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;

  const { isOffline } = useDownloadContext();
  const { is_logged_in_offline, ssoRedirectionUrl } = deserify(
    useAppSelector((state) => state.auth)
  );
  console.log('sidebaroptions', isOffline, is_logged_in_offline);
  const isOfflineFrontend = isOffline || is_logged_in_offline;
  const deviceType = getDeviceType();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const lessonSessionId =
    new URLSearchParams(location.search).get('lessonSessionId') || undefined;
  const { selected_resource_session_id } = deserify(
    useAppSelector((state) => state.teach)
  );

  const userId = getLocalStorage('userId');
  const fcmToken = getLocalStorage('fcmToken'); // Convert fcmToken to string
  const deviceId = getLocalStorage('deviceID');

  const isMCBUser = deserify(useAppSelector((state) => state.auth.is_mcb_user));
  const config = getEnvConfig();

  const NotificationAPI = async () => {
    if (!userId) {
      console.log('User ID not found');
      return;
    }

    try {
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        profileId: BigInt(userId),
        role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
        deviceType: DeviceEnum.DEVICE_TYPE_WEB,
        deviceToken: fcmToken?.toString(),
        isActive: false,
        deviceId: deviceId ? BigInt(deviceId) : undefined,
      });
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        deviceToken: fcmToken?.toString(),
        topic: `${String(userId)}_${ProfileRolesEnum.PROFILE_ROLE_TEACHER}`,
        subscribeTopic: false,
      });
    } catch (err) {
      console.error('Error in Notification API:', err);
    }
  };

  const closeAiFunctions = async (buttonName: string) => {
    // aiBookCloseEvent(class_subject_info?.bookId);
    await aiLessonCloseEvent({
      lessonId: selected_lessons_info?.lessonId,
      isOffline: isOffline,
    });
    await aiTopicCloseEvent({
      topicId: selected_topic_info?.topicId,
      isOffline: isOffline,
    });
    await aiChapterCloseEvent({
      chapterId: chapter_resources?.chapterId,
      isOffline: isOffline,
    });
    await aiHomeworkCloseEvent({
      homeworkId: selected_assessment_id,
      isOffline: isOffline,
    });

    await interactionEvent({
      url: '',
      context: 'Navigation_bar',
      name: buttonName,
      pathSegments: pathSegments,
      lessonSessionId: lessonSessionId,
      resourceSessionId: selected_resource_session_id?.toString(),
      isOffline: isOffline,
    });
  };

  const sidebarArray = [
    {
      name: 'Profile',
      icon: 'profile-icon',
      onClick: async () => {
        await closeAiFunctions('PROFILE');
        navigate(PROFILE);
      },
      route: PROFILE,
    },
    // {
    //   name: 'Notification',
    //   icon: 'bell',
    //   onClick: async () => {
    //     // navigate(NOTIFICATION);
    //     await closeAiFunctions('NOTIFICATION');
    //   },
    // },
    {
      name: 'Contact Us',
      icon: 'contact',
      onClick: async () => {
        await interactionEvent({
          url: '',
          context: 'Navigation_bar',
          name: 'CONTACT',
          pathSegments: pathSegments,
          lessonSessionId: lessonSessionId,
          resourceSessionId: selected_resource_session_id?.toString(),
          isOffline: isOffline,
        });

        dispatch(setContactPopupOpen(true));
      },
      route: undefined,
    },
    {
      name: 'Help & Support',
      icon: 'support1',
      onClick: async () => {
        await closeAiFunctions('HELP_&_SUPPORT');
        navigate(HELP);
      },
      route: HELP,
    },
    {
      name: 'Terms & Conditions',
      icon: 'tnc',
      onClick: async () => {
        await closeAiFunctions('DISCLAIMER_&_POLICIES');
        navigate(DISCLAIMER);
      },
      route: DISCLAIMER,
    },
    {
      name: 'Download Settings',
      icon: 'headerSettings',
      onClick: async () => {
        await closeAiFunctions('DOWNLOAD_SETTINGS');
        navigate(DOWNLOAD_SETTINGS);
      },
      route: DOWNLOAD_SETTINGS,
    },
    {
      name: 'Sign out',
      icon: 'sign-out',
      onClick: async () => {
        try {
          await closeAiFunctions('SIGN_OUT');
          await aiBookCloseEvent({
            bookId: class_subject_info?.bookId,
            isOffline: isOffline,
          });
          await aiLogoutEvent({ isOffline: isOffline });
          !isOffline &&
            (await callTeacherEventsApi(commonServiceClientContext));
          if (fcmToken) {
            await NotificationAPI();
          }
          const response =
            await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.logout({
              profileId: getLocalStorage('userId'),
              role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            });
          if (deviceType === 'android') {
            android.userLoggedOut(true);
          }
        } catch (err) {
          console.log(err);
        } finally {
          let redirectUrl = undefined;
          if (isMCBUser && ssoRedirectionUrl) {
            redirectUrl = ssoRedirectionUrl;
          }
          dispatch(resetAuthState());
          dispatch(resetHomeDashboardState());
          dispatch(resetAssessmentFlowState());
          dispatch(resetManageHWState());
          dispatch(resetTeachState());
          dispatch(resetConnectedClassState());
          dispatch(resetAnalyticsState());
          // localStorage.clear();
          clearLocalStorageKeys();
          deleteOfflineAccessKeyFromCache();
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            navigate(LOGIN);
          }
        }
      },
    },
  ];

  if (!isOfflineFrontend && !isMCBUser) {
    sidebarArray.splice(1, 0, {
      name: 'Change Password',
      icon: 'change-password',
      onClick: async () => {
        navigate(CHANGE_PASSWORD);
        await closeAiFunctions('CHANGE_PASSWORD');
      },
    });
  }

  return sidebarArray;
};
