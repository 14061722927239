import { IStyles, PrimaryButton, pxToRem, pxTovW } from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';

const styles: IStyles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    bgcolor: { xs: 'common.white', md: 'inherit' },
    padding: { xs: pxToRem(20), lg: `${pxTovW(20)} ${pxTovW(240)}` },
  },
};
interface IProps {
  isFormativeAssessment?: boolean;
  proceedClickhandler?: () => void;
  disabled?: boolean;
}
export default function ReviewHeader(props: IProps) {
  const { proceedClickhandler, disabled, isFormativeAssessment } = props;

  return (
    <Box sx={styles.root}>
      <Typography variant="h1">
        {isFormativeAssessment ? `My Assessment` : `My Homework`}
      </Typography>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <PrimaryButton disabled={disabled} onClick={proceedClickhandler}>
          Proceed
        </PrimaryButton>
      </Box>
    </Box>
  );
}
