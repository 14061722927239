import {
  ContentListAccordian,
  IconWrapper,
  IStyles,
  pxToRem,
  pxTovW,
  theme,
} from '@geneo2-web/shared-ui';
import { CheckBox } from '@mui/icons-material';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { AssessmentTask } from '@protos/learning_management/lms.assessment.common.apis_pb';
import React from 'react';
const styles: IStyles = {
  root: {
    // border: '1px solid red',
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(5), md: pxTovW(5) },
    paddingX: { xs: pxToRem(20), md: pxTovW(0) },
    marginY: { xs: pxToRem(10), md: pxTovW(0) },
    // justifyContent: 'center',
    // alignItems: 'center',
  },
};
interface IProps {
  assessmentInfo?: AssessmentTask;
}
export default function SelectedChapters(props: IProps) {
  const { assessmentInfo } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  if (!assessmentInfo || !assessmentInfo.taskMetaInfo?.chapterInfo) {
    return null;
  }

  return (
    <Box sx={styles.root}>
      <Typography
        variant={isMobile ? 'h2' : 'h3'}
        sx={{
          marginY: { xs: pxToRem(10), md: pxTovW(20) },
          fontWeight: 'bold',
          marginLeft: { xs: pxToRem(30), md: pxTovW(40) },
        }}
      >
        Your Selected Chapters
      </Typography>
      {assessmentInfo.taskMetaInfo?.chapterInfo?.chapterTopicsInfo.map(
        (chapter, index) => (
          <Box
            key={index}
            sx={{
              width: '100%',
              display: 'flex',
              alignitems: 'flex-start',
              gap: { xs: pxToRem(10), md: pxTovW(10) },
            }}
          >
            <IconWrapper
              name="correct"
              size="md"
              type="png"
              parentFolder="icons"
              customSx={{ marginTop: '10px', height: pxToRem(20) }}
            />
            <ContentListAccordian
              key={index}
              variant="medium"
              HeaderContent={
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {chapter.name}
                  </Typography>
                </Box>
              }
              expandDisabled={false}
              // handleExpandmore={() => console.log('clicked')}
              // handleExpandless={() => console.log('clicked', chapter.chapterId)}
              // open={true}
              ListItemArray={chapter.topicInfo.map((topic) => (
                <Box
                  sx={{
                    width: { xs: '200px', md: '250px' },
                    height: { xs: '30px', md: '25px' },
                    display: 'flex',
                    gap: pxTovW(10),
                    alignItems: 'center',
                    paddingY: pxTovW(10),
                  }}
                >
                  <CheckBox
                    sx={{ height: '15px', width: '15px' }}
                    color="primary"
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {topic.name}
                  </Typography>
                </Box>
              ))}
            />
          </Box>
        )
      )}
    </Box>
  );
}
