import {
  IStyles,
  IconWrapper,
  ImageWrapper,
  Loader,
  MarksSubmitPopup,
  PrimaryButton,
  ScoreProgressBar,
  SecondaryButton,
  convertPercentageToMarks,
  deserify,
  formatDateAsDayMonth,
  getDifficultyLevelString,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import {
  SubmissionType,
  TaskEnum,
} from '@protos/learning_management/lms.db_pb';
import { HomeworkTask } from '@protos/learning_management/lms.hw.common.apis_pb';
import {
  StudentPerformanceInfo,
  StudentSubmissionInfo,
  TeacherHWClassStats,
} from '@protos/learning_management/lms.hw.teacher.apis_pb';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  ASSESSMENT_VIEW,
  HOMEWORK_PERFORMANCE,
  HOMEWORK_SUBMISSION_CUSTOM,
} from '../../../routeHandling/RoutesNomenclature';
import { getSubjectsMap } from '../../../utils/icons';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import ViewReportButton from '../components/ViewReportButton';
import { StudentSection } from './components/StudentSection';
import { AssessmentTask } from '@protos/learning_management/lms.assessment.common.apis_pb';
import {
  BloomLevelBreackdown,
  TeacherAssessmentClassStats,
  TeacherAssessmentClassStatsV2,
} from '@protos/learning_management/lms.assessment.teacher.apis_pb';
import { DifficultyLevelEnum } from '@protos/content_management/content.db_pb';
import { ChartBox } from './components/ChartBox';
import { StrengthsWeaknessesSection } from './components/StrenghtsAndWeaknessSection';
import { Create } from '@mui/icons-material';
import CurrentHomework from '../../Homework/CurrentHomework';
import { get } from 'http';
import { current } from '@reduxjs/toolkit';
import { userInfo } from 'os';
const styles: IStyles = {
  root: {
    width: '100vw',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    // paddingLeft: { xs: pxToRem(0), md: pxTovW(241) },
    // paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
    gap: { lg: pxTovW(30) },
    padding: {
      md: `${pxTovW(15)} ${pxTovW(20)}`,
      lg: `${pxTovW(15)} ${pxTovW(240)}`,
    },
    paddingTop: { xs: pxToRem(20), md: pxTovW(40) },
    boxSizing: 'border-box',
    overflowX: 'hidden',
  },
  header: {
    width: '100%',
    display: 'flex',
    // flexDirection: 'column',
    paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
    gap: { xs: pxToRem(10), md: pxTovW(22) },
    //
    marginLeft: { xs: pxToRem(20), md: pxTovW(0) },
    alignItems: 'center',
  },
  headImage: {
    width: { xs: pxToRem(74), md: pxToRem(50), lg: pxTovW(124) },
    height: { xs: pxToRem(74), md: pxToRem(50), lg: pxTovW(124) },
    borderRadius: { xs: pxToRem(6), md: pxToRem(6), lg: pxTovW(15) },
    objectFit: 'cover',
  },
  iconBox: {
    width: { xs: '100%', md: '97%', lg: pxTovW(794) },
    height: { xs: pxToRem(100), md: pxTovW(250), lg: pxTovW(209) },
    borderRadius: { xs: '0px', md: pxToRem(10) },
    backgroundColor: '#FFFFFF',
    // backgroundColor: 'red',
    border: { md: '1px solid #E0DFDE' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: pxToRem(10),
    boxSizing: 'border-box',
    boxShadow: `0px 0px ${pxToRem(10)} #0000001F`,
    marginLeft: { xs: pxToRem(0), md: pxTovW(40), lg: pxTovW(40) },
  },
};
export interface ISubmissionProps {
  studentData?: StudentPerformanceInfo;
  marks?: number;
  checkedValue?: boolean;
}
interface IStudentStatus {
  studentId: bigint;
  isSubmitted?: boolean;
  marks?: number;
}
export interface IStatusObj {
  [key: string]: IStudentStatus;
}
export const CompletedHomework = () => {
  // const { homework_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { assessment_list_data, selected_assessment_id } = deserify(
    useAppSelector((state) => state.manageHomework)
  );
  const { isIpadOnly } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [hwClassStats, setHwClassStats] = useState<
    TeacherAssessmentClassStatsV2 | undefined
  >();
  const [marksPopupDisplay, setMarksPopupDisplay] = useState(false);
  const [currentHomwork, setCurrentHomwork] = useState<AssessmentTask>();
  const {
    LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler,
    LmsAssessmentTeacherAPIServiceV2WithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const [studentInfo, setStudentInfo] = useState<
    StudentPerformanceInfo | undefined
  >();
  const [StatusObject, updateStatusObject] = useState<IStatusObj | undefined>();
  const teacher_id = getLocalStorage('userId');

  useEffect(() => {
    if (selected_assessment_id) {
      const hw = assessment_list_data?.ended.find(
        (e) => e.assessmentId === selected_assessment_id
      );
      setCurrentHomwork(hw);
      getHomeworkDetails(selected_assessment_id);
    }
  }, []);
  const createChartData = (bloomData: BloomLevelBreackdown) => {
    const mappings = [
      { key: 'retentionScore', title: 'Retention', color: '#005DAA' },
      { key: 'comprehensionScore', title: 'Comprehension', color: '#3498DB' },
      { key: 'applicationScore', title: 'Application', color: '#5DC151' },
      { key: 'analysisScore', title: 'Analysis', color: '#FA8D49' },
    ] as const;

    return mappings.map(({ key, title, color }) => ({
      obtainedMarks: bloomData[key] || 0,
      maxMarks: 100,
      title,
      color,
    }));
  };
  const getHomeworkDetails = async (homeworkId: number) => {
    try {
      setLoading(true);
      const response =
        await LmsAssessmentTeacherAPIServiceV2WithStatusCodeHandler.getAssessmentClassStats(
          {
            teacherId: BigInt(teacher_id),
            assessmentId: homeworkId,
          }
        );
      if (response.data) {
        setHwClassStats(response.data);
        const StatusObj: IStatusObj = {};
        response.data.studentPerformanceInfo.forEach((e) => {
          const id = e.studentId.toString();
          StatusObj[id] = {
            studentId: BigInt(e.studentId),
            isSubmitted: e.isSubmitted || false,
            marks: convertPercentageToMarks(
              e.scorePercent,
              response.data?.assessment?.maxMarks
            ),
          };
        });
        updateStatusObject(StatusObj);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  const physicalHwSubmitHandler = async (props: ISubmissionProps) => {
    const { checkedValue, marks, studentData } = props;
    //if max marks are there student data is taken from student info because in that case a pop up is needed which requires that data but if maxmarks are not present
    //then whole operation is to be done on radio button click , so data cannot be taken form local variable
    if (!studentInfo && !studentData) {
      return;
    }
    const studentPerformaceData = studentInfo || studentData;
    const isSubmittedStatus = marks !== undefined ? true : checkedValue;
    if (marks !== undefined) {
      const regex = /^\d+$/;
      if (!regex.test(marks.toString())) {
        dispatch(
          setToastInfo({
            label: 'Marks can be whole numbers only',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
    }
    try {
      setLoading(true);
      const submissionsInfo = new StudentSubmissionInfo({
        studentId: studentPerformaceData?.studentId,
        isSubmitted: isSubmittedStatus,
      });
      if (isSubmittedStatus && marks !== undefined) {
        submissionsInfo.obtainedMarks = marks;
      }
      const response =
        await LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler.studentHWPhysicalSubmissionUpdate(
          {
            teacherId: BigInt(teacher_id),
            homeworkId: selected_assessment_id,
            submissionsInfo: [submissionsInfo],
            maxMarks: currentHomwork?.maxMarks || 0,
          }
        );
      if (response) {
        console.log('response PhysicalHwSubmitHandler', response);
        const id = studentPerformaceData?.studentId.toString();
        //making changes in local status obj
        if (id && StatusObject && StatusObject[id]) {
          StatusObject[id].marks = marks;
          StatusObject[id].isSubmitted = isSubmittedStatus; //changing the status
        }

        updateStatusObject(StatusObject);
        selected_assessment_id && getHomeworkDetails(selected_assessment_id);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const cardOnClick = (studentId: string) => {
    // setMarksPopupDisplay(true);
    // navigate(`${HOMEWORK_SUBMISSION_CUSTOM}/${studentId}`);
    const student = StatusObject && StatusObject[studentId.toString()];
    switch (currentHomwork?.submissionType) {
      case SubmissionType.SUBMISSION_OFFLINE:
        // if (current_homework_classStats.homework.maxMarks) {
        //   setMarksPopupDisplay(true);
        // }
        //replaced with marksTextClickHandler as per new reqs
        break;
      case SubmissionType.SUBMISSION_ONLINE:
        if (student?.isSubmitted) {
          navigate(`${HOMEWORK_SUBMISSION_CUSTOM}/${studentId}`);
        }
        break;
      default:
        navigate(`${HOMEWORK_PERFORMANCE}/${studentId}`);
    }
  };
  const marksTextClickHandler = (student: StudentPerformanceInfo) => {
    setStudentInfo(student);
    if (currentHomwork?.submissionType === SubmissionType.SUBMISSION_OFFLINE) {
      if (currentHomwork.maxMarks) {
        setMarksPopupDisplay(true);
      }
    }
  };

  const subMap = getSubjectsMap();
  return loading === true ? (
    <Loader />
  ) : error === true ? (
    <Typography>Error Occured</Typography>
  ) : (
    <Box sx={styles.root}>
      <Box
        sx={{
          width: { xs: '100%', md: pxTovW(700), lg: pxTovW(610) },
          // backgroundColor: 'red',
        }}
      >
        <Box sx={styles.header}>
          <ImageWrapper
            name="chapterImage"
            type="png"
            parentFolder="tempAssets"
            path={getMediaBasePath(
              hwClassStats?.assessment?.assessmentPosterImgUrl,
              'processedMediaBucket'
            )}
            styles={styles.headImage}
          />
          <Box
            sx={{
              width: { xs: '70%', md: pxTovW(390) },
              display: 'flex',
              flexDirection: 'column',
              gap: { xs: pxToRem(14), md: pxTovW(15) },
            }}
          >
            <Box>
              <Typography
                variant="h1"
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                }}
              >
                {currentHomwork?.assessmentTitle || 'title'}
              </Typography>
              <Typography variant={isIpadOnly ? 'h4' : 'button'}
                color="primary"
                fontWeight="regular"
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  width: '90%',
                }}>
                {hwClassStats?.assessment?.class}
                {hwClassStats?.assessment?.section} {' '}
                {hwClassStats?.assessment?.subject} | {' '}
                {hwClassStats?.assessment?.moduleName}
              </Typography>
            </Box>
            <Box sx={{ width: { xs: pxToRem(178), md: pxTovW(300) } }}>
              {hwClassStats?.assessment?.maxMarks !== undefined && (
                <ScoreProgressBar
                  variant={largeScreen ? 'lg' : 'md'}
                  score={
                    Math.round(
                      Number(hwClassStats?.assessment?.classScore?.toFixed(2))
                    ) || 0
                  }
                />
              )}
            </Box>
          </Box>
        </Box>
        {hwClassStats?.assessment?.taskType ===
          TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM && (
            <Box
              sx={{
                ...styles.iconBox,
                width: { xs: '100vw', md: '100%', lg: pxTovW(552) },
                height: { xs: pxToRem(70), md: pxTovW(160), lg: pxTovW(121) },
                marginLeft: { xs: pxToRem(0), md: pxTovW(0) },
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: { xs: pxToRem(4), md: pxTovW(8) },
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: { xs: pxToRem(5.5), md: pxTovW(7.5) },
                    }}
                  >
                    <IconWrapper
                      name="user"
                      size={isIpadOnly ? 'large' : 'md'}
                      parentFolder="icons"
                      type="png"
                    />
                    <Typography variant="h2" fontWeight="bold">
                      {hwClassStats?.studentsSubmissionCount}/
                      {hwClassStats?.assignedStudentsCount}
                    </Typography>
                  </Box>
                  <Typography
                    variant="h4"
                    fontWeight="regular"
                    sx={{ color: '#828282' }}
                  >
                    Submissions
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: { xs: pxToRem(4), md: pxTovW(8) },
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: { xs: pxToRem(5.5), md: pxTovW(7.5) },
                    }}
                  >
                    <IconWrapper
                      name="calender"
                      size="md"
                      parentFolder="icons"
                      type="png"
                    />
                    <Typography variant="h2" fontWeight="bold">
                      {formatDateAsDayMonth(currentHomwork?.assessmentTargetDate)}
                    </Typography>
                  </Box>
                  <Typography
                    variant="h4"
                    fontWeight="regular"
                    sx={{ color: '#828282' }}
                  >
                    Deadline
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        {/* {selected_assessment_id &&
          hwClassStats?.assessment?.taskType !==
            TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM && (
            <ViewReportButton homeworkId={selected_assessment_id} />
          )} */}
        {hwClassStats?.assessmentReportInfo?.bloomLevelBreackdown &&
          hwClassStats?.assessment?.taskType !==
          TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM && (
            <ChartBox
              homeworkId={selected_assessment_id}
              chartData={createChartData(
                hwClassStats?.assessmentReportInfo.bloomLevelBreackdown
              )}
            />
          )}
        {hwClassStats?.assessment?.taskType !==
          TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM && (
            <StrengthsWeaknessesSection
              resultData={{
                strengths:
                  hwClassStats?.assessmentReportInfo?.strengthsAndWeaknessess
                    ?.strength || [],
                weakness:
                  hwClassStats?.assessmentReportInfo?.strengthsAndWeaknessess
                    ?.weakness || [],
              }}
            />
          )}
        {/* <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <PrimaryButton sx={{ margin: 'auto' }}>
            View Recommendation
          </PrimaryButton>
        </Box> */}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: pxToRem(20), md: pxTovW(20) },
          boxSizing: 'border-box',
          // backgroundColor: 'red',
        }}
      >
        {hwClassStats?.assessment?.taskType !==
          TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM && (
            <Box sx={styles.iconBox}>
              <Box
                sx={{
                  width: '100%',
                  boxSizing: 'border-box',
                  display: 'flex',
                  gap: pxToRem(20),
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingX: { xs: pxToRem(20), md: pxTovW(26) },
                }}
              >
                <Typography variant="bodyText" fontWeight="bold">
                  Test Overview
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: { xs: pxToRem(4), md: pxTovW(8) },
                    cursor: 'pointer',
                    borderRadius: pxTovW(15),
                    backgroundColor: '#158D29',
                    padding: { xs: pxToRem(8), md: pxTovW(10) },
                  }}
                  onClick={() => navigate(`${ASSESSMENT_VIEW}/ended`)}
                >
                  <IconWrapper
                    name="questions"
                    size={isIpadOnly ? 'large' : 'md'}
                    parentFolder="icons"
                    type="png"
                  />
                  <Typography
                    variant="smallText"
                    fontWeight="bold"
                    sx={{ color: '#FFFFFF' }}
                  >
                    VIEW ASSESSMENT
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingX: { xs: pxToRem(10), md: pxTovW(26) },
                  boxSizing: 'border-box',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    // alignItems: 'center',
                    gap: { xs: pxToRem(15), md: pxTovW(15) },
                    justifyContent: 'left',
                  }}
                >
                  <IconWrapper
                    name="questions"
                    size="large"
                    parentFolder="icons"
                    type="png"
                    customSx={{
                      height: { xs: pxToRem(25), lg: pxTovW(40) },
                      width: { xs: pxToRem(25), lg: pxTovW(40) },
                      mt: pxToRem(4),
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'left',
                      gap: { xs: pxToRem(5.5), md: pxTovW(7.5) },
                    }}
                  >
                    <Typography variant="h2" fontWeight="bold">
                      {currentHomwork?.assessmentContentInfo?.numberOfQuestions}
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight="regular"
                      sx={{ color: '#828282' }}
                    >
                      Questions
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: { xs: pxToRem(10), md: pxTovW(20) },
                    height: '100%',
                  }}
                >
                  <Divider
                    orientation="vertical"
                    sx={{
                      width: '1px',
                      height: '100%',
                      backgroundColor: '#E0DFDE',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      // alignItems: 'center',
                      gap: { xs: pxToRem(15), md: pxTovW(15) },
                      // backgroundColor: 'red',
                      justifyContent: 'left',
                    }}
                  >
                    <IconWrapper
                      name="bar-graph"
                      size="large"
                      parentFolder="icons"
                      type="png"
                      customSx={{
                        height: { xs: pxToRem(25), lg: pxTovW(40) },
                        width: { xs: pxToRem(25), lg: pxTovW(40) },
                        mt: pxToRem(4),
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        gap: { xs: pxToRem(5.5), md: pxTovW(7.5) },
                      }}
                    >
                      <Typography variant="h2" fontWeight="bold">
                        {getDifficultyLevelString(
                          currentHomwork?.difficultyLevel
                        )}
                      </Typography>
                      <Typography
                        variant="h4"
                        fontWeight="regular"
                        sx={{ color: '#828282' }}
                      >
                        Difficulty
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: { xs: pxToRem(10), md: pxTovW(20) },
                    height: '100%',
                  }}
                >
                  <Divider
                    orientation="vertical"
                    sx={{
                      width: '1px',
                      height: '100%',
                      backgroundColor: '#E0DFDE',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: { xs: pxToRem(15), md: pxTovW(15) },
                      // backgroundColor: 'red',
                      justifyContent: 'left',
                    }}
                  >
                    <IconWrapper
                      name="calender"
                      size="large"
                      parentFolder="icons"
                      type="png"
                      customSx={{
                        height: { xs: pxToRem(25), lg: pxTovW(24.43) },
                        width: { xs: pxToRem(25), lg: pxTovW(26.43) },
                        mt: pxToRem(4),
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        gap: { xs: pxToRem(5.5), md: pxTovW(7.5) },
                      }}
                    >
                      <Typography variant="h2" fontWeight="bold">
                        {formatDateAsDayMonth(
                          currentHomwork?.assessmentTargetDate
                        )}
                      </Typography>
                      <Typography
                        variant="h4"
                        fontWeight="regular"
                        sx={{ color: '#828282' }}
                      >
                        Deadline
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

        <StudentSection
          Class={`${currentHomwork?.class} ${currentHomwork?.section}`}
          studentPerformanceInfoList={hwClassStats?.studentPerformanceInfo}
          cardMaxMarks={currentHomwork?.maxMarks || 0}
          cardSubmissionType={currentHomwork?.submissionType as SubmissionType}
          cardOnClickHandler={cardOnClick}
          checkBoxClickHandler={physicalHwSubmitHandler}
          assignedStudentCount={hwClassStats?.assignedStudentsCount || 0}
          studentSubmissionCount={hwClassStats?.studentsSubmissionCount || 0}
          statusInfo={StatusObject}
          // saveStudentInfo={(e) => setStudentInfo(e)}
          textClickHandler={marksTextClickHandler}
          taskType={hwClassStats?.assessment?.taskType}
        />
      </Box>

      {currentHomwork && (
        <MarksSubmitPopup
          open={marksPopupDisplay}
          totalMarks={currentHomwork.maxMarks || 0}
          handleClose={() => {
            setMarksPopupDisplay(false);
            setStudentInfo(undefined);
          }}
          submitClickHandler={physicalHwSubmitHandler}
        />
      )}
    </Box>
  );
};
