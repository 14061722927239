import { AssessmentTask } from '@protos/learning_management/lms.assessment.common.apis_pb';
import {
  AssessmentList,
  AssessmentListCountInfo,
  TeacherAssessmentClassStats,
} from '@protos/learning_management/lms.assessment.teacher.apis_pb';
import { HomeworkTask } from '@protos/learning_management/lms.hw.common.apis_pb';
import {
  HWListCountInfo,
  HomeworkList,
  TeacherHWClassStats,
} from '@protos/learning_management/lms.hw.teacher.apis_pb';
import { createSlice } from '@reduxjs/toolkit';
// import {} from '@protos/common/'

export interface IManageHomeworkState {
  // homework_list_data: HomeworkList | undefined;
  assessment_list_data: AssessmentList | undefined;
  current_assessmemt_classStats?: TeacherAssessmentClassStats;
  selected_assessment_id?: number;
  draft_homework?: { [pageNumber: number]: AssessmentTask[] };
  assigned_homework?: { [pageNumber: number]: AssessmentTask[] };
  ended_homework?: { [pageNumber: number]: AssessmentTask[] };
  hw_info?: {
    hwListInfo: AssessmentListCountInfo | undefined;
    totalDraftPages: number;
    totalAssignedPages: number;
    totalEndedPages: number;
  };
  assessment_info?: {
    assessmentListInfo: AssessmentListCountInfo | undefined;
    totalDraftPages: number;
    totalAssignedPages: number;
    totalEndedPages: number;
  };

  // hw_class_stats: TeacherHWClassStats | undefined;
  //TeacherHWList[]
}

const initialState: IManageHomeworkState = {
  // homework_list_data: undefined,
  assessment_list_data: undefined,
  draft_homework: {},
  assigned_homework: {},
  ended_homework: {},
  hw_info: {
    hwListInfo: undefined,
    totalDraftPages: 1,
    totalAssignedPages: 1,
    totalEndedPages: 1,
  },
  assessment_info: {
    assessmentListInfo: undefined,
    totalDraftPages: 1,
    totalAssignedPages: 1,
    totalEndedPages: 1,
  },
};

export const manageHomeworkSlice = createSlice({
  name: 'manageHomework',
  initialState,
  reducers: {
    // setHomeworkListsData: (state, action) => {
    //   state.homework_list_data = action.payload;
    // },
    setAssessmentListData: (state, action) => {
      state.assessment_list_data = action.payload;
    },
    setCurrentAssessmentClassStats: (state, action) => {
      state.current_assessmemt_classStats = action.payload;
    },
    setSelectedAssessmentId: (state, action) => {
      state.selected_assessment_id = action.payload;
    },
    setDraftData: (state, action) => {
      state.draft_homework = action.payload;
    },
    setAssignedData: (state, action) => {
      state.assigned_homework = action.payload;
    },
    setEndedData: (state, action) => {
      state.ended_homework = action.payload;
    },
    setHwInfoData: (state, action) => {
      state.hw_info = action.payload;
    },
    setAssessmentInfoData: (state, action) => {
      state.assessment_info = action.payload;
    },

    resetManageHWState: (state) => {
      return initialState;
    },
  },
});

export const {
  // setHomeworkListsData,
  setAssessmentListData,
  setSelectedAssessmentId,
  resetManageHWState,
  setCurrentAssessmentClassStats,
  setDraftData,
  setAssignedData,
  setEndedData,
  setHwInfoData,
  setAssessmentInfoData,
} = manageHomeworkSlice.actions;
export default manageHomeworkSlice.reducer;
