import {
  deserify,
  IStyles,
  pxToRem,
  pxTovW,
  QuantityButton,
  theme,
} from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../reduxStore/reduxHooks';
import { interactionEvent } from '../../../../Auth/auth.events';
import {
  setHighQuestionsCount,
  setLowQuestionsCount,
  setMediumQuestionsCount,
} from '../../../../Assessment/reducer/assessmentFlow.slice';
import { useDownloadContext } from '../../../../../app/Context/DownloadContextProviderV2';

interface IVariant {
  horizontal: string;
  vertical: string;
}
interface IProps {
  mobileVariant?: keyof IVariant;
  desktopVariant?: keyof IVariant;
  disabled?: boolean;
}
export default function DifficultyNos({
  mobileVariant,
  desktopVariant,
  disabled,
}: IProps) {
  if (!mobileVariant) mobileVariant = 'horizontal';
  if (!desktopVariant) desktopVariant = 'horizontal';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isOffline } = useDownloadContext();
  const styles: IStyles = {
    root: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      // height: { xs: pxToRem(95), md: 'max-content' },
      height: 'max-content',
      boxSizing: 'border-box',
      display: 'flex',
      // flexDirection: { xs: 'row ', md: 'column' },
      flexDirection: {
        xs: mobileVariant === 'horizontal' ? 'row' : 'column',
        md: desktopVariant === 'horizontal' ? 'row' : 'column',
      },
      justifyContent: { xs: 'space-around', md: 'space-between' },
      alignItems: { xs: 'center', md: 'inherit' },
      boxShadow: '0px 3px 35px #0000000F',
      // border: '1px solid #EAEAEA',
      borderRadius: pxTovW(30),
      padding: {
        xs: `${pxToRem(0)} ${pxToRem(10)}`,
        md: `${pxTovW(20)} ${pxTovW(30)}`,
      },
      '&>:not(:last-child)': {
        borderBottom: '1px solid #F2F2F2',
      },
    },
    buttonContainer: {
      // width: '100%',
      width: {
        xs: mobileVariant === 'horizontal' ? 'unset' : '100%',
        md: desktopVariant === 'horizontal' ? 'unset' : '100%',
      },
      display: 'flex',
      // flexDirection: { xs: 'column ', md: 'row' },

      flexDirection: {
        xs: mobileVariant === 'horizontal' ? 'column' : 'row',
        md: desktopVariant === 'horizontal' ? 'column' : 'row',
      },
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: { xs: pxToRem(10), md: 'inherit' },
      padding: { xs: `${pxToRem(10)} 0`, md: `${pxTovW(20)} 0px` },
    },
  };

  const { questions_count, module_filtered_questions, questions_stats } =
    deserify(useAppSelector((state) => state.assessment));
  const dispatch = useAppDispatch();
  const handleIncrement = (callback: any) => {
    return () => dispatch(callback({ type: 'inc' }));
  };
  const handleDecrement = (callback: any) => {
    return () => dispatch(callback({ type: 'dec' }));
  };

  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const addInteractionEvent = async () => {
    await interactionEvent({
      url: '',
      context: 'Create_homework',
      name: 'ADD',
      pathSegments: pathSegments,
      isOffline: isOffline,
    });
  };

  const removeInteractionEvent = async () => {
    await interactionEvent({
      url: '',
      context: 'Create_homework',
      name: 'REMOVE',
      pathSegments: pathSegments,
      isOffline: isOffline,
    });
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.buttonContainer}>
        <Typography variant="h3" fontFamily={'lato'} fontWeight={700}>
          Easy
        </Typography>
        <QuantityButton
          smallVariant={isMobile}
          quantity={questions_count.noOfLowQuestions}
          incrementClickHandler={() => {
            addInteractionEvent();
            const incrementFunction = handleIncrement(setLowQuestionsCount);
            incrementFunction();
          }}
          decrementClickHandler={() => {
            removeInteractionEvent();
            const decrementFunction = handleDecrement(setLowQuestionsCount);
            decrementFunction();
          }}
          maxQuantity={
            questions_stats?.questionDifficultyLevel?.noOfLowQuestions
          }
          disabled={disabled}
        />
      </Box>

      <Box sx={styles.buttonContainer}>
        <Typography variant="h3" fontFamily={'lato'} fontWeight={700}>
          Medium
        </Typography>
        <QuantityButton
          smallVariant={isMobile}
          quantity={questions_count.noOfMediumQuestions}
          incrementClickHandler={() => {
            addInteractionEvent();
            const incrementFunction = handleIncrement(setMediumQuestionsCount);
            incrementFunction();
          }}
          decrementClickHandler={() => {
            removeInteractionEvent();
            const decrementFunction = handleDecrement(setMediumQuestionsCount);
            decrementFunction();
          }}
          maxQuantity={
            questions_stats?.questionDifficultyLevel?.noOfMediumQuestions
          }
          disabled={disabled}
        />
      </Box>

      <Box sx={styles.buttonContainer}>
        <Typography variant="h3" fontFamily={'lato'} fontWeight={700}>
          Hard
        </Typography>
        <QuantityButton
          smallVariant={isMobile}
          quantity={questions_count.noOfHighQuestions}
          incrementClickHandler={() => {
            addInteractionEvent();
            const incrementFunction = handleIncrement(setHighQuestionsCount);
            incrementFunction();
          }}
          decrementClickHandler={() => {
            removeInteractionEvent();
            const decrementFunction = handleDecrement(setHighQuestionsCount);
            decrementFunction();
          }}
          maxQuantity={
            questions_stats?.questionDifficultyLevel?.noOfHighQuestions
          }
          disabled={disabled}
        />
      </Box>
    </Box>
  );
}
