import {
  ActionsPopup,
  deserify,
  DragAndDropFiles,
  getFileExtensionType,
  getFileSizeInMB,
  getFileType,
  getImageType,
  getLocalStorage,
  getMediaBasePath,
  InputField,
  InputFieldContainer,
  IStyles,
  Loader,
  PrimaryButton,
  pxToRem,
  pxTovW,
  SelectMenu,
  uploadFile,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import {
  Box,
  Button,
  InputAdornment,
  Radio,
  Skeleton,
  Typography,
} from '@mui/material';
import { SubjectChapterMiniInfo } from '@protos/content_management/content.common.apis_pb';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { ChangeEvent, DragEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CloudUpload } from '@protos/learning_management/lms.db_pb';
import {
  Content_ContentEnum,
  FileEnum,
  Resource_ResourceEnum,
  ResourceCategoryEnum,
} from '@protos/content_management/content.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import {
  FileUploadPurpose,
  SignedUrlReponse,
} from '@protos/learning_management/lms.common.apis_pb';
import { PartialMessage } from '@bufbuild/protobuf';
import { setCustomResourceData } from '../reducer/teach.slice';
import { useDispatch } from 'react-redux';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import { ContentLockStatusType } from '@protos/school_management/school.db_pb';
import { CustomUploadFileResponse } from '../../Assessment/reducer/assessmentFlow.slice';

const styles: IStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    // paddingBottom: { xs: pxToRem(75), md: 0 },
  },
  heading: {
    paddingTop: { xs: pxToRem(20), md: pxTovW(40) },
    width: {
      xs: `calc(100% - ${pxToRem(40)})`,
      md: `calc(100% - ${pxTovW(40)})`,
      lg: `calc(100vw - ${pxTovW(480)})`,
    },
    flexDirection: { xs: 'column', md: 'row' },
    position: { xs: 'unset', md: 'fixed' },
    paddingX: { xs: pxToRem(20), md: pxTovW(20), lg: pxTovW(240) },
    height: { xs: pxToRem(39), md: pxTovW(143) },
    background: { xs: '#FDFBFB', md: '#FFFFFF' },
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: { md: 1 },
  },
  buttonContainer: {
    position: { xs: 'fixed', md: 'unset' },
    bottom: { xs: 0, md: 'none' },
    width: { xs: '90%', md: pxTovW(397) },
    alignSelf: { xs: 'center', md: 'start' },
    zIndex: { xs: 1 },
    marginBottom: { xs: pxToRem(10), md: 0 },
  },
  mainContainer: {
    // paddingX: { xs: pxToRem(20), md: pxTovW(240) },
    // paddingTop: { xs: pxToRem(20), md: pxTovW(200) },
    width: {
      xs: `calc(100% - ${pxToRem(40)})`,
      md: `calc(100% - ${pxToRem(20)})`,
      lg: `calc(100% - ${pxTovW(480)})`,
    },
    p: {
      xs: ` 0 ${pxToRem(20)} ${pxToRem(75)}`,
      md: `${pxTovW(200)} ${pxTovW(20)} ${pxTovW(50)}`,
      lg: `${pxTovW(200)} ${pxTovW(240)} ${pxTovW(50)}`,
    },
    background: '#FDFBFB',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: { md: 'space-between' },
    gap: { xs: pxToRem(20), md: pxTovW(126) },
  },
  leftPanel: {
    width: { xs: '100%', md: pxTovW(652) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(24) },

    flexGrow: { md: 0.7, lg: 'inherit' },
  },
  labelContainer: {
    width: { xs: '100%', md: pxTovW(652) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(9), md: pxTovW(12) },
    '& .MuiTypography-root': {
      fontFamily: 'poppins',
    },
    '& .MuiTypography-root > span': {
      color: '#F54040',
      fontWeight: '600',
      fontSize: { xs: pxToRem(18), md: pxTovW(21) },
    },
  },
  radioDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(7) },
  },
  radio: {
    color: '#CCE6FE',
    '& .MuiSvgIcon-root': {
      fontSize: { xs: pxToRem(22), md: pxTovW(34) },
    },
    '&.MuiRadio-root': {
      padding: '0',
    },
  },

  rightPanel: {
    // width: { md: pxTovW(663) },

    flexGrow: { md: 1 },
  },
};

interface IOptionList {
  name: string | number;
  id: number;
  nonEditableItem?: boolean;
}

export const ResourceUpload = () => {
  const { resource_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const dispatch = useDispatch();
  const teacher_id = getLocalStorage('userId');
  const { setSelectedFunction } = useGlobalContext();
  const {
    LmsCommonAPIServiceV2ClientWithStatusCodeHandler,
    TeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();

  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const { class_subject_info } = deserify(
    useAppSelector((state) => state.homeDashboard)
  );
  const { subject_chapter_info, subject_topic_info, custom_resource_data } =
    deserify(useAppSelector((state) => state.teach));

  const [goingBackPopup, setGoingBackPopup] = useState(false);
  const [radioValue, setRadioValue] = useState<string>('Unlocked');
  const [topicList, setTopicList] = useState<IOptionList[]>();
  const [contentLockStatus, setContentLockStatus] = useState<number>(
    ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED
  );
  // const [currentCategory, setCurrentCategory] = useState('');
  // const [userUploads, setUserUploads] = useState<CustomUploadFileResponse[]>(
  //   []
  // );
  const [isAnyFilePending, setIsAnyFilePending] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [coverIsUploading, setCoverIsUploading] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);

  // --------------------------------------------------------------------------

  const controlRadioProps = (item: string) => ({
    name: 'color-radio-button',
    value: item,
    checked: radioValue === item,
    onChange: async (event: ChangeEvent<HTMLInputElement>) => {
      setRadioValue(event.target.value);
      if (event.target.value === 'Locked') {
        setContentLockStatus(
          ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
        );
        dispatch(
          setCustomResourceData({
            ...custom_resource_data,
            contentLock: true,
          })
        );
      } else {
        setContentLockStatus(
          ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED
        );
        dispatch(
          setCustomResourceData({
            ...custom_resource_data,
            contentLock: false,
          })
        );
      }
    },
    inputProps: { 'aria-label': item },
  });

  // --------------------------------------------------------------------------

  const coverImageUpload = async (e: any) => {
    const file = e.target.files[0];
    if (!file || !file.type.startsWith('image/')) {
      dispatch(
        setToastInfo({
          label: 'Please add an Image File',
          variant: 'error',
          open: true,
        })
      );
      e.target.value = '';
      return;
    }

    setFile(file);

    const tempUserUploads: CustomUploadFileResponse = {
      uploadFileData: new CloudUpload({
        gcpFileUrl: '',
        personId: teacher_id,
        fileSizeInMb: getFileSizeInMB(file.size),
        isDeleted: false,
        externalWebResourceUrl: '',
        fileName: file.name,
        fileType: getFileType(file.type),
        fileExtensionType: getFileExtensionType(file.type),
        isUploaded: false,
        signedUrl: '',
      }),
      fileUploadStatus: 'pending',
      fileSize: file.size,
    };

    // if (getFileType(file.type) !== FileEnum.FILE_TYPE_IMAGE) {
    //   tempUserUploads.uploadFileData.isUploaded = false;
    //   tempUserUploads.fileUploadStatus = 'Invalid Type';
    // }
    //! max file size to be 50 MB
    console.log('getFileSizeInMB(file.size):', getFileSizeInMB(file.size));
    if (getFileSizeInMB(file.size) > 50) {
      console.log('file.size:');
      tempUserUploads.uploadFileData.isUploaded = false;
      tempUserUploads.fileUploadStatus = 'Size Exceeded';
      dispatch(
        setToastInfo({
          label: 'File Size should be less than 50 MB',
          variant: 'error',
          open: true,
        })
      );
      dispatch(
        setCustomResourceData({
          ...custom_resource_data,
          custom_resource_cover_image: tempUserUploads,
        })
      );
      return;
    }

    setCoverIsUploading(true);
    try {
      const signedUrlResponse: SignedUrlReponse =
        await LmsCommonAPIServiceV2ClientWithStatusCodeHandler.getSignedUrlForFileUpload(
          {
            personId: teacher_id,
            filesInfo: [
              {
                originalFilePath: file.name,
                mimeType: getImageType(file.type),
                size: getFileSizeInMB(file.size),
              },
            ],
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            fileUploadPurpose: FileUploadPurpose.FILE_UPLOAD_TEACHER_RESOURCE,
            sectionId: class_subject_info?.sectionId,
            subjectId: class_subject_info?.subjectId,
            resourceId: custom_resource_data?.resourceId
              ? Number(custom_resource_data?.resourceId)
              : Number(resource_id),
          }
        );

      if (signedUrlResponse && signedUrlResponse.response.length > 0) {
        const signedUrl = getMediaBasePath(
          signedUrlResponse.response[0].signedUrl
        );

        // Perform your file upload here
        const uploadSuccess = await uploadFile({ file, signedUrl });
        if (uploadSuccess.success === true) {
          // If upload is successful, update status to 'success'
          // tempUserUploads.uploadFileData.gcpFileUrl =
          //   signedUrlResponse.response[0].signedUrl;
          tempUserUploads.uploadFileData.signedUrl =
            signedUrlResponse.response[0].signedUrl;
          tempUserUploads.uploadFileData.isUploaded = true;
          tempUserUploads.fileUploadStatus = 'success';
        } else {
          tempUserUploads.uploadFileData.isUploaded = false;
          tempUserUploads.fileUploadStatus = 'Upload Error';
          dispatch(
            setToastInfo({
              label: `Error Occurred! Unable to upload Cover image.`,
              variant: 'error',
              open: true,
            })
          );
        }
      } else {
        tempUserUploads.uploadFileData.isUploaded = false;
        tempUserUploads.fileUploadStatus = 'Upload Error';
        dispatch(
          setToastInfo({
            label: `Error Occurred! Unable to upload Cover image.`,
            variant: 'error',
            open: true,
          })
        );
      }
      setCoverIsUploading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      tempUserUploads.uploadFileData.isUploaded = false;
      tempUserUploads.fileUploadStatus = 'Upload Error';
      dispatch(
        setToastInfo({
          label: `Error Occurred! Unable to upload Cover image.`,
          variant: 'error',
          open: true,
        })
      );
      setCoverIsUploading(false);
    }

    dispatch(
      setCustomResourceData({
        ...custom_resource_data,
        custom_resource_cover_image: tempUserUploads,
      })
    );
  };

  // --------------------------------------------------------------------------

  const topicsFetch = () => {
    setTopicList(
      subject_topic_info?.chapterTopics?.map((data) => ({
        name: data.topicTitle,
        id: data.topicId,
        nonEditableItem: false,
      }))
    );
  };

  // --------------------------------------------------------------------------

  //^ Drag and drop
  const dragItem = useRef(0);
  const dragOverItem = useRef(0);
  // will be used to adjust styling of the item over which it is hovering
  const [dragOverItemIndex, setDragOverItemIndex] = useState(-1);
  const reorderDragStart = (position: number) => {
    // record the position from where the item is picked
    dragItem.current = position;
  };
  const reorderDragEnter = (position: number) => {
    // record the position above which the item is present
    dragOverItem.current = position;
    setDragOverItemIndex(position);
  };

  const reorderDrop = () => {
    if (!custom_resource_data) return;
    const temp_userUploads = [
      ...custom_resource_data.custom_resource_uploaded_files,
    ];
    // create a copy of the item being dragged
    const dragItemContent = temp_userUploads[dragItem.current];
    // remove the item being dragged from the list
    temp_userUploads.splice(dragItem.current, 1);
    // add the item to its new position
    temp_userUploads.splice(dragOverItem.current, 0, dragItemContent);
    // reset the values
    dragItem.current = -1;
    dragOverItem.current = -1;
    setDragOverItemIndex(-1);
    // set the new array
    dispatch(
      setCustomResourceData({
        ...custom_resource_data,
        custom_resource_uploaded_files: temp_userUploads,
      })
    );
  };

  // --------------------------------------------------------------------------

  const handleDropFunction = async (event: DragEvent<HTMLDivElement>) => {
    await handleUploadDoc(event.dataTransfer.files);
  };
  const handleChangeFunction = async (event: ChangeEvent<HTMLInputElement>) => {
    handleUploadDoc(event.target.files as FileList);
  };
  const handleDeleteFunction = async (indexToDelete: number) => {
    if (!custom_resource_data) return;
    const tempUserUploads = [
      ...custom_resource_data.custom_resource_uploaded_files,
    ];
    tempUserUploads[indexToDelete].uploadFileData.isDeleted = true;
    dispatch(
      setCustomResourceData({
        ...custom_resource_data,
        custom_resource_uploaded_files: tempUserUploads,
      })
    );
  };

  const pendingStateUpdate = async (filesToUpload: FileList) => {
    if (!filesToUpload) return;

    const tempUserUploadsArray: CustomUploadFileResponse[] = [];
    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      const tempUserUploads: CustomUploadFileResponse = {
        uploadFileData: new CloudUpload({
          gcpFileUrl: '',
          personId: teacher_id,
          fileSizeInMb: getFileSizeInMB(file.size),
          externalWebResourceUrl: '',
          isDeleted: false,
          fileName: file.name,
          fileType: getFileType(file.type),
          fileExtensionType: getFileExtensionType(file.type),
          isUploaded: false,
          signedUrl: '',
        }),
        fileUploadStatus: 'pending',
        fileSize: file.size,
      };

      tempUserUploadsArray.push(tempUserUploads);
    }

    // setUserUploads([...(userUploads || []), ...tempUserUploadsArray]);
    // dispatch(setUserUploadedFiles([...(userUploads || []), ...tempUserUploadsArray]));

    if (custom_resource_data)
      dispatch(
        setCustomResourceData({
          ...custom_resource_data,
          custom_resource_uploaded_files: [
            ...(custom_resource_data?.custom_resource_uploaded_files || []),
            ...tempUserUploadsArray,
          ],
        })
      );
  };

  const handleUploadDoc = async (filesToUpload: FileList) => {
    try {
      if (!filesToUpload) return;
      const tempUserUploadsArray: CustomUploadFileResponse[] = [];
      await pendingStateUpdate(filesToUpload);

      for (let i = 0; i < filesToUpload.length; i++) {
        const file = filesToUpload[i];
        const tempUserUploads: CustomUploadFileResponse = {
          uploadFileData: new CloudUpload({
            gcpFileUrl: '',
            personId: teacher_id,
            fileSizeInMb: getFileSizeInMB(file.size),
            isDeleted: false,
            externalWebResourceUrl: '',
            fileName: file.name,
            fileType: getFileType(file.type),
            fileExtensionType: getFileExtensionType(file.type),
            isUploaded: false,
            signedUrl: '',
          }),
          fileUploadStatus: 'pending',
          fileSize: file.size,
        };

        if (getFileType(file.type) === FileEnum.FILE_TYPE_UNKNOWN) {
          tempUserUploads.uploadFileData.isUploaded = false;
          tempUserUploads.fileUploadStatus = 'Invalid Type';
          tempUserUploadsArray.push(tempUserUploads);
          continue;
        }
        //! max file size to be 50 MB
        if (getFileSizeInMB(file.size) > 50) {
          tempUserUploads.uploadFileData.isUploaded = false;
          tempUserUploads.fileUploadStatus = 'Size Exceeded';
          tempUserUploadsArray.push(tempUserUploads);
          continue;
        }

        try {
          const signedUrlResponse: SignedUrlReponse =
            await LmsCommonAPIServiceV2ClientWithStatusCodeHandler.getSignedUrlForFileUpload(
              {
                personId: teacher_id,
                filesInfo: [
                  {
                    originalFilePath: file.name,
                    mimeType: getImageType(file.type),
                    size: getFileSizeInMB(file.size),
                  },
                ],
                personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
                fileUploadPurpose:
                  FileUploadPurpose.FILE_UPLOAD_TEACHER_RESOURCE,
                sectionId: class_subject_info?.sectionId,
                subjectId: class_subject_info?.subjectId,
                resourceId: custom_resource_data?.resourceId
                  ? Number(custom_resource_data?.resourceId)
                  : Number(resource_id),
              }
            );

          if (signedUrlResponse && signedUrlResponse.response.length > 0) {
            const signedUrl = getMediaBasePath(
              signedUrlResponse.response[0].signedUrl
            );

            // Perform your file upload here
            const uploadSuccess = await uploadFile({ file, signedUrl });
            if (uploadSuccess.success === true) {
              // If upload is successful, update status to 'success'
              // tempUserUploads.uploadFileData.gcpFileUrl =
              //   signedUrlResponse.response[0].signedUrl;
              tempUserUploads.uploadFileData.signedUrl =
                signedUrlResponse.response[0].signedUrl;
              tempUserUploads.uploadFileData.isUploaded = true;
              tempUserUploads.fileUploadStatus = 'success';
            } else {
              tempUserUploads.uploadFileData.isUploaded = false;
              tempUserUploads.fileUploadStatus = 'Upload Error';
            }
          } else {
            tempUserUploads.uploadFileData.isUploaded = false;
            tempUserUploads.fileUploadStatus = 'Upload Error';
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          tempUserUploads.uploadFileData.isUploaded = false;
          tempUserUploads.fileUploadStatus = 'Upload Error';
        }

        tempUserUploadsArray.push(tempUserUploads);
      }

      // After all uploads are done, update state and status
      dispatch(
        setCustomResourceData({
          ...custom_resource_data,
          custom_resource_uploaded_files: [
            ...(custom_resource_data?.custom_resource_uploaded_files || []),
            ...tempUserUploadsArray,
          ],
        })
      );
    } catch (error) {
      console.error('An error occurred during file upload:', error);
    }
  };

  // --------------------------------------------------------------------------

  const isUploadDisabled = () => {
    if (!custom_resource_data) return true;

    const hasPendingFiles =
      custom_resource_data?.custom_resource_uploaded_files?.some(
        (file) => file.fileUploadStatus === 'pending'
      );

    const hasNoSuccessFiles =
      !custom_resource_data?.custom_resource_uploaded_files?.some(
        (file) => file.fileUploadStatus === 'success'
      );

    const allSuccessFilesDeleted =
      custom_resource_data?.custom_resource_uploaded_files
        ?.filter((file) => file.fileUploadStatus === 'success')
        .every((file) => file.uploadFileData.isDeleted === true);

    return (
      hasPendingFiles ||
      hasNoSuccessFiles ||
      allSuccessFilesDeleted ||
      !custom_resource_data.resourceTitle ||
      custom_resource_data.resourceTitle.trim() === '' ||
      !custom_resource_data.resourceTime ||
      Number(custom_resource_data.resourceTime) === 0
    );
  };

  // --------------------------------------------------------------------------

  const getFilesInfo = () => {
    if (!custom_resource_data) return;
    const filteredData =
      custom_resource_data.custom_resource_uploaded_files.filter(
        (response) =>
          response.fileUploadStatus === 'success' &&
          !response.uploadFileData.isDeleted
      );

    return filteredData.map((file) => file.uploadFileData);
  };

  const uploadClickHandler = async () => {
    if (!custom_resource_data) return;

    try {
      setIsUploading(true);
      const response =
        await TeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler.upsertTeacherContent(
          {
            resourceId: custom_resource_data.resourceId,
            chapterId: custom_resource_data.selected_chapter.chapter_id,
            topicId: custom_resource_data.selectedTopic?.topicId,
            coverTitle: custom_resource_data.resourceTitle.trim(),
            coverImageInfo:
              custom_resource_data.custom_resource_cover_image
                ?.fileUploadStatus === 'success'
                ? custom_resource_data.custom_resource_cover_image
                  ?.uploadFileData
                : undefined,
            estimatedTimeInMin: Number(custom_resource_data.resourceTime),
            contentLock: custom_resource_data.contentLock,
            resourceType: Resource_ResourceEnum.RESOURCE_TYPE_TEACHER_UPLOAD,
            subjectId: class_subject_info?.subjectId,
            sectionId: class_subject_info?.sectionId,
            filesInfo: getFilesInfo(),
            teacherId: BigInt(teacher_id),
            schoolId: user_info?.schoolDetails[0].schoolId,
          }
        );

      if (response.resourceId) {
        // testFunc(response.resourceId);
        navigate(-1);
        dispatch(setCustomResourceData(undefined));
        dispatch(
          setToastInfo({
            label: custom_resource_data.resourceId
              ? 'Your changes are saved successfully.'
              : 'Resource uploaded successfully.',
            variant: 'info',
            open: true,
          })
        );
        setIsUploading(false);
      }

      console.log('response:', response);
    } catch (error) {
      console.log('error:', error);
      setIsUploading(false);
      dispatch(
        setToastInfo({
          label: custom_resource_data.resourceId
            ? 'Error Occurred! While trying to update the resource.'
            : 'Error Occurred! While trying to create the resource.',
          variant: 'error',
          open: true,
        })
      );
    }
  };

  // --------------------------------------------------------------------------

  const backButtonClick = async () => {
    // navigate(-1);
    // dispatch(setCustomResourceData(undefined));
    setGoingBackPopup(true);
  };
  useEffect(() => {
    setSelectedFunction(() => backButtonClick);

    topicsFetch();
  }, [goingBackPopup]);

  useEffect(() => {
    // 0 pending and at least 1 success
    if (custom_resource_data?.custom_resource_uploaded_files) {
      setIsAnyFilePending(
        custom_resource_data.custom_resource_uploaded_files.some(
          (item) => item.fileUploadStatus === 'pending'
        ) ||
        !custom_resource_data.custom_resource_uploaded_files.some(
          (item) => item.fileUploadStatus === 'success'
        )
      );
    }
  }, [custom_resource_data?.custom_resource_uploaded_files]);

  // --------------------------------------------------------------------------

  if (isUploading) return <Loader />;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.heading}>
        <Typography variant="h1">Upload Your Content</Typography>
        <Box sx={styles.buttonContainer}>
          {/* <PrimaryButton
            fullWidth
            onClick={uploadClickHandler}
            disabled={isUploadDisabled()}
          > */}
          <Button
            onClick={uploadClickHandler}
            disabled={isUploadDisabled()}
            variant="contained"
            color="secondary"
            sx={{
              width: {
                xs: '100%',
                md: pxTovW(397),
              },
              height: { xs: pxToRem(52), md: pxTovW(70) },
              boxShadow:
                'inset 0 0 8px rgba(0, 0, 0, 0.5),0 7px 13px rgba(0, 0, 0, 0.5)',
              borderRadius: '10px',
              '&:disabled': {
                backgroundColor: '#0AA34F63',
              },
            }}
          >
            <Typography variant="h3" color={'common.white'} fontWeight={'Bold'}>
              UPLOAD
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box sx={styles.mainContainer}>
        <Box sx={styles.leftPanel}>
          <Typography
            variant="h2"
            sx={{ display: { xs: 'none', md: 'unset' } }}
          >
            Create Resource Card
          </Typography>
          <Box sx={styles.labelContainer}>
            <Typography variant="h4" paddingLeft={2}>
              Chapter<span>*</span>
            </Typography>
            <Box
              sx={{
                width: '100%',
                alignContent: 'center',
                height: { xs: pxToRem(50), md: pxTovW(62) },
                background: '#ECECEC',
                borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
                // padding: { xs: pxToRem(15), md: pxTovW(20) },
              }}
            >
              <Typography
                variant="bodyText"
                sx={{ paddingLeft: { xs: pxToRem(15), md: pxTovW(20) } }}
              >
                {custom_resource_data?.selected_chapter.chapter_name}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              ...styles.labelContainer,
              marginTop: { md: pxTovW(6) },
              '& .css-17h1mtl-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-17h1mtl-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-17h1mtl-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
              {
                paddingY: { md: 0 },
              },
            }}
          >
            <Typography variant="h4" paddingLeft={2}>
              Topics
            </Typography>
            <SelectMenu
              value={custom_resource_data?.selectedTopic?.topicName || ''}
              onChange={(value: string | number) => {
                const option = topicList?.find((topic) => topic.name === value);
                dispatch(
                  setCustomResourceData({
                    ...custom_resource_data,
                    selectedTopic: { topicName: value, topicId: option?.id },
                  })
                );
              }}
              clearIcon={true}
              // value={topic}
              // onChange={setTopic}
              optionList={topicList ? topicList : []}
              rootStyles={{ width: '100%' }}
            />
          </Box>
          <Box sx={{ ...styles.labelContainer, marginTop: { md: pxTovW(6) } }}>
            <Typography variant="h4" paddingLeft={2}>
              Title<span>*</span>
            </Typography>
            <InputField
              placeholder="e.g: Resources"
              value={custom_resource_data?.resourceTitle}
              autoComplete="off"
              onChange={(e) => {
                dispatch(
                  setCustomResourceData({
                    ...custom_resource_data,
                    resourceTitle: e.target.value,
                  })
                );
              }}
              variant="outlined"
              fullWidth
              nonCircular
              borderColor="#61BAFF"
              fontColor="#1D1D1D"
              sx={{ background: '#DAF5FF' }}
            />
          </Box>
          <Box sx={styles.labelContainer}>
            <Typography variant="h4" paddingLeft={2}>
              {coverIsUploading
                ? 'Uploading Cover Image ......... '
                : 'Cover Image (optional)'}
            </Typography>

            {coverIsUploading ? (
              <Skeleton
                variant="rectangular"
                sx={{
                  width: '100%',
                  height: { xs: pxToRem(55), md: pxTovW(72) },
                  borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
                }}
              />
            ) : (
              <InputField
                type=""
                value={
                  custom_resource_data?.custom_resource_cover_image
                    ?.fileUploadStatus === 'success'
                    ? custom_resource_data.custom_resource_cover_image
                      ?.uploadFileData.fileName
                    : ''
                }
                variant="outlined"
                fullWidth
                nonCircular
                borderColor="#61BAFF"
                fontColor="#1D1D1D"
                sx={{
                  background: '#DAF5FF',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        ref={inputRef}
                        onChange={coverImageUpload}
                      />
                      <Button
                        onClick={() => {
                          if (inputRef.current) inputRef.current.click();
                        }}
                        sx={{
                          width: { xs: pxToRem(93), md: pxTovW(132) },
                          height: { xs: pxToRem(31), md: pxTovW(44) },
                          background: '#007CDC',
                          '&:hover': {
                            backgroundColor: '#007CDC',
                          },
                        }}
                      >
                        <Typography
                          variant="h4"
                          fontWeight={'Bold'}
                          color="success.light"
                          sx={{
                            fontFamily: 'lato !important',
                            fontSize: { xs: pxToRem(12), md: pxTovW(18) },
                          }}
                        >
                          Browse Image
                        </Typography>
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>
          <Box sx={styles.labelContainer}>
            <Typography variant="h4" paddingLeft={2}>
              Time (minutes)<span>*</span>
            </Typography>
            <InputField
              value={custom_resource_data?.resourceTime}
              placeholder="e.g: 20"
              variant="outlined"
              type="number"
              autoComplete="off"
              onKeyDown={(evt) => {
                let checkIfNum = false;
                if (evt.key !== undefined) {
                  // Check if it's a "e", ".", "+" or "-"
                  checkIfNum =
                    evt.key === 'e' ||
                    evt.key === '.' ||
                    evt.key === '+' ||
                    evt.key === '-';
                }
                return checkIfNum && evt.preventDefault();
              }}
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  dispatch(
                    setCustomResourceData({
                      ...custom_resource_data,
                      resourceTime: e.target.value,
                    })
                  );
                }
              }}
              fullWidth
              nonCircular
              borderColor="#61BAFF"
              fontColor="#1D1D1D"
              sx={{
                width: { md: pxTovW(160) },
                background: '#DAF5FF',
                '& input[type=number]': {
                  '-moz-appearance': 'textfield' /* Firefox */,
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                    { '-webkit-appearance': 'none', margin: 0 },
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: { xs: pxToRem(220), md: pxTovW(344) },
              height: { xs: pxToRem(19), md: pxTovW(34) },
              justifyContent: 'space-between',
              marginTop: { xs: pxToRem(40), md: pxTovW(15) },
            }}
          >
            <Box sx={styles.radioDetails}>
              <Radio sx={styles.radio} {...controlRadioProps('Locked')} />
              <Typography variant="h2" fontWeight="regular">
                Locked
              </Typography>
            </Box>

            <Box sx={styles.radioDetails}>
              <Radio sx={styles.radio} {...controlRadioProps('Unlocked')} />
              <Typography variant="h2" fontWeight="regular">
                Unlocked
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={styles.rightPanel}>
          <DragAndDropFiles
            handleDropFunction={handleDropFunction}
            handleChangeFunction={handleChangeFunction}
            handleDeleteFunction={handleDeleteFunction}
            userUploads={
              custom_resource_data?.custom_resource_uploaded_files || []
            }
            variantSecond
            reorderDragStart={reorderDragStart}
            reorderDragEnter={reorderDragEnter}
            reorderDrop={reorderDrop}
            dragOverItemIndex={dragOverItemIndex}
          />
        </Box>

        <ActionsPopup
          open={goingBackPopup}
          handleClose={() => {
            setGoingBackPopup(false);
          }}
          fontSmall
          iconName="delete"
          popupText={`Are you sure you want to go back ? All progress will be lost.`}
          yesClickHandler={async () => {
            setSelectedFunction(() => navigate(-1));
            // dispatch(setCustomResourceData(undefined));
          }}
          noClickHandler={async () => {
            setGoingBackPopup(false);
          }}
        />
      </Box>
    </Box>
  );
};
