import {
  IStyles,
  ImageWrapper,
  NumericalInputField,
  PrimaryButton,
  StudentScoreCard,
  deserify,
  firstLetterImage,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import {
  StudentSubmissionInfo,
  TeacherStudentResponseFetch,
} from '@protos/learning_management/lms.hw.teacher.apis_pb';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../reduxStore/reduxHooks';
import { interactionEvent } from '../../../Auth/auth.events';
import { setToastInfo } from '../../../Home/reducer/homeDashboard.slice';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
const styles: IStyles = {
  root: {},
  withoutPercentage: {
    display: 'flex',
    gap: { xs: pxToRem(8), md: pxTovW(9) },
    mt: { xs: pxToRem(20), md: pxTovW(22) },
    // width: '100%',
    // width: { xs: pxToRem(200), md: pxTovW(573) },
  },
  headImage: {
    width: { xs: pxToRem(60), md: pxTovW(80) },
    height: { xs: pxToRem(56), md: pxTovW(80) },
    borderRadius: pxToRem(15),
  },

  withPercentage: {
    mt: { xs: pxToRem(20), md: pxTovW(22) },
  },
  marksBox: {
    mt: { xs: pxToRem(20), md: pxTovW(32) },
    // width: { xs: pxToRem(200), md: pxTovW(573) },
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
    boxShadow: `0px 0px ${pxToRem(10)} #00000014`,
    p: {
      xs: `${pxToRem(20)} ${pxToRem(0)}`,
      md: `${pxTovW(46)} ${pxTovW(0)} ${pxTovW(69)}`,
    },
    // height: { xs: pxToRem(200), md: pxTovW(370) },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: { xs: pxToRem(24), md: pxTovW(52) },
  },
  marksInputBox: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(8), md: pxTovW(8) },
    // p: { xs: `${pxToRem(0)} ${pxToRem(20)}`, md: '0' },
  },
  userImage: {
    width: { xs: pxToRem(60), md: pxTovW(80) },
    height: { xs: pxToRem(56), md: pxTovW(80) },
    borderRadius: pxToRem(10),
  },
};
interface IProps {
  studentData?: TeacherStudentResponseFetch;
  maxMarks?: number;
}

export const SubmissionStudentDetails = (props: IProps) => {
  const { studentData, maxMarks } = props;
  const dispatch = useAppDispatch();
  const location = useLocation();

  const pathSegments = location.pathname.split('/');
  const { isOffline } = useDownloadContext();
  const { LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const teacher_id = getLocalStorage('userId');
  const [marks, setMarks] = useState<string | undefined>();
  const [isFocused, setIsFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [percentageScore, setPercentageScore] = useState<number>(0);
  const { isIpadOnly } = useGlobalContext();
  const roundedPercentage = Math.round(percentageScore);

  useEffect(() => {
    if (studentData) {
      setMarks(String(studentData?.responseScore));
      const initialPercentage =
        (studentData.responseScore / (studentData.maxScore || 100)) * 100;
      setPercentageScore(Math.round(initialPercentage));
    }
  }, [studentData]);

  const doneClickHandler = () => {
    setLoading(true);
    MarksSubmitHandler(Number(marks));
    // if (marks !== undefined) {
    //   // Recalculate the percentage based on the new marks
    //   const newPercentage = (Number(marks) / (maxMarks || 100)) * 100;
    //   setPercentageScore(Math.round(newPercentage));
    // }
  };

  const { selected_assessment_id } = deserify(
    useAppSelector((state) => state.manageHomework)
  );
  const handleMarksChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (
      value === '' ||
      (Number(value) <= (maxMarks ?? 0) && Number(value) >= 0)
    ) {
      setMarks(value);
      setErrorMessage(''); // Clear error message if input is valid
    } else {
      setErrorMessage(`Marks cannot exceed ${maxMarks}`);
    }
  };
  //online Submission
  const MarksSubmitHandler = async (marks?: number) => {
    if (!studentData) {
      return;
    }
    if (marks) {
      const regex = /^\d+$/;
      if (!regex.test(marks.toString())) {
        dispatch(
          setToastInfo({
            label: 'Marks can be whole numbers only',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
    }
    try {
      const response =
        await LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler.studentHWPhysicalSubmissionUpdate(
          {
            teacherId: BigInt(teacher_id),
            homeworkId: selected_assessment_id,
            submissionsInfo: [
              new StudentSubmissionInfo({
                studentId: studentData?.studentId,
                isSubmitted: true,
                obtainedMarks: marks,
              }),
            ],
            maxMarks: maxMarks || 0,
          }
        );
      if (response) {
        setLoading(false);
        if (marks !== undefined) {
          // Recalculate the percentage based on the new marks
          const newPercentage = (Number(marks) / (maxMarks || 100)) * 100;
          setPercentageScore(Math.round(newPercentage));
        }

        await interactionEvent({
          url: '',
          context: 'enter_marks',
          name: 'DONE',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ width: { md: '100%', lg: 'unset' } }}>
      <Typography variant={'h1'} color="initial">
        Performance
      </Typography>

      <Box sx={{ width: { xs: '100%', md: pxToRem(370), lg: pxTovW(573) } }}>
        {maxMarks ? (
          <Box sx={styles.withPercentage}>
            <StudentScoreCard
              imageUrl={
                getMediaBasePath(studentData?.profileImageUrl) ||
                firstLetterImage(studentData?.studentName)
              }
              studentName={studentData?.studentName || ''}
              score={roundedPercentage}
              withoutArrow
            />

            <Box sx={styles.marksBox}>
              <Typography
                variant={'h2'}
                color="initial"
                sx={{
                  fontSize: { xs: pxToRem(21), lg: pxTovW(27) },
                  fontFamily: 'poppins',
                  fontWeight: 'medium',
                }}
              >
                Enter Marks
              </Typography>

              <Box sx={styles.marksInputBox}>
                <NumericalInputField
                  variant="outlined"
                  value={marks}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    // const value = e.target.value;
                    // value ? setMarks(Number(value)) : setMarks(undefined);
                    handleMarksChange(e);
                  }}
                />

                <Typography
                  color="initial"
                  sx={{
                    fontSize: { xs: pxToRem(16), lg: pxTovW(19) },
                    fontFamily: 'poppins',
                    fontWeight: 'medium',
                  }}
                >
                  out of {maxMarks} Marks
                </Typography>
              </Box>

              <PrimaryButton
                onClick={doneClickHandler}
                sx={{ width: { xs: pxToRem(144), lg: pxTovW(245) } }}
              >
                <Typography
                  variant={'h3'}
                  color={'common.white'}
                  fontWeight={700}
                  sx={{ fontSize: { xs: pxToRem(14), lg: pxTovW(19) } }}
                >
                  DONE
                </Typography>
              </PrimaryButton>
            </Box>
          </Box>
        ) : (
          <Box sx={styles.withoutPercentage}>
            <Box>
              {studentData && (
                <ImageWrapper
                  name="student1"
                  type="png"
                  parentFolder="tempAssets"
                  styles={styles.headImage}
                  path={
                    getMediaBasePath(studentData?.profileImageUrl) ||
                    firstLetterImage(studentData?.studentName)
                  }
                />
              )}
            </Box>
            <Typography variant="h3" fontWeight="bold">
              {studentData?.studentName}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
