import {
  DatePicker,
  IStyles,
  IconWrapper,
  InputField,
  InstructionsPanel,
  Loader,
  PrimaryButton,
  TimePicker,
  deserify,
  getLocalStorage,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
  theme,
  MultiSelect,
  ToggleSwitch,
} from '@geneo2-web/shared-ui';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Question } from '@protos/content_management/content.db_pb';
import {
  AssignmentConfigEnum,
  AssignmentInfo,
  TaskEnum,
} from '@protos/learning_management/lms.db_pb';
import { TeacherHWAssignmentSubmitResponse } from '@protos/learning_management/lms.hw.teacher.apis_pb';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../reduxStore/reduxHooks';

import {
  calculateMinMaxTime,
  combineStartTimeAndDate,
  convertTimeStringToTimestamp,
} from '../../../../utils/functions';
import { interactionEvent } from '../../../Auth/auth.events';
import { setToastInfo } from '../../../Home/reducer/homeDashboard.slice';

import {
  resetAssessmentInstructions,
  setCreatedAssessmentDetails,
  setAssessmentInstructions,
  setTemporarySectionIds,
  resetCustomHomework,
} from '../../reducer/assessmentFlow.slice';
import { HomeworkInstructions } from './components/HomeworkInstructions';
import { aiHomeworkCreateEvent } from '../../../Homework/homework.events';
import {
  ASSESSMENT_CONGRATULATIONS,
  FORMATIVE,
  // HOMEWORK_CONGRATULATIONS,
} from '../../../../routeHandling/RoutesNomenclature';
import { PartialMessage, Timestamp } from '@bufbuild/protobuf';

import { AssessmentAssignmentSubmitResponse } from '@protos/learning_management/lms.assessment.teacher.apis_pb';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';

const styles: IStyles = {
  root: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: { xs: pxToRem(20), lg: pxTovW(241) },
    paddingRight: { xs: pxToRem(20), lg: pxTovW(241) },
    paddingTop: { xs: pxToRem(20), lg: pxTovW(40) },
    paddingBottom: { xs: pxToRem(20), lg: pxTovW(40) },
    boxSizing: 'border-box',
    overflowX: 'hidden',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
  },
  inputFeildBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(40) },
    width: { xs: '100%', md: pxTovW(856) },
    // backgroundColor: 'blue',
    mb: '2.343vw', //32px
  },
  instructionBox: {
    width: { xs: '100%' },
    // boarder: '1px solid #CCE6FE',
    boxSizing: 'border-box',
    marginTop: { xs: '30px', sm: '30px', md: 0 },
  },
  instructionCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: { xs: pxToRem(14), lg: pxTovW(10) },
    boxSizing: 'border-box',
    paddingBottom: { xs: pxToRem(14), md: pxTovW(47) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    width: { xs: '100%' },
    backgroundColor: '#F6FBFF',
    border: '1px solid #CCE6FE',
    gap: { xs: pxToRem(20), md: pxTovW(16) },
    marginBottom: { xs: pxToRem(60) },
    // backgroundColor: 'blue',
  },
  textBox: {
    display: 'flex',
    // alignItems: 'center',
    width: '100%',
    paddingLeft: { md: pxTovW(10) },
    paddingRight: { md: pxTovW(10) },
    gap: { xs: pxToRem(6.61), md: pxTovW(6.61) },
    // paddingTop: '20px',
    alignItems: 'flex-start',
  },
  inputField: {
    // backgroundColor: "red",
    height: { xs: pxToRem(50), md: pxTovW(70) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    border: '1px solid #CCE6FE',
    width: { xs: '100%', md: pxTovW(856) },
    justifyContent: 'center',
  },
  timeFeild: {
    height: { xs: pxToRem(50), md: pxTovW(70) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    border: '1px solid #CCE6FE',
    width: { xs: pxToRem(145), md: pxTovW(200) },
    alignItems: 'center',
    justifyContent: 'center',
  },
  reminderFeild: {
    width: { xs: pxToRem(311), md: pxTovW(419) },
    height: { xs: pxToRem(50), md: pxTovW(70) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    border: '1px solid #CCE6FE',
    // alignItems: 'center',
    justifyContent: 'center',
  },
  inputBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
  },
  label: {
    color: 'black',
    '@media(max-width:640px)': { fontSize: pxToRem(30) },
  },

  multySelect: {
    height: { xs: pxToRem(60), md: pxTovW(70) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    border: '1px solid #CCE6FE',

    justifyContent: 'center',
  },
  lableToggle: {
    fontSize: { xs: '1.4rem', sm: '1.4rem', md: '0.8rem', lg: '1rem' },
  },
};

interface IDataList {
  name: string;
  id: number;
  selected: boolean;
}
interface IHomeworkData {
  taskName: string;
  dailyReminder: string;
  allowLateSubmission: boolean;
  duration: string;
  homeworkSections: any[];
  shufflingAllowed: boolean;
  reattemptAllowed: boolean;
  timeTest: boolean;
}
export const AssignAssessment = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const pathname = location.pathname;
  const isFormativeAssessment = pathname.includes(FORMATIVE);
  const pathSegments = location.pathname.split('/');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const CustomHw = true;
  const { isOffline } = useDownloadContext();
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const { class_subject_info } = deserify(
    useAppSelector((state) => state.homeDashboard)
  );
  // const {
  //   fetched_hw_details,
  //   submitted_hw_id,
  //   module_filtered_questions,
  //   questions_count,
  //   homework_instructions,
  //   custom_homework,
  // } = deserify(useAppSelector((state) => state.homework));
  const {
    fetched_assessment_details,
    submitted_assessment_id,
    module_filtered_questions,
    questions_count,
    assessment_instructions,
    custom_homework,
  } = deserify(useAppSelector((state) => state.assessment));

  // LmsAssessmentTeacherAPIServiceV1
  const {
    LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler,
    LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler,
  } = useCommonServiceClientContext();

  const { setSelectedFunction } = useGlobalContext();
  const [openInstructionsPopup, setOpenInstructionsPopup] = useState(false);
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const [sectionList, setSectionList] = useState<IDataList[]>(
    // exampleSectionList
    user_info?.teachClassSubjects
      ?.filter((elem) => class_subject_info?.classId === elem.classId)
      .map((elem) => ({
        id: elem.sectionId,
        name: elem.sectionName,
        selected:
          class_subject_info?.sectionId === elem.sectionId ? true : false,
      })) || []
  );
  const [toggleAssignmentInfoList, setToggleAssignmentInfoList] = useState<
    PartialMessage<AssignmentInfo>[]
  >(() => {
    return initialToggleData.map((item) => {
      if (item.configName === AssignmentConfigEnum.ASSIGNMENT_CONFIG_TIMED) {
        return {
          ...item,
          isEnabled: isFormativeAssessment ? true : item.isEnabled,
        };
      }
      return item; // Return all other items unchanged
    });
  });

  const checkToggle = (config: AssignmentConfigEnum) => {
    const toggleInfo = toggleAssignmentInfoList.find(
      (toggleData) => toggleData.configName === config
    );
    return toggleInfo?.isEnabled ? toggleInfo?.isEnabled : false;
  };
  const handleToggleSwitch = (config: AssignmentConfigEnum) => {
    setToggleAssignmentInfoList((prevState) =>
      prevState.map((data) =>
        data.configName === config
          ? { ...data, isEnabled: !data.isEnabled }
          : data
      )
    );
  };

  const sections = ['A', 'B', 'C', 'D', 'E'];
  const [selectedSections, setSelectedSections] = useState([
    'A',
    'B',
    'C',
    'D',
    'E',
  ]);

  const [homeworkData, setHomeworkData] = useState<IHomeworkData>({
    // taskName: fetched_hw_details?.homeworkTitle || '',
    taskName: '',
    dailyReminder: '18:00',
    allowLateSubmission: false,
    duration: isFormativeAssessment ? '50' : '',
    homeworkSections: selectedSections,
    shufflingAllowed: checkToggle(
      AssignmentConfigEnum.ASSIGNMENT_CONFIG_SHUFFLING
    ),
    reattemptAllowed: checkToggle(
      AssignmentConfigEnum.ASSIGNMENT_CONFIG_REATTEMPT
    ),
    timeTest: checkToggle(AssignmentConfigEnum.ASSIGNMENT_CONFIG_TIMED),
  });
  const [startTimeValues, setStartTimeValues] = useState({
    timeValue: `${new Date().getHours()} : ${new Date().getMinutes()}`,
    dateValue: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    ).toISOString(), // Includes timezone offset
  });
  const [endTimeValues, setEndTimeValues] = useState({
    timeValue: '23:59',
    dateValue: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    ).toISOString(),
  });

  const teacher_id = deserify(getLocalStorage('userId'));
  const backButtonClick = async () => {
    dispatch(resetAssessmentInstructions());
    if (!custom_homework) {
      navigate(-1);
    }
  };
  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setHomeworkData({ ...homeworkData, [name]: value });
    console.log('form....', name, '---', value);
  };
  const assignClickHandler = () => {
    if (
      startTimeValues.timeValue !== '' &&
      startTimeValues.dateValue !== '' &&
      endTimeValues.dateValue !== '' &&
      endTimeValues.timeValue !== ''
    ) {
      const currentTimeIsoString = new Date().toISOString();
      //if start time smaller than current Time make current Time as start Time
      const startTimeIsoString =
        currentTimeIsoString > combineStartTimeAndDate(startTimeValues)
          ? currentTimeIsoString
          : combineStartTimeAndDate(startTimeValues);

      const endTimeIsoString = combineStartTimeAndDate(endTimeValues);
      const startTime = new Date(startTimeIsoString);
      const endTime = new Date(endTimeIsoString);
      // const date = new Date();
      // const currentTime = new Date(
      //   Math.floor(date.getTime() / 300000) * 300000
      // );
      if (endTime < new Date(Date.now())) {
        dispatch(
          setToastInfo({
            variant: 'error',
            label: 'Assessment  deadline has passed.',
            open: true,
          })
        );
      }
      // else if (startTime < currentTime) {
      //   console.log({ starttime: startTime, currentTime: currentTime });
      //   dispatch(
      //     setToastInfo({
      //       variant: 'error',
      //       label: 'Start date and time has already passed',
      //       open: true,
      //     })
      //   );
      // }
      else if (startTime > endTime) {
        dispatch(
          setToastInfo({
            variant: 'error',
            label: 'Start date connot be after End date',
            open: true,
          })
        );
      } else if (startTime.toString() === endTime.toString()) {
        dispatch(
          setToastInfo({
            variant: 'error',
            label: 'Start date and time cannot be same as End date and time',
            open: true,
          })
        );
      } else if (homeworkData.taskName === '') {
        dispatch(
          setToastInfo({
            variant: 'error',
            label: 'Assessment  Name Cannot be empty',
            open: true,
          })
        );
      }
      // else if (homeworkData.duration === '') {
      //   dispatch(
      //     setToastInfo({
      //       variant: 'error',
      //       label: 'Duration Cannot be empty',
      //       open: true,
      //     })
      //   );
      // }
      else {
        assignmentSubmission({ startTime: startTime, endTime: endTime });
        // console.log({ starttime: startTime, currentTime: currentTime });
        // console.log(startTime, endTime);
      }
    } else {
      dispatch(
        setToastInfo({
          variant: 'error',
          label: 'Please select all Date and Time',
          open: true,
        })
      );
    }
  };

  interface ISubmissionProps {
    startTime: Date;
    endTime: Date;
  }

  const assignmentSubmission = async (props: ISubmissionProps) => {
    const { endTime, startTime } = props;
    try {
      setLoading('loading');
      const startTimeIsoString = combineStartTimeAndDate(startTimeValues);
      const endTimeIsoString = combineStartTimeAndDate(endTimeValues);

      const assessmentSubmitPayload = {
        assessmentName: homeworkData.taskName,
        assignmentInfo: toggleAssignmentInfoList,
        dailyReminderTime: convertTimeStringToTimestamp(
          homeworkData.dailyReminder
        ),
        endTime: Timestamp.fromDate(endTime),
        sectionIds: sectionList
          .filter((val) => val.selected)
          .map((val) => val.id),
        startTime: Timestamp.fromDate(startTime),
        teacherId: teacher_id,
        timeDurationInMin: !checkToggle(
          AssignmentConfigEnum.ASSIGNMENT_CONFIG_TIMED
        )
          ? undefined
          : Number(homeworkData.duration),
      };

      // let response: TeacherHWAssignmentSubmitResponse | null = null;
      let assessmentResponse: AssessmentAssignmentSubmitResponse | null = null;
      if (custom_homework?.taskType === TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM) {
        assessmentResponse =
          await LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler.assessmentAssignmentSubmit(
            {
              ...assessmentSubmitPayload,
              assessmentId: custom_homework.assessmentId,
              assessmentInstructions: custom_homework.assessmentInstructions,
            }
          );
      } else {
        assessmentResponse =
          await LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler.assessmentAssignmentSubmit(
            {
              ...assessmentSubmitPayload,
              assessmentId: submitted_assessment_id,
              assessmentInstructions: assessment_instructions,
            }
          );
      }
      if (assessmentResponse?.data) {
        setLoading('completed');
        // dispatch(setCreatedHWDetails(response.data?.updatedHW));
        dispatch(setCreatedAssessmentDetails(assessmentResponse.data));
        dispatch(
          setTemporarySectionIds(
            sectionList.filter((val) => val.selected).map((val) => val.id)
          )
        );

        const questionInfoArray = module_filtered_questions?.questions?.map(
          (que: any) => {
            return {
              questionId: que.questionId,
              difficultyLevel: que.questionMeta.difficultyLevel,
            };
          }
        );

        const [hours, minutes] = homeworkData.dailyReminder
          .split(':')
          .map(Number);
        const reminderTime = new Date();
        reminderTime.setHours(hours);
        reminderTime.setMinutes(minutes);

        await aiHomeworkCreateEvent({
          homeworkId: submitted_assessment_id,
          name: homeworkData.taskName,
          estimateTime: Number(timeOfQuestions()),
          questionInfo: questionInfoArray,
          noOfQuestions: module_filtered_questions?.questions.length,
          marks: totalMarks(module_filtered_questions?.questions),
          // startTimestamp: Timestamp.fromDate(new Date(startTimeIsoString)),
          startTimestamp: new Date(startTimeIsoString),
          // endTimestamp: Timestamp.fromDate(new Date(endTimeIsoString)),
          endTimestamp: new Date(endTimeIsoString),
          allowLateSubmission: homeworkData.allowLateSubmission,
          // dailyRemainder: convertTimeStringToTimestamp(
          //   homeworkData.dailyReminder
          // ),
          dailyRemainder: reminderTime,
          isOffline: isOffline,
        });

        dispatch(resetAssessmentInstructions());
        dispatch(resetCustomHomework());
        navigate(
          `${ASSESSMENT_CONGRATULATIONS}/${assessmentResponse.data.updatedAssessment?.assessmentId}`
        );

        await interactionEvent({
          url: '',
          context: 'assign_homework',
          name: 'ASSIGN',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
      }
    } catch (err: any) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: `${err.message
            ? err.message.replace(/.*\]\s*/, '').trim()
            : 'Assign homework Failed'
            }`,
          variant: 'error',
          open: true,
        })
      );
      console.log(err);
    }
  };

  const timeOfQuestions = () => {
    let time = 0;
    if (module_filtered_questions) {
      time = module_filtered_questions.questions.reduce((acc, curr) => {
        const c = curr.question?.model.value;
        const currentTime = c ? c.commonQuestionContent?.time || 0 : 0;
        return acc + currentTime;
      }, 0);
      return (time / 60).toFixed(2);
    }
    return `${calculateMinMaxTime(questions_count).min}-${calculateMinMaxTime(questions_count).max
      }`;
  };

  const totalMarks = (question?: Question[]) => {
    if (!question) {
      return 0;
    }
    const marks = question.reduce(
      (acc, curr) =>
        acc +
        (curr.question?.model.value?.commonQuestionContent?.marks[0] || 0),
      0
    );
    return marks;
  };

  let mobileCssToggle: object = {};

  if (isMobile) {
    mobileCssToggle = {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right',
      marginTop: '10px',
      flexDirection: 'row',
    };
  } else {
    mobileCssToggle = { alignItems: 'center', marginTop: '10px', flex: 1 };
  }
  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box sx={styles.root}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={styles.header}>
          <Typography variant="h1">
            {isFormativeAssessment
              ? `Assign Formative Assessment`
              : `Assign Daily Homework`}
          </Typography>
          <Typography
            variant="cardText"
            sx={{ color: '#007CDC', fontWeight: 'normal', marginTop: '10px' }}
          >
            Class {class_subject_info?.classname || ''}
            {class_subject_info?.section || ''} |{' '}
            {class_subject_info?.subject || ''}
          </Typography>
        </Box>
        <PrimaryButton
          onClick={assignClickHandler}
          sx={{ display: { xs: 'none', md: 'unset' } }}
        >
          Assign
        </PrimaryButton>
      </Box>

      <Box
        sx={{
          boxSizing: 'border-box',
          // paddingLeft: { xs: pxToRem(20), md: pxTovW(40) },
          // paddingRight: { xs: pxToRem(20), md: pxTovW(40) },
          display: { md: 'flex' },
          gap: { xs: pxToRem(10), md: pxTovW(36) },
        }}
      >
        <Box sx={styles.inputFeildBox}>
          <Box sx={styles.inputBox}>
            <Typography variant="bodyText">Assessment Name</Typography>
            <InputField
              nonCircular
              value={homeworkData.taskName}
              name="taskName"
              onChange={handleChange}
              variant="outlined"
              sx={styles.inputField}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '1vw',
              flexDirection: { xs: 'column', lg: 'row' },
            }}
          >
            <Box sx={styles.inputBox}>
              <Typography variant="bodyText">Start Date</Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: '2vw',
                }}
              >
                <DatePicker
                  value={startTimeValues.dateValue}
                  handleDateChange={(date) => {
                    console.log(startTimeValues.dateValue);
                    setStartTimeValues({ ...startTimeValues, dateValue: date });
                  }}
                />
                <TimePicker
                  date={startTimeValues.dateValue}
                  value={startTimeValues.timeValue}
                  handleTimeChange={(time) => {
                    setStartTimeValues({ ...startTimeValues, timeValue: time });
                  }}
                  disabled={startTimeValues.dateValue === '' ? true : false}
                />
              </Box>
            </Box>

            <Box sx={styles.inputBox}>
              <Typography variant="bodyText">End Date</Typography>
              <Box sx={{ display: 'flex', gap: '2vw' }}>
                <DatePicker
                  minDate={startTimeValues.dateValue.slice(0, 10)}
                  value={endTimeValues.dateValue}
                  handleDateChange={(date) =>
                    setEndTimeValues({ ...endTimeValues, dateValue: date })
                  }
                />
                <TimePicker
                  disabled={
                    startTimeValues.timeValue === '' ||
                      endTimeValues.dateValue === ''
                      ? true
                      : false
                  }
                  minTime={
                    startTimeValues.dateValue === endTimeValues.dateValue
                      ? startTimeValues.timeValue
                      : undefined
                  }
                  date={endTimeValues.dateValue}
                  value={endTimeValues.timeValue}
                  handleTimeChange={(time) => {
                    setEndTimeValues({ ...endTimeValues, timeValue: time });
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'space-between', md: 'flex-start' },
              gap: { md: pxTovW(27) },
              flexDirection: { xs: 'column-reverse', md: 'row' },
            }}
          >
            <Box
              sx={{
                ...styles.inputBox,
                width: { md: pxTovW(409) },
                flexGrow: { xs: '1', md: '0' },
              }}
            >
              <Typography variant="bodyText">Daily Reminder</Typography>
              <Box>
                <TimePicker
                  fullWidth
                  value={homeworkData.dailyReminder}
                  name="dailyReminder"
                  handleTimeChange={(time) => {
                    setHomeworkData((prev) => ({
                      ...prev,
                      dailyReminder: time,
                    }));
                  }}
                />
              </Box>
            </Box>

            {/* <Box
              sx={{
                ...styles.inputBox,
                width: { md: pxTovW(409) },
                flexGrow: { xs: '1', md: '0' },
              }}
            >
              <Typography variant="bodyText">Allow Late Submission</Typography>
              <Box>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue={homeworkData.allowLateSubmission}
                  defaultValue={checkToggle(
                    AssignmentConfigEnum.ASSIGNMENT_CONFIG_ALLOW_LATE_SUBMISSION
                  )}
                  name="allowLateSubmission"
                  // onChange={handleChange}
                  onChange={(event) => {
                    let bolHold = false;
                    if (event.target.value === 'true') bolHold = true;

                    setToggleAssignmentInfoList((prevState) =>
                      prevState.map((data) =>
                        data.configName ===
                        AssignmentConfigEnum.ASSIGNMENT_CONFIG_ALLOW_LATE_SUBMISSION
                          ? { ...data, isEnabled: bolHold }
                          : data
                      )
                    );
                  }}
                  sx={{ display: 'inline' }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
            </Box> */}

            {/* <Box sx={styles.inputBox}>
              <Typography variant="bodyText">Allow Late Submission</Typography>
              <RadioGroupForm
                homeworkData={homeworkData}
                setHomeworkData={setHomeworkData}
              />
            </Box> */}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'space-between', md: 'flex-start' },
              gap: { md: pxTovW(27) },
              flexDirection: { xs: 'column-reverse', md: 'row' },
            }}
          >
            <Box
              sx={{
                ...styles.inputBox,
                width: { md: pxTovW(409) },
                flexGrow: { xs: '1', md: '0' },
              }}
            >
              <Typography variant="bodyText">{`Duration (Min)`}</Typography>{' '}
              {!checkToggle(AssignmentConfigEnum.ASSIGNMENT_CONFIG_TIMED) ? (
                <Box
                  sx={{
                    height: { xs: pxToRem(30), md: pxTovW(64) },
                    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
                    backgroundColor: '#F2F2F2',
                  }}
                ></Box>
              ) : (
                <InputField
                  value={homeworkData.duration}
                  name="duration"
                  onChange={handleChange}
                  variant="outlined"
                  nonCircular
                  type="number"
                  autoComplete="off"
                  onKeyDown={(evt) => {
                    let checkIfNum = false;
                    if (evt.key !== undefined) {
                      checkIfNum =
                        evt.key === 'e' ||
                        evt.key === '.' ||
                        evt.key === '+' ||
                        evt.key === '-';
                    }
                    return checkIfNum && evt.preventDefault();
                  }}
                  sx={{
                    borderRadius: { xs: pxToRem(0.8), md: pxTovW(0.8) },
                    '& input[type=number]': {
                      '-moz-appearance': 'textfield',
                      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                      {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                    },
                  }}
                />
              )}
            </Box>

            <Box
              sx={{
                ...styles.inputBox,
                width: { md: pxTovW(409) },
                flexGrow: { xs: '1', md: '0' },
              }}
            >
              <Typography variant="bodyText">Choose Section</Typography>
              <MultiSelect
                selectAllTitle="All Divisions"
                inputName="homeworkSection"
                optionList={sections}
                handleChange={handleChange}
                dataList={sectionList}
                setDataList={setSectionList}
              />
            </Box>
          </Box>

          <Box>
            <Box>
              <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
                <Box sx={mobileCssToggle}>
                  <Box
                    sx={{
                      textAlign: 'left',
                    }}
                  >
                    <Typography variant="bodyText" sx={styles.lableToggle}>
                      Question Shuffling
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      marginLeft: 'auto',
                      marginTop: { xs: '1px', md: '10px' },
                    }}
                  >
                    <ToggleSwitch
                      checked={checkToggle(
                        AssignmentConfigEnum.ASSIGNMENT_CONFIG_SHUFFLING
                      )}
                      handleChange={() =>
                        handleToggleSwitch(
                          AssignmentConfigEnum.ASSIGNMENT_CONFIG_SHUFFLING
                        )
                      }
                    />
                  </Box>
                </Box>

                <Box sx={mobileCssToggle}>
                  <Box>
                    <Typography variant="bodyText" sx={styles.lableToggle}>
                      Reattempt
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      marginLeft: 'auto',
                      marginTop: { xs: '1px', md: '10px' },
                    }}
                  >
                    <ToggleSwitch
                      checked={checkToggle(
                        AssignmentConfigEnum.ASSIGNMENT_CONFIG_REATTEMPT
                      )}
                      handleChange={() =>
                        handleToggleSwitch(
                          AssignmentConfigEnum.ASSIGNMENT_CONFIG_REATTEMPT
                        )
                      }
                    />
                  </Box>
                </Box>

                <Box sx={mobileCssToggle}>
                  <Box>
                    <Typography variant="bodyText">Time Test</Typography>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: 'auto',
                      marginTop: { xs: '1px', md: '10px' },
                    }}
                  >
                    <ToggleSwitch
                      checked={checkToggle(
                        AssignmentConfigEnum.ASSIGNMENT_CONFIG_TIMED
                      )}
                      handleChange={() =>
                        handleToggleSwitch(
                          AssignmentConfigEnum.ASSIGNMENT_CONFIG_TIMED
                        )
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {custom_homework?.taskType === TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM ? (
          <Box
            sx={{ mb: { xs: pxToRem(60), md: 0 }, marginTop: { sx: '50px' } }}
          >
            <InstructionsPanel
              customSx={{
                display: 'flex',
                flexGrow: 1,
                width: { xs: '100%', md: pxTovW(900), lg: pxTovW(555) },
                padding: pxToRem(20),
                // height: pxToRem(450),
              }}
              editIconDisplay
              Katexvalue={custom_homework?.assessmentInstructions?.[0]}
              AttachmentList={custom_homework.taskUploads.filter(
                (e) => e.isDeleted === false
              )}
              onRedirect={async () => {
                navigate(-1);
                await interactionEvent({
                  url: '',
                  context: 'instructions',
                  name: 'EDIT',
                  pathSegments: pathSegments,
                  isOffline: isOffline,
                });
              }}
            />
          </Box>
        ) : (
          <Box sx={styles.instructionBox}>
            <Box sx={styles.instructionCard}>
              <Box
                sx={{
                  height: '5px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  // padding: { md: pxTovW(20) },
                }}
              >
                <IconWrapper
                  name="edit"
                  parentFolder="icons"
                  type="png"
                  size="md"
                  customSx={{
                    height: { xs: pxToRem(34), md: pxTovW(34) },
                    width: { xs: pxToRem(34), md: pxTovW(34) },
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpenInstructionsPopup(true)}
                />
              </Box>
              <Box
                sx={{
                  ...styles.textBox,
                }}
              >
                <Typography variant="h2" fontWeight="bold">
                  Instructions :
                </Typography>
              </Box>
              {assessment_instructions?.map((elem: string, i: number) => (
                <Box key={i} sx={styles.textBox}>
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <CheckOutlinedIcon fontSize="small" color="primary" />
                  </Box>
                  <Typography
                    variant={!isMobile && isIpad ? 'h3' : 'h4'}
                    sx={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {elem}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <HomeworkInstructions
          instructions={assessment_instructions}
          setInstructions={(arr) => dispatch(setAssessmentInstructions(arr))}
          open={openInstructionsPopup}
          handleClose={() => setOpenInstructionsPopup(false)}
        />
      </Box>

      <Box
        sx={{
          display: { xs: 'unset', md: 'none' },
          position: 'fixed',
          top: { md: pxTovW(126) },
          left: { xs: 'unset', md: pxTovW(1450), lg: pxTovW(1279) },
          bottom: { xs: pxToRem(10), md: '100%' },
          width: { xs: '90vw', md: 'max-content' },
        }}
      >
        <PrimaryButton onClick={assignClickHandler}>Assign</PrimaryButton>
      </Box>
    </Box>
  );
};

const initialToggleData = [
  {
    configName: AssignmentConfigEnum.ASSIGNMENT_CONFIG_REATTEMPT,
    isEnabled: true,
  },
  {
    configName: AssignmentConfigEnum.ASSIGNMENT_CONFIG_SHUFFLING,
    isEnabled: true,
  },
  {
    configName: AssignmentConfigEnum.ASSIGNMENT_CONFIG_TIMED,
    isEnabled: false,
  },
  {
    configName: AssignmentConfigEnum.ASSIGNMENT_CONFIG_ALLOW_LATE_SUBMISSION,
    isEnabled: false,
  },
];

const exampleSectionList = [
  {
    name: 'A',
    id: 1,
    selected: true,
  },
  {
    name: 'B',
    id: 2,
    selected: true,
  },
  {
    name: 'C',
    id: 3,
    selected: true,
  },
];
