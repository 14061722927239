import { theme } from '@geneo2-web/shared-ui';
import { Typography, useMediaQuery } from '@mui/material';
import { Box, display } from '@mui/system';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';
import ReactApexChart from 'react-apexcharts';
interface IProps {
  color: string;
  title?: string;
  obtainedMarks: number;
  maxMarks: number;
  variant: 'small' | 'medium' | 'large';
}
interface IChartComponentProps {
  options: {
    chart: { type: 'donut'; width: number };
    legend: { show: boolean };
    dataLabels: { enabled: boolean };
    tooltip: { enabled: boolean };

    // fill: { colors: string[] };
    states: {
      hover: { filter: { type: string; value: number } };
      active: { filter: { type: string; value: number } };
    };
    stroke: { width: number };
  };
}
const chartData = {
  options: {
    chart: { type: 'donut', width: 200, innerWidth: 50, outerWidth: 200 },
    legend: { show: false },
    dataLabels: { enabled: false },

    tooltip: { enabled: false },
    // fill: { colors: ['#9B9B9B', '#7FD320'] },
    states: {
      hover: { filter: { type: 'lighten', value: 0.5 } },
      active: { filter: { type: 'none', value: 0 } },
    },
    stroke: { width: 0 },
  },
};
export const PieChart = (props: IProps) => {
  const { color, title, obtainedMarks, maxMarks, variant } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {' '}
      <ReactApexChart
        series={[
          Math.round((obtainedMarks / maxMarks) * 100),
          100 - Math.round((obtainedMarks / maxMarks) * 100),
        ]}
        options={{
          ...(chartData.options as ApexCharts.ApexOptions),
          fill: { colors: [color, '#00000029'] },
          plotOptions: {
            pie: {
              expandOnClick: false,

              donut: {
                size: '60%',

                labels: {
                  show: true,
                  name: { show: false },
                  total: {
                    show: true,
                    showAlways: true,
                    color: 'red',

                    formatter: function () {
                      const percent = Math.round(
                        (obtainedMarks / maxMarks) * 100
                      );
                      return `${percent}%`;
                    },
                  },
                },
              },
            },
          },
        }}
        height={`${variantMapping[variant].height}`}
        width={variantMapping[variant].width}
        type="donut"
      />
      {title && (
        <Typography variant="h3" sx={{ color: color }}>
          {title}
        </Typography>
      )}
    </Box>
  );
};

const variantMapping = {
  small: {
    width: '81px',
    height: '81px',
  },
  medium: {
    width: '121px',
    height: '121px',
  },
  large: {
    width: '161px',
    height: '161px',
  },
};
