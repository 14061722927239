import {
  IClassAndSubjectSelected,
  IStyles,
  IconWrapper,
  ImageWrapper,
  Loader,
  NoContentCard,
  ScoreProgressBar,
  deserify,
  getLocalStorage,
  pxToRem,
  pxTovW,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { GetConnectedClassSessionAnalyticsResponse } from '@protos/learning_management/lms.connected.apis_pb';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useConnectedClassContext } from '../../../app/Context/ConnectedClassContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { ResultsList } from './components/resultsList';
import { TabComp } from './components/tabComponent';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { StrengthsWeaknessesSection } from './components/StrengthsWeaknessesSection';
import {
  LessonInfo,
  TeacherLessonInfo,
} from '@protos/learning_management/lms.lesson.common.apis_pb';
const styles: IStyles = {
  root: {
    marginRight: { md: pxToRem(20), lg: pxTovW(240) },
    marginLeft: { md: pxToRem(20), lg: pxTovW(240) },
    padding: {
      xs: `${pxToRem(20)} ${pxToRem(10)} ${pxToRem(20)} ${pxToRem(10)} `,
      md: 0,
    },
    marginTop: { xs: pxToRem(20), md: pxTovW(50) },
  },
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    maxHeight: { xs: '110vh', md: 'unset' },
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    marginTop: { xs: pxToRem(20), md: pxTovW(40) },
    marginBottom: { xs: pxToRem(80) },
  },
  leftPanel: {
    display: 'flex',
    flexDirection: 'column',
    width: { xs: '100%', md: pxTovW(650), lg: pxTovW(490) },
    gap: { xs: pxToRem(27), md: pxTovW(30) },
    justifyContent: 'center',
    padding: { md: pxTovW(10) },
    alignItems: 'center',
    textAlign: 'center',
    // backgroundColor: 'red',
  },
  classSummary: {
    width: '95%',
    borderRadius: { xs: pxToRem(11), md: pxTovW(15) },
    backgroundColor: '#FFFFFFFF',
    boxSizing: 'border-box',
    boxShadow: `0px 0px ${pxTovW(11)} #00000029`,
    padding: { xs: pxToRem(12), md: pxTovW(20) },
    display: 'flex',
    justifyContent: 'space-around',
  },

  instructionCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: { xs: pxToRem(14), md: pxTovW(17) },
    gap: { xs: pxToRem(10), md: pxTovW(20) },
    width: '90%',
  },
  averageScoreBox: {
    width: '95%',
    borderRadius: { xs: pxToRem(11), md: pxTovW(15) },
    height: { md: pxTovW(122) },
    backgroundColor: '#FFFFFFFF',
    boxSizing: 'border-box',
    boxShadow: `0px 0px ${pxTovW(11)} #00000029`,
    padding: { xs: pxToRem(12), md: pxTovW(20) },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'start',
    gap: { xs: pxToRem(11) },
  },
  zeroInstructionBox: {
    display: 'flex',
    width: '95%',
    flexDirection: 'column',
    background: '#FFFFFF',
    borderRadius: pxToRem(5),
    boxShadow: `0px 0px ${pxTovW(11)} #00000029`,
    padding: {
      xs: `${pxToRem(17.5)} ${pxToRem(7)}`,
      md: `${pxTovW(35)} ${pxTovW(86)}`,
    },
    marginBottom: { xs: pxToRem(30) },
    gap: { xs: pxToRem(13), md: pxTovW(13) },
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  instructionBox: {
    boxSizing: 'border-box',
    // backgroundColor: 'red',
    display: 'flex',
    width: '95%',
    flexDirection: 'column',
    background: '#FFFFFF',
    borderRadius: pxToRem(5),
    boxShadow: `0px 0px ${pxTovW(11)} #00000029`,
    padding: {
      xs: `${pxToRem(17.5)} ${pxToRem(7)}`,
      md: `${pxTovW(20)} ${pxTovW(20)}`,
    },
    marginBottom: { xs: pxToRem(30) },
  },
  strengthHeadingBox: {
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    borderBottom: '1px solid #E7F4E7',
    marginBottom: { xs: pxToRem(14), md: pxTovW(21) },
  },
  strengthBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: { md: pxTovW(20) },
    gap: { xs: pxToRem(14), md: pxTovW(21) },
  },
  weaknessHeadingBox: {
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    borderBottom: '1px solid #E7F4E7',
    marginBottom: { xs: pxToRem(14), md: pxTovW(21) },
    marginTop: { xs: pxToRem(10) },
  },
  weaknessBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(14), md: pxTovW(21) },
  },

  iconBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  textBox: {
    display: 'flex',
    gap: { xs: pxToRem(4), md: pxTovW(5) },
    alignItems: 'flex-start',
    // backgroundColor: 'red',
  },

  rightPanel: {
    width: { xs: '100%', md: '50%' },
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'unset' },
    // alignItems: 'center',
    justifyContent: { xs: 'center', md: 'unset' },
    // justifyContent: 'center',
    gap: { md: pxTovW(40) },
    marginTop: { xs: pxToRem(20), md: '0px' },
    overflowY: 'auto',
    // backgroundColor: 'red',
    paddingLeft: { xs: pxToRem(0), md: pxTovW(15) },
    paddingRight: { xs: pxToRem(0), md: pxTovW(15) },
  },
  rightHeadingBox: {
    display: 'flex',
    width: '100%',
    justifyContent: { xs: 'center', md: 'space-between' },
    alignItems: 'center',
    marginBottom: { xs: pxToRem(16), md: pxTovW(16) },
  },
  classAndSubject: {
    display: 'flex',
    gap: { xs: pxToRem(8), md: pxTovW(17.32) },
    alignItems: 'center',
  },
};

export const ConnectedClassResults = () => {
  const location = useLocation();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler } =
    useCommonServiceClientContext();

  const teacher_id = getLocalStorage('userId');
  const lesson_session_id =
    new URLSearchParams(location.search).get('lessonSessionId') || undefined;

  const mapData = ['Overall Details', 'Total Marks'];
  const [value, setValue] = useState(mapData[0]);
  const handleChange = (newValue: string) => {
    setValue(newValue);
    console.log(value);
  };
  const { baseStationData } = useConnectedClassContext();
  const { students_data } = deserify(
    useAppSelector((state) => state.connectedClass)
  );
  const { class_subject_info } = deserify(
    useAppSelector((state) => state.homeDashboard)
  );
  const { selected_lessons_info } = deserify(
    useAppSelector((state) => state.teach)
  );
  const [resultData, setResultData] = useState<
    GetConnectedClassSessionAnalyticsResponse | undefined
  >(undefined);
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );

  useEffect(() => {
    getConnectedClassSessionAnalytics();
  }, []);
  const getConnectedClassSessionAnalytics = async () => {
    setLoading('loading');
    try {
      const response =
        await LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler.getConnectedClassSessionAnalytics(
          {
            teacherId: teacher_id,
            teacherLessonSessionId: Number(lesson_session_id),
          }
        );
      // console.log('getBaseStationConnectionStatus response', response);
      if (response) {
        //
        console.log('response:', response);
        setResultData(response);
        setLoading('completed');
      } else {
        setLoading('error');
      }
    } catch (error: any) {
      setLoading('error');
      console.log(error.message);
    }
  };

  const calculatePercentage = () => {
    if (
      !resultData ||
      !resultData.noOfCorrectAnswers ||
      !resultData.noOfQuestions ||
      resultData.noOfQuestions === 0
    ) {
      return 0;
    }
    // need to confirm this

    // const totalAnswersPossible = resultData.noOfCorrectAnswers + resultData.noOfIncorrectAnswers + resultData.noOfUnansweredQuestions;
    // const percentage = (resultData.noOfCorrectAnswers / totalAnswersPossible) * 100;
    const percentage = Math.round(
      (resultData.totalScore / resultData.totalPossibleScore) * 100
    );
    return Math.max(0, Math.min(percentage, 100));
  };

  const getOnlineKeyPadsCount = () => {
    const keyPads = baseStationData?.keyPads || [];
    const onlineKeyPadsCount = keyPads.filter(
      (keypad) => keypad.isOnline
    ).length;
    const totalKeyPadsCount = baseStationData?.keyPads.length || 0;

    return `${onlineKeyPadsCount}/${totalKeyPadsCount}`;
  };
  const getClassInfo = (
    classSubjectnfo: IClassAndSubjectSelected | undefined,
    selectedLessonsInfo: LessonInfo | TeacherLessonInfo | undefined
  ) => {
    if (classSubjectnfo) {
      return {
        class: classSubjectnfo.classname,
        section: classSubjectnfo.section,
        subject: classSubjectnfo.subject,
      };
    } else if (
      selectedLessonsInfo &&
      'teachClassSubjects' in selectedLessonsInfo
    ) {
      return {
        class: selectedLessonsInfo.teachClassSubjects?.class,
        section: selectedLessonsInfo.teachClassSubjects?.section,
        subject: selectedLessonsInfo.teachClassSubjects?.subject,
      };
    } else {
      return { class: '', section: '', subject: '' };
    }
  };
  return loading === 'loading' ? (
    <Loader />
  ) : loading === 'error' ? (
    <NoContentCard variant="error" icon="error" text="Error Occured" />
  ) : (
    <Box sx={styles.root}>
      <Typography variant="h1" fontWeight="bold">
        Result Page
      </Typography>

      <Box sx={styles.container}>
        <Box sx={styles.leftPanel}>
          {largeScreen ? (
            <Box sx={{ marginRight: 'auto' }}>
              <Typography variant="h2">Overall Details</Typography>
            </Box>
          ) : (
            <TabComp
              stateValue={value}
              handleChange={handleChange}
              mapData={mapData}
            />
          )}

          {(largeScreen || value === 'Overall Details') && (
            <Box sx={styles.classSummary}>
              <ImageTextBox
                imageName="joined"
                primaryText={getOnlineKeyPadsCount()}
                secondaryText="Joined"
                borderRight
              />

              <ImageTextBox
                imageName="clock-green"
                primaryText={`${resultData?.timeSpentInMins} mins`}
                secondaryText="Class Duration"
                borderRight
              />

              <ImageTextBox
                imageName="questions2"
                primaryText={resultData?.noOfQuestions.toString() || '0'}
                secondaryText="Questions"
              />
            </Box>
          )}

          {(largeScreen || value === 'Overall Details') && (
            <Box sx={styles.averageScoreBox}>
              <Typography variant="bodyText" fontWeight="medium">
                Total Average Score
              </Typography>
              <ScoreProgressBar
                variant="md"
                score={calculatePercentage()}
              ></ScoreProgressBar>
            </Box>
          )}
          {/* {(largeScreen || value === 'Overall Details') && (
           <Box sx={styles.averageScoreBox}>
              <Typography variant="bodyText" fontWeight="medium">
                Subtopics
              </Typography>
              <ScoreProgressBar variant="md" score={40}></ScoreProgressBar>
            </Box>
          )} */}

          {(value === 'Overall Details' || largeScreen) && (
            <StrengthsWeaknessesSection resultData={resultData} />
          )}
        </Box>

        {(largeScreen || value === 'Total Marks') && (
          <Box sx={styles.rightPanel}>
            <Box sx={styles.rightHeadingBox}>
              {largeScreen && (
                <Typography variant="h2" fontWeight="medium">
                  Total Marks
                </Typography>
              )}
              <Box sx={styles.classAndSubject}>
                <ImageWrapper
                  name="science"
                  parentFolder="icons"
                  type="png"
                  styles={{
                    height: { xs: pxToRem(35), md: pxTovW(52) },
                    width: { xs: pxToRem(35), md: pxTovW(52) },
                  }}
                />
                <Typography variant="bodyText" fontWeight="bold">
                  {
                    getClassInfo(class_subject_info, selected_lessons_info)
                      .class
                  }
                  {
                    getClassInfo(class_subject_info, selected_lessons_info)
                      ?.section
                  }{' '}
                  {
                    getClassInfo(class_subject_info, selected_lessons_info)
                      ?.subject
                  }
                </Typography>
              </Box>
            </Box>

            <ResultsList resultData={resultData} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const strength = [
  'All the students completed the quiz on time',
  'All the students answered questions from each subtopic correctly.',
  'Class average score is less than 70%',
  'Every student asnwered one question incorrectly',
];

const weakness = [
  'Class average score is less than 70%',
  'Every student asnwered one question incorrectly',
];

interface IImageTextBox {
  imageName: string;
  primaryText: string;
  secondaryText: string;
  borderRight?: boolean;
}
const ImageTextBox = (props: IImageTextBox) => {
  const { imageName, primaryText, secondaryText, borderRight } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: pxToRem(5), md: pxTovW(10) },
        borderRight: borderRight ? '1px dashed lightGrey' : 'none',
        paddingRight: {
          xs: borderRight ? pxToRem(27) : '0',
          md: borderRight ? pxTovW(15) : '0',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: { xs: pxToRem(5), md: pxTovW(10) },
          alignItems: 'center',
        }}
      >
        <ImageWrapper
          name={imageName}
          parentFolder="icons"
          type="png"
          styles={{
            height: { xs: pxToRem(26), md: pxTovW(40) },
            width: { xs: pxToRem(26), md: pxTovW(40) },
          }}
        />
        <Typography variant="h3" fontWeight="bold">
          {primaryText}
        </Typography>
      </Box>
      <Typography variant="h4">{secondaryText}</Typography>
    </Box>
  );
};
