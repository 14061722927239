import {
  BaseStationDetails,
  StudentClickerData,
  UpsertConnectedClassSessionResponse,
} from '@protos/learning_management/lms.connected.apis_pb';
import { createSlice } from '@reduxjs/toolkit';

export interface IStudentClickerDataObj extends StudentClickerData {
  // connectedClassSessionStudentId?: bigint;
  rank?: number | string;
}
export interface IConnectedClassState {
  base_station_details?: BaseStationDetails;
  students_data?: IStudentClickerDataObj[];
  question_session_details?: IQuestionSession;
  connectedClass_session_data?: UpsertConnectedClassSessionResponse;
}
export interface IQuestionSession {
  connected_class_question_session_id?: bigint;
  question_id: string;
}

const initialState: IConnectedClassState = {
  base_station_details: undefined,
  students_data: undefined,
  question_session_details: undefined,
  connectedClass_session_data: undefined,
};

export const connectedClassSlice = createSlice({
  name: 'connectedClass',
  initialState,
  reducers: {
    setBaseStationDetails: (
      state,
      action: { payload: BaseStationDetails | undefined }
    ) => {
      // console.log('payload', action.payload);
      state.base_station_details = action.payload;
    },
    setStudentsData: (
      state,
      action: { payload: StudentClickerData[] | undefined }
    ) => {
      state.students_data = action.payload;
    },
    updateStudentSessionId: (
      state,
      action: { payload: { studentId: bigint } }
    ) => {
      const { studentId } = action.payload;
      const updatedStudentsData = state.students_data?.map((student) => {
        if (student.studentId === studentId) {
          // Update the student's session ID
          return {
            ...student,
          };
        }
        return student;
      });

      // Update the state with the modified students_data array
      state.students_data = updatedStudentsData;
    },
    setConnectedClassQuestionSessionDetails: (
      state,
      action: { payload: IQuestionSession | undefined }
    ) => {
      state.question_session_details = action.payload;
    },
    setConnectedClassSessionData: (
      state,
      action: { payload: UpsertConnectedClassSessionResponse | undefined }
    ) => {
      state.connectedClass_session_data = action.payload;
    },
    resetConnectedClassState: (state) => {
      return initialState;
    },
  },
});

export const {
  resetConnectedClassState,
  setBaseStationDetails,
  setStudentsData,
  setConnectedClassQuestionSessionDetails,
  updateStudentSessionId,
  setConnectedClassSessionData,
} = connectedClassSlice.actions;
export default connectedClassSlice.reducer;
