import { IStyles, pxToRem, pxTovW } from '@geneo2-web/shared-ui';
import { Divider, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
interface IProps {
  modalState: boolean;
  setModalState: (arg: boolean) => void;

  //   displayData: { subject: string; icon: string; class?: string }[];
}
const styles: IStyles = {
  modal: {
    boxSizing: 'border-box',
    display: { xs: 'flex' },
    alignItems: 'flex-end',
    top: { md: '-30%', lg: '0' },
  },
  root: {
    backgroundColor: 'white',
    padding: pxTovW(23),
    // backgroundColor: 'yellow',
    // overflow: 'hidden',
    // overflowY: 'scroll',
    margin: { md: 'auto' },
    width: { xs: '100vw', md: pxToRem(500), lg: pxTovW(742) },
    height: { xs: pxToRem(364), md: pxToRem(360), lg: pxTovW(528) },
    // maxHeight: { md: pxTovW(528) },
    borderRadius: { xs: `${pxToRem(30)} ${pxToRem(30)} 0 0`, md: pxToRem(15) },
    boxSizing: 'border-box',
    outline: 'none',
    boxShadow: `${pxTovW(0)} ${pxTovW(4)} ${pxTovW(18)} #3608918F ,${pxTovW(
      0
    )} ${pxTovW(-3)} ${pxTovW(10)}#292CE696 inset`,
    // mt: { md: '30vh' },

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
};
export const NcfMappingPopup = (props: IProps) => {
  const { modalState, setModalState } = props;

  return (
    <Modal
      open={modalState}
      onClose={() => {
        setModalState(false);
      }}
      sx={styles.modal}
    >
      <Box sx={styles.root}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            border: '1px solid #007CDC',
          }}
        >
          {displayData.map((elem, index) => {
            return (
              <Box
                key={index}
                sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
              >
                <Box
                  sx={{
                    backgroundColor: '#3F4D8F',
                    padding: pxTovW(23),
                  }}
                >
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    sx={{ color: 'white' }}
                  >
                    {elem.heading}
                  </Typography>
                </Box>
                <Box>
                  {elem.list.map((e, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          boxSizing: 'border-box',
                          padding: pxTovW(23),
                          width: '100%',
                        }}
                      >
                        <Typography variant="h5" fontWeight="bold">
                          {e}
                        </Typography>
                        {index !== displayData.length - 1 && (
                          <Divider
                            sx={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: '#E0DFDE',
                            }}
                          />
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Modal>
  );
};
const displayData = [
  {
    heading: 'Heading',
    list: ['list1', 'list2', 'list3'],
  },
];
