import { Timestamp } from '@bufbuild/protobuf';
import {
  DatePicker,
  IStyles,
  IconWrapper,
  LinkButton,
  PrimaryButton,
  ReminderPopup,
  SecondaryButton,
  TimePicker,
  formatSecondsToDateTimeString,
  getDifficultyLevelString,
  pxToRem,
  pxTovW,
  remainingTimeInHoursAndMins,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { TaskEnum } from '@protos/learning_management/lms.db_pb';
import { HomeworkTask } from '@protos/learning_management/lms.hw.common.apis_pb';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../reduxStore/reduxHooks';

import {
  combineStartTimeAndDate,
  convertIsoStringToTimestamp,
} from '../../../../utils/functions';
import { interactionEvent } from '../../../Auth/auth.events';
import { setToastInfo } from '../../../Home/reducer/homeDashboard.slice';
import { setCurrentAssessmentClassStats } from '../../reducer/manageHomework.slice';
import { SendReminder } from './SendReminder';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';
import { ASSESSMENT_VIEW } from '../../../../routeHandling/RoutesNomenclature';
import { AssessmentTask } from '@protos/learning_management/lms.assessment.common.apis_pb';
const styles: IStyles = {
  iconBox: {
    width: { xs: '90vw', md: pxTovW(700), lg: pxTovW(552) },
    height: { xs: pxToRem(70), md: pxTovW(121) },
    borderRadius: { xs: pxToRem(10) },
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0DFDE',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: pxToRem(10),
    boxSizing: 'border-box',
    boxShadow: `0px 0px ${pxToRem(10)} #0000001F`,
    marginLeft: { xs: pxToRem(20), md: pxTovW(0) },
  },
  infoBox: {
    width: { xs: '100vw', md: pxTovW(700), lg: pxTovW(552) },
    height: { md: 'max-content' },
    borderRadius: { md: pxToRem(10) },
    backgroundColor: '#FFFFFF',
    border: { md: '1px solid #E0DFDE' },
    // borderTop: '1px solid #E0DFDE',
    // borderBottom: '1px solid #E0DFDE',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    gap: { xs: pxToRem(24), md: pxTovW(35) },
    boxSizing: 'border-box',
    boxShadow: `0px 0px ${pxToRem(10)} #0000001F`,
    paddingLeft: { xs: pxToRem(35), md: pxTovW(20), lg: pxTovW(62) },
    paddingTop: { xs: pxToRem(21), md: pxTovW(20), lg: pxTovW(32) },
    paddingBottom: { xs: pxToRem(21), md: pxTovW(20), lg: pxTovW(32) },
    paddingRight: { xs: pxToRem(40), md: pxTovW(20), lg: pxTovW(62) },
  },
  deadlineBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
interface IProps {
  currentHomework: AssessmentTask | undefined;
  assigendInfo?: number;
  submissonCount?: number;
}
export const HwDetails = (props: IProps) => {
  const { homework_id } = useParams();
  const navigate = useNavigate();
  const { currentHomework, assigendInfo, submissonCount } = props;
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const pathSegments = location.pathname.split('/');
  const { isIpadOnly } = useGlobalContext();
  const { isOffline } = useDownloadContext();
  const { current_assessmemt_classStats } = useAppSelector(
    (state) => state.manageHomework
  );
  const dispatch = useAppDispatch();
  const [sendReminderPopup, setSendReminderPopup] = useState(false);
  const [changeReminderPopup, setChangeReminderPopup] = useState(false);
  const [extendDeadlinePopup, setExtendDeadlinePopup] = useState(false);
  const {
    LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler,
    LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const nonSumbissionCount = currentHomework
    ? assigendInfo !== undefined
      ? submissonCount !== undefined
        ? assigendInfo - submissonCount
        : assigendInfo
      : 0
    : 0;

  const dailyReminderTime = currentHomework?.dailyReminderTime
    ? new Timestamp(currentHomework?.dailyReminderTime).toDate()
    : undefined;
  let hours = dailyReminderTime?.getHours() || 0;
  const timeFormat = hours >= 12 ? 'PM' : 'AM';
  hours = hours > 12 ? hours - 12 : hours;
  const mins = dailyReminderTime?.getMinutes() || 0;

  const [reminderTime, setReminderTime] = useState({
    timeValue: `${dailyReminderTime?.getHours() || 0} : ${
      dailyReminderTime?.getMinutes() || 0
    } `,
    dateValue: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    ).toISOString(), // Includes timezone offset
  });
  const deadLine = currentHomework?.assessmentTargetDate
    ? new Timestamp(currentHomework?.assessmentTargetDate).toDate()
    : undefined;
  const [endTimeValues, setEndTimeValues] = useState({
    timeValue: `${deadLine?.getHours()} : ${deadLine?.getMinutes()}`,
    dateValue:
      deadLine
        ?.toLocaleString('en-UK', { timeZone: 'Asia/Kolkata' })
        .slice(0, 10)
        .split('/')
        .reverse()
        .join('-') ||
      new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString(), // Includes timezone offset
  });
  // console.log(
  //   // formatSecondsToDateTimeString(currentHomework?.homeworkTargetDate?.seconds),

  //   {
  //     deadLine: deadLine,
  //     Deadline:
  //       deadLine &&
  //       deadLine
  //         ?.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
  //         .slice(0, 10),
  //     EndTimeValues: endTimeValues,
  //   }
  // );
  const handleChangeReminder = async () => {
    try {
      const IsoStringDailyReminder = combineStartTimeAndDate(reminderTime);
      const response =
        await LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler.assessmentAssignmentSubmit(
          {
            assessmentId: currentHomework?.assessmentId,
            teacherId: currentHomework?.teacherId,
            startTime: currentHomework?.assessmentCommenceDate,
            endTime: currentHomework?.assessmentTargetDate,
            assessmentInstructions: currentHomework?.assessmentInstructions,
            sectionIds: currentHomework?.sectionId
              ? [currentHomework?.sectionId]
              : undefined,
            dailyReminderTime: convertIsoStringToTimestamp(
              IsoStringDailyReminder
            ),
            assignmentInfo: currentHomework?.assignmentInfo,
          }
        );
      // console.log(response);
      setChangeReminderPopup(false);
      if (response.status === 200 && response.data?.updatedAssessment) {
        dispatch(
          setCurrentAssessmentClassStats({
            ...current_assessmemt_classStats,
            homework: response.data.updatedAssessment,
          })
        );
        dispatch(
          setToastInfo({
            label: 'Reminder Time is Changed',
            variant: 'info',
            open: true,
          })
        );

        await interactionEvent({
          url: '',
          context: 'change_reminder',
          name: 'CONFIRM',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
        window.location.reload();
      }
    } catch (error) {
      setChangeReminderPopup(false);
      dispatch(
        setToastInfo({
          label: 'Reminder Time change failed',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    }
  };
  const handleExtendDeadline = async () => {
    try {
      const IsoStringDeadline =
        endTimeValues && combineStartTimeAndDate(endTimeValues);
      const IsoStringCurrentDeadline = new Timestamp(
        currentHomework?.assessmentTargetDate
      )
        .toDate()
        .toISOString();
      const currentDeadlineTime = new Date(IsoStringCurrentDeadline);
      const NewendTime = new Date(IsoStringDeadline);
      const currentTime = new Date(Date.now());
      console.log({
        EndTime: IsoStringDeadline,
        STartTime: IsoStringCurrentDeadline,
      });
      if (NewendTime <= currentDeadlineTime) {
        dispatch(
          setToastInfo({
            label: 'Only Extension of deadline is allowed',
            variant: 'error',
            open: true,
          })
        );
      } else {
        const response =
          await LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler.assessmentAssignmentSubmit(
            {
              assessmentId: currentHomework?.assessmentId,
              teacherId: currentHomework?.teacherId,
              startTime: currentHomework?.assessmentCommenceDate,
              endTime: convertIsoStringToTimestamp(IsoStringDeadline),
              assessmentInstructions: currentHomework?.assessmentInstructions,
              sectionIds: currentHomework?.sectionId
                ? [currentHomework?.sectionId]
                : undefined,
              dailyReminderTime: currentHomework?.dailyReminderTime,
              assignmentInfo: currentHomework?.assignmentInfo,
            }
          );
        // console.log(response);
        setExtendDeadlinePopup(false);
        if (response.status === 200 && response.data?.updatedAssessment) {
          dispatch(
            setCurrentAssessmentClassStats({
              ...current_assessmemt_classStats,
              homework: response.data.updatedAssessment,
            })
          );
          dispatch(
            setToastInfo({
              label: 'Deadline extended',
              variant: 'info',
              open: true,
            })
          );

          await interactionEvent({
            url: '',
            context: 'extend_deadline',
            name: 'CONFIRM',
            pathSegments: pathSegments,
            isOffline: isOffline,
          });
        }
        window.location.reload();
      }
    } catch (error) {
      setExtendDeadlinePopup(false);
      dispatch(
        setToastInfo({
          label: 'Deadline Extention failed',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        // backgroundColor: 'red',
        gap: { xs: pxToRem(13), md: pxTovW(27) },
        flexDirection: 'column',
      }}
    >
      {current_assessmemt_classStats?.assessment?.taskType !=
      TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM ? (
        <Box sx={styles.iconBox}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xs: pxToRem(4), md: pxTovW(2), lg: pxTovW(8) },
                flexDirection: 'column',
              }}
            >
              <Box>
                <IconWrapper
                  name="clock"
                  size={isIpadOnly ? 'large' : 'md'}
                  parentFolder="icons"
                  type="png"
                />
              </Box>
              <Typography variant="cardText" fontWeight="bold">
                {currentHomework?.assessmentContentInfo?.timeDurationInMin} Mins
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xs: pxToRem(4), md: pxTovW(2), lg: pxTovW(8) },
                flexDirection: 'column',
              }}
            >
              <Box>
                <IconWrapper
                  name="questions"
                  size={isIpadOnly ? 'large' : 'md'}
                  parentFolder="icons"
                  type="png"
                />
              </Box>
              <Typography variant="cardText" fontWeight="bold">
                {currentHomework?.assessmentContentInfo?.numberOfQuestions} Qs
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xs: pxToRem(4), md: pxTovW(2), lg: pxTovW(8) },
                flexDirection: 'column',
              }}
            >
              <Box>
                <IconWrapper
                  name="level"
                  size={isIpadOnly ? 'large' : 'md'}
                  parentFolder="icons"
                  type="png"
                />
              </Box>
              <Typography variant="cardText" fontWeight="bold">
                {getDifficultyLevelString(currentHomework?.difficultyLevel)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: { md: 'none' },
              }}
            >
              <SecondaryButton
                onClick={async () => {
                  navigate(`${ASSESSMENT_VIEW}/assigned`);
                  await interactionEvent({
                    url: '',
                    context: 'homework',
                    name: 'VIEW_HOMEWORK',
                    pathSegments: pathSegments,
                    isOffline: isOffline,
                  });
                }}
              >
                <Typography variant="smallText" color={'white'}>
                  View Homework
                </Typography>
              </SecondaryButton>
            </Box>
          </Box>
        </Box>
      ) : null}

      <Box sx={styles.infoBox}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xs: pxToRem(4), md: pxTovW(8) },
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xs: pxToRem(5.5), md: pxTovW(7.5) },
              }}
            >
              <IconWrapper
                name="user"
                size={isIpadOnly ? 'large' : 'md'}
                parentFolder="icons"
                type="png"
              />
              <Typography variant="h2" fontWeight="bold">
                {submissonCount}/{assigendInfo}
              </Typography>
            </Box>
            <Typography
              variant="h4"
              fontWeight="regular"
              sx={{ color: '#828282' }}
            >
              Submissions
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
              textAlign: isIpadOnly ? 'right' : 'unset',
              gap: { xs: pxToRem(4), md: pxTovW(8) },
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h3"
              fontWeight="regular"
              sx={{ color: '#828282' }}
            >
              {`${nonSumbissionCount} Students not Submitted`}
            </Typography>
            {/* <LinkButton onClick={() => setSendReminderPopup(true)}>
              Send Reminder
            </LinkButton> */}
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: { xs: pxToRem(25), md: pxTovW(55) },
            borderBottom: '1px dashed #828282',
            // backgroundColor: 'red',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xs: pxToRem(4), md: pxTovW(8) },

              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                // backgroundColor: 'red',
                alignItems: 'center',
                gap: { xs: pxToRem(5.5), md: pxTovW(7.5) },
              }}
            >
              <IconWrapper
                name="reminder"
                size={isIpadOnly ? 'large' : 'md'}
                parentFolder="icons"
                type="png"
              />
              <Typography variant="h2" fontWeight="bold">
                {hours}:{mins < 10 ? '0' + mins : mins}
              </Typography>
              <Typography
                variant="h4"
                fontWeight="regular"
                sx={{ color: '#828282' }}
              >
                {timeFormat}
              </Typography>
            </Box>
            <Typography
              variant="h4"
              fontWeight="regular"
              sx={{ color: '#828282' }}
            >
              Daily Reminder
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',

              textAlign: isIpadOnly ? 'right' : 'unset',
              // backgroundColor: 'blue',
              gap: { xs: pxToRem(4), md: pxTovW(8) },
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h3"
              fontWeight="regular"
              sx={{ color: '#828282' }}
            >
              To set a new Reminder
            </Typography>
            <LinkButton
              onClick={async () => {
                setChangeReminderPopup(true);
                await interactionEvent({
                  url: '',
                  context: 'homework',
                  name: 'CHANGE_REMINDER',
                  pathSegments: pathSegments,
                  isOffline: isOffline,
                });
              }}
            >
              Change Reminder
            </LinkButton>
          </Box>
        </Box>
        <Box sx={styles.deadlineBox}>
          <Box
            sx={{
              width: '50%',
              borderRight: '1px solid #c7c7c7',
              padding: { xs: pxToRem(4), md: 0, lg: pxTovW(8) },
              // backgroundColor: 'red',
              height: isIpadOnly ? pxTovW(100) : 'unset',
            }}
          >
            <Typography
              variant="h4"
              fontWeight="regular"
              sx={{ color: '#333333' }}
            >
              {currentHomework?.assessmentCommenceDate &&
              remainingTimeInHoursAndMins(
                currentHomework.assessmentCommenceDate
              ) === '0 mins'
                ? ' Remaining Time'
                : 'Homework Starts'}
            </Typography>
            <Typography
              variant="h4"
              fontWeight="regular"
              sx={{ color: '#333333' }}
            >
              <Typography
                variant={isIpadOnly ? 'h4' : 'bodyText'}
                fontWeight="bold"
                sx={{ color: '#333333' }}
              >
                {currentHomework?.assessmentCommenceDate &&
                remainingTimeInHoursAndMins(
                  currentHomework.assessmentCommenceDate
                ) === '0 mins'
                  ? remainingTimeInHoursAndMins(
                      currentHomework?.assessmentTargetDate
                    )
                  : `${formatSecondsToDateTimeString(
                      currentHomework?.assessmentCommenceDate?.seconds
                    )}`}
              </Typography>
            </Typography>
          </Box>
          <Box
            sx={{
              width: '50%',
              // backgroundColor: 'red',
              borderLeft: '1px solid #c7c7c7',
              padding: { xs: pxToRem(4), md: 0, lg: pxTovW(8) },
              paddingLeft: { xs: pxToRem(25), md: pxTovW(40), lg: pxTovW(32) },
              height: isIpadOnly ? pxTovW(100) : 'unset',
              // textAlign: 'center',
            }}
          >
            <Typography
              variant="h4"
              fontWeight="regular"
              sx={{ color: '#333333' }}
            >
              Deadline
            </Typography>
            <Typography
              variant="h4"
              fontWeight="regular"
              sx={{ color: '#333333' }}
            >
              <Typography
                variant={isIpadOnly ? 'h4' : 'bodyText'}
                fontWeight="bold"
                sx={{ color: '#333333' }}
              >
                {formatSecondsToDateTimeString(
                  currentHomework?.assessmentTargetDate?.seconds
                )}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <LinkButton
          onClick={async () => {
            new Timestamp(currentHomework?.assessmentTargetDate).toDate() <
            new Date(Date.now())
              ? dispatch(
                  setToastInfo({
                    label: 'Deadline already passed',
                    variant: 'error',
                    open: true,
                  })
                )
              : setExtendDeadlinePopup(true);
            await interactionEvent({
              url: '',
              context: 'homework',
              name: 'EXTEND_DEADLINE',
              pathSegments: pathSegments,
              isOffline: isOffline,
            });
          }}
        >
          Extend Deadline
        </LinkButton>
      </Box>
      <ReminderPopup
        iconName="reminder2"
        open={sendReminderPopup}
        handleClose={() => setSendReminderPopup(false)}
        popupComponent={
          <SendReminder
            nonSubmissionCount={nonSumbissionCount}
            studentinfo={current_assessmemt_classStats?.studentPerformanceInfo}
          />
        }
        heading={
          <Typography variant={isMobile ? 'h3' : 'h1'} fontWeight="medium">
            Send Reminder
          </Typography>
        }
      />
      <ReminderPopup
        handleClose={async () => {
          setEndTimeValues({
            timeValue: `${deadLine?.getHours()} : ${deadLine?.getMinutes()}`,
            dateValue:
              deadLine
                ?.toLocaleString('en-UK', { timeZone: 'Asia/Kolkata' })
                .slice(0, 10)
                .split('/')
                .reverse()
                .join('-') ||
              new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
              ).toISOString(),
          });
          setExtendDeadlinePopup(false);

          await interactionEvent({
            url: '',
            context: 'extended_deadline_popup',
            name: 'CLOSE',
            pathSegments: pathSegments,
            isOffline: isOffline,
          });
        }}
        open={extendDeadlinePopup}
        iconName="schedule"
        popupComponent={
          <Box
            sx={{
              height: '80%',
              width: '100%',
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              // backgroundColor: 'blue',
              // marginTop: pxTovW(29),
              // gap: pxTovW(60),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-around',
              }}
            >
              <Box
                sx={{
                  width: '40%',
                }}
              >
                <DatePicker
                  minDate={new Timestamp(currentHomework?.assessmentTargetDate)
                    .toDate()
                    .toLocaleString('en-UK', { timeZone: 'Asia/Kolkata' })
                    .slice(0, 10)
                    .split('/')
                    .reverse()
                    .join('-')}
                  value={`${endTimeValues.dateValue}`}
                  handleDateChange={(date) =>
                    setEndTimeValues({
                      ...endTimeValues,
                      dateValue: date,
                    })
                  }
                />
              </Box>{' '}
              <Box
                sx={{
                  width: '40%',
                }}
              >
                <TimePicker
                  minTime={
                    `${endTimeValues.dateValue.slice(0, 10)}` ===
                    new Timestamp(currentHomework?.assessmentCommenceDate)
                      .toDate()
                      ?.toLocaleString('en-UK', { timeZone: 'Asia/Kolkata' })
                      .slice(0, 10)
                      .split('/')
                      .reverse()
                      .join('-')
                      ? `${new Timestamp(currentHomework?.assessmentTargetDate)
                          .toDate()
                          .getHours()}:${new Timestamp(
                          currentHomework?.assessmentTargetDate
                        )
                          .toDate()
                          .getMinutes()}`
                      : undefined
                  }
                  fullWidth
                  date={`${endTimeValues.dateValue}`}
                  value={endTimeValues.timeValue}
                  handleTimeChange={(time) => {
                    setEndTimeValues({ ...endTimeValues, timeValue: time });
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                // width: '100%',
                display: 'flex',
                justifyContent: 'center',
                // backgroundColor: 'red',
                padding: {
                  xs: `${pxToRem(0)} ${pxToRem(14)} ${pxToRem(14)} ${pxToRem(
                    22
                  )}`,

                  md: `${pxToRem(0)} ${pxToRem(26)} ${pxToRem(36)} ${pxToRem(
                    26
                  )}`,
                },
              }}
            >
              <PrimaryButton fullWidth onClick={handleExtendDeadline}>
                <Typography
                  variant={isIpadOnly ? 'h2' : 'h4'}
                  fontFamily="poppins"
                  color="white"
                  fontWeight={600}
                >
                  CONFIRM
                </Typography>
              </PrimaryButton>
            </Box>
          </Box>
        }
        heading={
          <Typography variant={isMobile ? 'h3' : 'h1'} fontWeight="medium">
            Extend Deadline
          </Typography>
        }
      />
      <ReminderPopup
        handleClose={async () => {
          setReminderTime({
            ...reminderTime,
            timeValue: `${dailyReminderTime?.getHours() || 0} : ${
              dailyReminderTime?.getMinutes() || 0
            } `,
          });
          setChangeReminderPopup(false);

          await interactionEvent({
            url: '',
            context: 'change_reminder',
            name: 'CLOSE',
            pathSegments: pathSegments,
            isOffline: isOffline,
          });
        }}
        open={changeReminderPopup}
        iconName="schedule"
        popupComponent={
          <Box
            sx={{
              height: '80%',
              width: '90%',
              display: 'flex',
              justifyContent: 'space-between',

              flexDirection: 'column',
              margin: 'auto',
              // backgroundColor: 'red',
              // marginTop: pxTovW(29),
            }}
          >
            <Box>
              <TimePicker
                fullWidth
                name="dailyReminder"
                value={reminderTime.timeValue}
                handleTimeChange={(time) => {
                  setReminderTime({ ...reminderTime, timeValue: time });
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: {
                  xs: `${pxToRem(0)} ${pxToRem(14)} ${pxToRem(14)} ${pxToRem(
                    22
                  )}`,

                  md: `${pxToRem(0)} ${pxToRem(26)} ${pxToRem(36)} ${pxToRem(
                    26
                  )}`,
                },
              }}
            >
              <PrimaryButton fullWidth onClick={handleChangeReminder}>
                <Typography
                  variant={isIpadOnly ? 'h2' : 'h4'}
                  color="white"
                  fontWeight={600}
                  fontFamily="Poppins"
                >
                  CONFIRM
                </Typography>
              </PrimaryButton>
            </Box>
          </Box>
        }
        heading={
          <Typography variant={isMobile ? 'h3' : 'h1'} fontWeight="medium">
            Change Reminder
          </Typography>
        }
      />
    </Box>
  );
};

function convertTo12HourFormat(time?: string): string {
  if (!time) {
    return '0:00 AM';
  }
  const [hours, minutes] = time.split(':').map(Number);
  const date = new Date(2000, 0, 1, hours, minutes);
  const formattedTime = date.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
  return formattedTime;
}
