import {
  FilterSortPopup,
  IStyles,
  StudentScoreCard,
  deserify,
  firstLetterImage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import { SubmissionType } from '@protos/learning_management/lms.db_pb';
import { StudentPerformanceInfo } from '@protos/learning_management/lms.hw.teacher.apis_pb';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IStatusObj, ISubmissionProps } from '..';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { interactionEvent } from '../../../Auth/auth.events';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';

const styles: IStyles = {
  root: {
    width: { xs: '100%', md: '100%' },
    // display: 'flex',
    // flexDirection: 'column',
    paddingLeft: { xs: pxToRem(0), md: pxTovW(40) },
    paddingTop: { xs: pxToRem(20), md: pxTovW(0) },
    // paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
    boxSizing: 'border-box',
    // overflowX: 'hidden',
  },
  header: {
    width: { xs: '94%', md: '100%', lg: pxTovW(794) },
    display: 'flex',
    marginLeft: { xs: '3%', md: 'auto' },
    // flexDirection: 'column',
    // paddingLeft: { xs: pxToRem(40), md: pxTovW(40) },
    // paddingTop: { xs: pxToRem(20), md: pxTovW(40) },
    paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
    gap: { xs: pxToRem(10), md: pxTovW(22) },
    justifyContent: 'space-between',
    //
    // alignItems: 'center',
  },
};
interface IProps {
  Class: string;
  studentPerformanceInfoList?: StudentPerformanceInfo[];
  cardMaxMarks?: number;
  cardSubmissionType?: SubmissionType;
  cardOnClickHandler?: (studentId: string) => void;
  checkBoxClickHandler?: (props: ISubmissionProps) => Promise<void>;
  saveStudentInfo?: (props: StudentPerformanceInfo) => void;
  statusInfo?: IStatusObj;
  textClickHandler?: (studentInfo: StudentPerformanceInfo) => void;
}
export const StudentSection = (props: IProps) => {
  const {
    Class,
    studentPerformanceInfoList,
    cardSubmissionType,
    cardMaxMarks,
    cardOnClickHandler,
    checkBoxClickHandler,
    // saveStudentInfo,
    statusInfo,
    textClickHandler,
  } = props;
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const { isOffline } = useDownloadContext();

  const [studentList, setStudentList] = useState<
    StudentPerformanceInfo[] | undefined
  >();
  const navigate = useNavigate();
  const { assessment_list_data } = deserify(
    useAppSelector((state) => state.manageHomework)
  );

  function splitName(fullName: string) {
    const nameParts = fullName.split(' ');
    const firstname = nameParts[0];
    const lastname = nameParts.slice(1).join(' '); // In case there are middle names or multiple last names

    return {
      firstname: firstname,
      lastname: lastname,
    };
  }
  useEffect(
    () => setStudentList(studentPerformanceInfoList),
    [studentPerformanceInfoList]
  );

  const SortFunction = async (value: string) => {
    const temp = studentPerformanceInfoList?.slice();
    switch (value) {
      case 'Score: High to Low':
        temp?.sort((a, b) => b.scorePercent - a.scorePercent);
        setStudentList(temp);
        // console.log(temp);
        // console.log(studentList);
        await interactionEvent({
          url: '',
          context: 'sort_by',
          name: 'SCORE_HIGH_TO_LOW',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
        break;
      case 'Score: Low to High':
        temp?.sort((a, b) => a.scorePercent - b.scorePercent);
        setStudentList(temp);
        // console.log(temp);
        // console.log(studentList);
        await interactionEvent({
          url: '',
          context: 'sort_by',
          name: 'SCORE_LOW_TO_HIGH',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
        break;
      case 'First Name':
        temp?.sort((a, b) => {
          const FirstNameA = splitName(a.name).firstname;
          const FirstNameB = splitName(b.name).firstname;
          const nameA = FirstNameA.toLowerCase();
          const nameB = FirstNameB.toLowerCase();

          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        });
        setStudentList(temp);
        await interactionEvent({
          url: '',
          context: 'sort_by',
          name: 'FIRST_NAME',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
        break;
      case 'Last Name':
        temp?.sort((a, b) => {
          const LastNameA = splitName(a.name).lastname;
          const LastNameB = splitName(b.name).lastname;
          const nameA = LastNameA.toLowerCase();
          const nameB = LastNameB.toLowerCase();

          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        });
        setStudentList(temp);
        await interactionEvent({
          url: '',
          context: 'sort_by',
          name: 'LAST_NAME',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
        break;

      default:
        break;
    }
  };

  const FilterFunction = (value: string) => {
    const temp = studentPerformanceInfoList;
    switch (value) {
      case 'High Score':
        setStudentList(temp?.filter((elem) => elem.scorePercent > 80));
        // console.log(temp?.filter((elem) => elem.scorePercent > 80));
        break;
      case 'Low Score':
        setStudentList(temp?.filter((elem) => elem.scorePercent < 30));
        break;
      case 'Medium Score':
        setStudentList(
          temp?.filter(
            (elem) => elem.scorePercent > 30 && elem.scorePercent < 80
          )
        );
        break;

      default:
        break;
    }
  };
  console.log('check3', studentList);
  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h2" fontWeight="medium">
            Students
          </Typography>
          <Typography
            variant="cardText"
            fontWeight="bold"
            sx={{ color: '#007CDC' }}
          >
            Class {Class}
          </Typography>
        </Box>

        <FilterSortPopup
          iconName="Sort"
          title="Sort By"
          options={[
            'First Name',
            'Last Name',
            'Score: Low to High',
            'Score: High to Low',
          ]}
          sortFunction={SortFunction}
        />
        {/* <FilterSortPopup
            iconName="Filter"
            title="Filter"
            options={['High Score', 'Low Score', 'Medium Score']}
            filterFunction={FilterFunction}
          /> */}
      </Box>
      <Box
        sx={{
          width: {
            xs: '100vw',
            md: pxTovW(1100),
            lg: pxTovW(754),
          },
          marginLeft: 'auto',
          paddingLeft: { xs: pxToRem(20), md: pxTovW(19) },
          paddingTop: { xs: pxToRem(20), md: pxTovW(40) },
          paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
          paddingRight: { xs: pxToRem(0), md: pxTovW(19) },
          boxShadow: `0px 0px ${pxToRem(13)} #E0DFDE`,
          backgroundColor: '#FFFFFF',
          borderRadius: '5px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          rowGap: { xs: pxToRem(20), md: pxTovW(30) },
        }}
      >
        {studentList?.map((elem, index) => (
          <Box
            key={index}
            onClick={() => {
              // cardMaxMarks && saveStudentInfo && saveStudentInfo(elem);
              cardOnClickHandler && cardOnClickHandler(String(elem.studentId));
            }}
            sx={{ cursor: 'pointer' }}
          >
            <StudentScoreCard
              studentName={elem.name}
              maxMarks={cardMaxMarks}
              submissionType={cardSubmissionType}
              submission={
                statusInfo && statusInfo[elem.studentId.toString()].isSubmitted
              }
              score={Math.round(elem.scorePercent)}
              marks={statusInfo && statusInfo[elem.studentId.toString()].marks}
              imageUrl={
                getMediaBasePath(elem.profileImgUrl) ||
                firstLetterImage(elem.name)
              }
              checkBoxClickHandler={checkBoxClickHandler}
              studentPerformanceInfo={elem}
              textClickHandler={textClickHandler}
            ></StudentScoreCard>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
