import { Key, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, useMediaQuery } from '@mui/material';

import {
  AssessmentButton,
  ChapterSelectedCard,
  ChipBadge,
  IStyles,
  Loader,
  NoContentCard,
  TopicCard,
  deserify,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  roundingAssessmentNumber,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { AssessmentEnum } from '@protos/learning_management/lms.db_pb';
import { interactionEvent } from '../../../Auth/auth.events';
import {
  ASSESMENT_LIBRARY,
  ASSESSMENT_CREATE,
  HOMEWORK,
  HOMEWORK_CHAPTER_SELECTION,
} from '../../../../routeHandling/RoutesNomenclature';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import {
  resetSelectedChapter,
  resetSelectedTasksInfo,
  resetSelectedTopic,
  setAssessmentData,
  setChapterWiseTopicInfo,
  setSelectedChapter,
} from '../../reducer/assessmentFlow.slice';
import ChapterResourcesDisplay from './components/ChapterResourcesDisplay';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../reduxStore/reduxHooks';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';

const styles: IStyles = {
  root: {
    boxSizing: 'border-box',
    backgroundColor: 'neutral.paleGrey',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: pxToRem(25),
    padding: {
      md: `${pxTovW(14)} ${pxToRem(20)}`,
      lg: `${pxTovW(14)} ${pxTovW(240)}`,
    },
    paddingTop: { md: pxTovW(40) },
  },
  textWithBadge: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(14), md: pxTovW(10) },
    paddingX: { xs: pxToRem(20), md: 0 },
  },
  topicContainer: {
    flexBasis: { xs: '100%', md: '40%', lg: pxTovW(554) },
    // maxWidth: { md: pxTovW(554) },
    // paddingLeft: { md: pxTovW(246) },
    // paddingBottom: { xs: pxToRem(20), md: pxTovW(46) },
  },
  chapterBox: {
    padding: { xs: pxToRem(20), md: 0 },
    paddingBottom: { xs: pxToRem(20), md: pxTovW(46) },
  },
  resourceContainer: {
    flexGrow: { xs: 0, md: 1, lg: 0 },
    flexBasis: { xs: '100%', md: 1, lg: pxTovW(862) },
  },
  chooseTopicBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(7), md: pxTovW(18) },
  },
  topicBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    '& > div': {
      borderBottom: '1px solid lightgrey',
      padding: { xs: pxToRem(20) },
    },
  },
};
export default function HwTopicSelection() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { subject_id, chapter_id } = useParams();
  const location = useLocation();
  const pathname = location.pathname;
  const { isOffline } = useDownloadContext();
  const isTopicsSelection = pathname.includes('/hw-topics-selection');
  console.log(pathname, isTopicsSelection);
  const teacher_id = getLocalStorage('userId');
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const topicNavigation = async (topicId?: string) => {
    await interactionEvent({
      url: 'Teacher_hw_chapter_selection',
      context: 'Topic',
      name: 'VIEW',
      isOffline: isOffline,
    });
    navigate(`${HOMEWORK}${ASSESSMENT_CREATE}/${topicId}`);
  };
  const { setSelectedFunction, isIpadOnly } = useGlobalContext();

  // Getting Data for ChapterSelectedCard
  const { subjectwise_chapters_info } = deserify(
    useAppSelector((state: { assessment: any }) => state.assessment)
  );
  // Getting all the Topics in chapter
  const { chapterwise_topic } = deserify(
    useAppSelector((state: { assessment: any }) => state.assessment)
  );

  const { assesment_flow_data } = deserify(
    useAppSelector((state: { assessment: any }) => state.assessment)
  );

  useEffect(() => {
    const onBackClick = () => {
      navigate(`${HOMEWORK_CHAPTER_SELECTION}/${subject_id}`);
    };
    setSelectedFunction(() => onBackClick);
    dispatch(resetSelectedChapter());
    dispatch(resetSelectedTopic());
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const findSelectedChapterData = () => {
    const selectedChapter = subjectwise_chapters_info?.response.find(
      (chapter: { chapterId: number }) =>
        chapter_id && +chapter_id === chapter.chapterId
    );
    if (selectedChapter) {
      return {
        name: selectedChapter.chapterTitle,
        image: getMediaBasePath(
          selectedChapter.posterImagesUrl,
          'processedMediaBucket'
        ),
      };
    }
    return { name: '', image: '' };
  };

  // Getting all the Chapter Resources
  const { chapter_resources } = deserify(
    useAppSelector((state: { assessment: any }) => state.assessment)
  );

  // Getting current class and subject selected
  const { class_subject_info } = useAppSelector(
    (state: { homeDashboard: any }) => state.homeDashboard
  );

  async function fetchChapterTopicInfo({
    subjectId,
    chapterId,
  }: {
    subjectId: string;
    chapterId: string;
  }) {
    try {
      setLoading('loading');

      const response =
        await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchChapterTopicInfo(
          {
            personId: BigInt(teacher_id),
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            subjectId: Number(subjectId),
            chapterId: Number(chapterId),
            sectionId: class_subject_info?.sectionId,
            isHomework: true,
          }
        );

      if (response?.data) {
        const data = response.data;
        // console.log(typeof data, data);
        data.chapterTopics.sort((a, b) => a.topicNo - b.topicNo);
        dispatch(setChapterWiseTopicInfo(data));
      } else {
        // setError(new Error('Login failed'));
      }

      setLoading('completed');
    } catch (err) {
      setLoading('error');
      // setError(err);
      console.log(err);
    }
  }

  const fetchAssessmentsByModule = async (
    subjectId: number,
    chapterId: number | undefined
  ) => {
    try {
      setLoading('loading');
      const response =
        await LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchAssessmentsByModule(
          {
            personId: teacher_id,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            subjectId: subjectId,
            assessmentType: AssessmentEnum.ASSESSMENT_HOMEWORK,
            chapterId: chapter_id ? Number(chapterId) : undefined,
          }
        );
      if (response?.data) {
        dispatch(setAssessmentData(response.data));
      }
      setLoading('completed');
    } catch (err) {
      setLoading('error');
      console.log(err);
    }
  };

  useEffect(() => {
    // if (chapter_id) fetchChapterTopicInfo();
    if (subject_id && chapter_id) {
      fetchChapterTopicInfo({ subjectId: subject_id, chapterId: chapter_id });
      // fetchAssessmentsByModule(Number(subject_id), Number(chapter_id));
      dispatch(resetSelectedTasksInfo());
      dispatch(resetSelectedTopic());
    }
  }, [chapter_id]);
  // console.log('subject_topic_info', chapterwise_topic);
  return (
    <Box sx={styles.root}>
      <Box sx={styles.topicContainer}>
        <Box sx={styles.chapterBox}>
          <ChapterSelectedCard
            image={findSelectedChapterData().image}
            chapterName={
              findSelectedChapterData().name
                ? findSelectedChapterData().name
                : ''
            }
            className={
              class_subject_info
                ? class_subject_info?.classname + class_subject_info?.section
                : ''
            }
            subject={class_subject_info ? class_subject_info?.subject : ''}
          />
        </Box>
        <Box
          sx={{
            display: { md: 'none' },
            padding: { xs: `${pxToRem(1)} ${pxToRem(20)} ${pxToRem(20)}` },
          }}
        >
          <AssessmentButton
            text="Choose"
            subText={`From Library (${roundingAssessmentNumber(
              Number(100)
            )} ready to use assessments)`}
            backgroundColor="#CBEECD"
            imageName="library-group"
            variant="small"
            clickHandler={() => {
              dispatch(
                setSelectedChapter({
                  chapterId: chapter_id,
                  chapterTitle: findSelectedChapterData().name,
                })
              );
              navigate(`${ASSESMENT_LIBRARY}`); // Pass chapterId via state
            }}
          />
        </Box>
        <Box sx={styles.chooseTopicBox}>
          <Box sx={styles.textWithBadge}>
            <Typography variant="h2">Choose a Topic</Typography>
            <Typography variant="h3">
              <ChipBadge
                label={chapterwise_topic?.chapterTopics.length}
                color="primary"
                size="small"
              />
            </Typography>
          </Box>

          {loading === 'loading' ? (
            <Loader />
          ) : loading === 'error' ? (
            <NoContentCard variant="error" icon="error" text="Error Occured" />
          ) : chapterwise_topic &&
            chapterwise_topic.chapterTopics.length > 0 ? (
            <Box sx={styles.topicBox}>
              {chapterwise_topic?.chapterTopics.map(
                (
                  topics: {
                    topicTitle: string;
                    topicContentStats: {
                      homeworkCount: number | undefined;
                      questionCount: number | undefined;
                    };
                    topicId: { toString: () => string | undefined };
                  },
                  index: Key | null | undefined
                ) => (
                  <TopicCard
                    key={index}
                    title={topics.topicTitle}
                    homeWorkCount={topics.topicContentStats?.homeworkCount}
                    questionsCount={topics.topicContentStats?.questionCount}
                    viewAllClickHandler={() =>
                      topicNavigation(topics.topicId.toString())
                    }
                  />
                )
              )}
            </Box>
          ) : (
            <Box>
              <NoContentCard
                variant="white"
                icon="coming-soon-yellow"
                text="No topics to show"
              />
            </Box>
          )}
        </Box>
      </Box>

      {/* right panel */}
      <Box sx={styles.resourceContainer}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              ...styles.textWithBadge,
              paddingY: { xs: pxToRem(20), md: 0 },
            }}
          >
            <Typography
              variant={isIpadOnly ? 'h3' : 'h2'}
              sx={{
                wordBreak: 'break-word',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              Chapter Resources
            </Typography>
            <Typography variant="h3">
              <ChipBadge
                label={
                  chapter_resources?.categoryResourceMap
                    ?.map(
                      (obj: { categoryResources: string | any[] }) =>
                        obj.categoryResources.length
                    )
                    .reduce((a: any, b: any) => a + b, 0) || 0
                }
                color="primary"
                size="small"
              />
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'unset' },
            }}
          >
            <AssessmentButton
              text="Choose"
              subText={`From Library (${roundingAssessmentNumber(
                Number(100)
              )} ready to use assessments)`}
              backgroundColor="#CBEECD"
              imageName="library-group"
              variant="small"
              clickHandler={() => {
                dispatch(
                  setSelectedChapter({
                    chapterId: chapter_id,
                    chapterTitle: findSelectedChapterData().name,
                  })
                );
                navigate(`${ASSESMENT_LIBRARY}`);
              }}
            />
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <ChapterResourcesDisplay />
        </Box>
      </Box>
    </Box>
  );
}
