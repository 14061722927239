import {
  IStyles,
  IconWrapper,
  ImageWrapper,
  pxToRem,
  pxTovW,
  theme,
} from '@geneo2-web/shared-ui';
import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { GetConnectedClassSessionAnalyticsResponse } from '@protos/learning_management/lms.connected.apis_pb';
const styles: IStyles = {
  zeroInstructionBox: {
    display: 'flex',
    width: '95%',
    flexDirection: 'column',
    background: '#FFFFFF',
    borderRadius: pxToRem(5),
    boxShadow: `0px 0px ${pxTovW(11)} #00000029`,
    padding: {
      xs: `${pxToRem(17.5)} ${pxToRem(7)}`,
      md: `${pxTovW(35)} ${pxTovW(86)}`,
    },
    marginBottom: { xs: pxToRem(30) },
    gap: { xs: pxToRem(13), md: pxTovW(13) },
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  instructionBox: {
    boxSizing: 'border-box',
    // backgroundColor: 'red',
    display: 'flex',
    width: '95%',
    flexDirection: 'column',
    background: '#FFFFFF',
    borderRadius: pxToRem(5),
    boxShadow: `0px 0px ${pxTovW(11)} #00000029`,
    padding: {
      xs: `${pxToRem(17.5)} ${pxToRem(7)}`,
      md: `${pxTovW(20)} ${pxTovW(20)}`,
    },
    marginBottom: { xs: pxToRem(30) },
  },
  strengthHeadingBox: {
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    borderBottom: '1px solid #E7F4E7',
    marginBottom: { xs: pxToRem(14), md: pxTovW(21) },
  },
  strengthBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: { md: pxTovW(20) },
    gap: { xs: pxToRem(14), md: pxTovW(21) },
  },
  weaknessHeadingBox: {
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    borderBottom: '1px solid #E7F4E7',
    marginBottom: { xs: pxToRem(14), md: pxTovW(21) },
    marginTop: { xs: pxToRem(10) },
  },
  weaknessBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(14), md: pxTovW(21) },
  },

  iconBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  textBox: {
    display: 'flex',
    gap: { xs: pxToRem(4), md: pxTovW(5) },
    alignItems: 'flex-start',
    // backgroundColor: 'red',
  },
};
interface IProps {
  resultData?: {
    strengths: string[];
    weakness: string[];
  };
}
export const StrengthsWeaknessesSection = (props: IProps) => {
  const { resultData } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        boxSizing: 'border-box',
        gap: { xs: pxToRem(27), md: pxTovW(30) },
        justifyContent: 'center',
        // padding: { md: pxTovW(10) },
        // backgroundColor: 'red',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          marginTop: { md: pxTovW(50) },
          marginBottom: { md: pxTovW(20) },
          width: '95%',
          padding: { md: pxTovW(10) },

          textAlign: 'left',
          // marginRight: 'auto',
          justifyContent: 'left',
          //   backgroundColor: 'blue',
        }}
      >
        <Typography variant="h2">Strength & Weakness</Typography>
      </Box>

      {resultData?.strengths.length === 0 && resultData?.weakness.length === 0 && (
        <Box sx={styles.zeroInstructionBox}>
          <ImageWrapper
            name="no_data"
            parentFolder="images"
            type="png"
            styles={{
              height: { xs: pxToRem(135), md: pxTovW(203) },
              width: { xs: pxToRem(164), md: pxTovW(247) },
            }}
          />

          <Typography variant="h3" fontWeight="bold">
            No strength & weakness data found
          </Typography>
        </Box>
      )}

      {resultData &&
        (resultData?.strengths.length > 0 ||
          resultData?.weakness.length > 0) && (
          <Box sx={styles.instructionBox}>
            <Box sx={styles.strengthHeadingBox} paddingBottom={2}>
              <Typography variant="h2" fontWeight="bold">
                Strength
              </Typography>
            </Box>
            {resultData.strengths.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  // backgroundColor: 'red',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ImageWrapper
                  name="no-strengths-show"
                  parentFolder="icons"
                  type="png"
                  styles={{
                    height: { xs: pxToRem(135), md: pxTovW(127) },
                    width: { xs: pxToRem(200), md: pxTovW(189) },
                  }}
                />
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ wordBreak: 'break-word' }}
                >
                  No strengths to show
                </Typography>
              </Box>
            ) : (
              <Box sx={styles.strengthBox}>
                {resultData.strengths?.map((elem, i) => (
                  <Box key={i} sx={styles.textBox}>
                    <Box sx={{ ...styles.iconBox, cursor: 'default' }}>
                      <CheckOutlinedIcon fontSize="small" color="primary" />
                    </Box>
                    <Box sx={{ display: 'flex', textAlign: 'left' }}>
                      <Typography variant="h3" sx={{ wordBreak: 'break-word' }}>
                        {elem}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

            <Box sx={styles.weaknessHeadingBox} paddingBottom={2}>
              <Typography variant="h2" fontWeight="bold">
                Weakness
              </Typography>
            </Box>
            {resultData.weakness.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  // justifyContent: 'center',
                  alignItems: 'center',
                  gap: { xs: pxToRem(7.2), md: pxTovW(11) },
                }}
              >
                <IconWrapper
                  name="thumbsup"
                  parentFolder="icons"
                  type="png"
                  customSx={{
                    height: { xs: pxToRem(61), md: pxTovW(78) },
                    width: { xs: pxToRem(61), md: pxTovW(78) },
                  }}
                />
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ wordBreak: 'break-word' }}
                >
                  Nice! No Found Weaknesses
                </Typography>
              </Box>
            ) : (
              <Box sx={styles.weaknessBox}>
                {resultData.weakness?.map((elem, i) => (
                  <Box key={i} sx={styles.textBox}>
                    <Box sx={{ ...styles.iconBox, cursor: 'default' }}>
                      <CloseIcon fontSize="small" sx={{ color: 'red' }} />
                    </Box>
                    <Box sx={{ display: 'flex', textAlign: 'left' }}>
                      <Typography variant="h3" sx={{ wordBreak: 'break-word' }}>
                        {elem}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}
    </Box>
  );
};
