import {
  IconWrapper,
  IStyles,
  pxToRem,
  pxTovW,
  SecondaryButton,
} from '@geneo2-web/shared-ui';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PieChart } from './PieChart';
import ViewReportButton from '../../components/ViewReportButton';

const styles: IStyles = {
  root: {
    width: { xs: '100%', md: pxTovW(700), lg: '100%' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: { xs: '0px', md: pxToRem(10) },
    backgroundColor: '#FFFFFF',
    border: { md: '1px solid #E0DFDE' },
    gap: pxToRem(10),
    // gap: pxToRem(10),
    boxSizing: 'border-box',
    boxShadow: `0px 0px ${pxToRem(10)} #0000001F`,
    padding: { xs: pxToRem(20), md: pxTovW(20) },
  },
};
interface IChartComponentProps {
  obtainedMarks: number;
  maxMarks: number;
  title: string;
  color: string;
}
interface IProps {
  chartData: IChartComponentProps[];
  homeworkId?: number;
}
export const ChartBox = (props: IProps) => {
  return (
    <Box sx={styles.root}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          //   alignItems: 'center',
          paddingX: pxTovW(15),
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: pxToRem(10) }}
        >
          <Typography variant="h3" fontWeight="bold">
            Key Highlights of Report
          </Typography>
          <Typography variant="bodyText">Bloom Level Breakdown</Typography>
        </Box>
        {/* <SecondaryButton
          sx={{
            display: 'flex',
            width: 'max-content',
            height: 'max-content',
            boxSizing: 'border-box',
            gap: pxToRem(10),
            padding: pxToRem(5),
          }}
          variant="outlined"
        >
          <IconWrapper
            name="download"
            parentFolder="icons"
            type="png"
            size="small"
            customSx={{
              marginRight: pxToRem(5),
              height: pxTovW(22),
              width: pxTovW(22),
            }}
          />
          <Typography variant="smallText" color="#158D29">
            Full Report
          </Typography>
        </SecondaryButton> */}
        {props.homeworkId && <ViewReportButton homeworkId={props.homeworkId} />}
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ width: '100%', justifyContent: 'center' }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        {props.chartData.map((elem, index) => (
          <Grid item key={index} xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{
                display: 'flex',
                boxSizing: 'border-box',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                border: '1px solid #00000029',
                borderRadius: pxTovW(10),
                width: '100%',
                height: 'max-content',
                paddingY: pxTovW(15),
                paddingX: pxTovW(30),
              }}
            >
              <PieChart
                variant="medium"
                obtainedMarks={elem.obtainedMarks}
                maxMarks={elem.maxMarks}
                title={elem.title}
                color={elem.color}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
