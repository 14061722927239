import { useEffect, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';

import { Box, Button, Typography, useMediaQuery } from '@mui/material';

import {
  ActionsPopup,
  ChipBadge,
  ContentDetailCard,
  ContentLockPopup,
  IStyles,
  IconWrapper,
  ImageWrapper,
  InfoPopup,
  LessonPlanInfoPanel,
  LockToggleButton,
  PrimaryButton,
  ResourceRejectedPopup,
  ResourceStatus,
  SectionListWithTopCarousel,
  cloneObject,
  deepClone,
  deserify,
  findDownloadedSubjectByParams,
  firstLetterImage,
  getEnumKeyByEnumValue,
  getHumanReadableTimestampString,
  getLocalStorage,
  getMediaBasePath,
  getTeacherSubjectEnum,
  pxToRem,
  pxTovW,
  resourceTypeName,
  theme,
  useCommonServiceClientContext,
  useDownloadedSubjectRequests,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';

import { Timestamp } from '@bufbuild/protobuf';
import { NoContentCard } from '@geneo2-web/shared-ui';
import {
  ResourceInfo,
  SubjectChapterMiniInfo,
} from '@protos/content_management/content.common.apis_pb';
import {
  ChapterMetaInfo,
  DownloadedSubject,
  Module_ModuleCategoryEnum,
  ResourceCategoryEnum,
  Resource_ResourceEnum,
  ContentApprovalStatusEnum,
  ApprovalInfo,
} from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import {
  CloudUpload,
  SessionModeEnum,
  SessionStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { LessonInfo } from '@protos/learning_management/lms.lesson.common.apis_pb';
import {
  ContentLockStatusType,
  Feature,
} from '@protos/school_management/school.db_pb';
import { TeacherLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { DownloadButtonWrapper } from '../../../components/DownloadButtonWrapper';
import { v4 as uuidv4 } from 'uuid';
import { useConnectedClassContext } from '../../../app/Context/ConnectedClassContextProvider';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import {
  RESOURCE_UPLOAD,
  TEACHING_FLOW,
  TEACH_TOPIC_SELECTION,
} from '../../../routeHandling/RoutesNomenclature';
import { onResourceClick } from '../../../utils/resource';
import { getResourceType, interactionEvent } from '../../Auth/auth.events';
import { aiBookOpenEvent } from '../../Home/home.events';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import { ReadymadeHomeworkSection } from '../../Homework/CreateHomework/components/ReadymadeHw';
import { setBaseStationDetails } from '../reducer/connectedClass.slice';
import {
  setCustomResourceData,
  setLessonPlanRedirectionPath,
  setLessonsByModule,
  setSelectedLessonInfo,
  setSessionMode,
  setTopicResources,
  setUpdatedLessonSessionVisitedResourceInfo,
} from '../reducer/teach.slice';
import {
  aiChapterOpenEvent,
  aiTopicCloseEvent,
  aiTopicOpenEvent,
} from '../teach.events';
import { ReadylessonShimmer, ResourceShimmer } from './shimmer';
import { CustomUploadFileResponse } from '../../Assessment/reducer/assessmentFlow.slice';

const styles: IStyles = {
  root: {
    padding: {
      md: `${pxToRem(20)} ${pxToRem(20)}`,
      lg: `${pxTovW(40)} ${pxTovW(240)}`,
    },
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { xs: pxToRem(20), lg: pxTovW(50) },
  },
  leftPanel: {
    // backgroundColor: 'blue',
    flexGrow: { md: 1, lg: 'inherit' },
    flexBasis: { md: 1, lg: pxTovW(424) },
  },
  rightPanel: {
    // backgroundColor: 'red',
    // flexGrow: 1,
    // flexBasis: { lg: pxTovW(670) },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(5), md: pxTovW(10) },
    paddingBottom: { xs: pxToRem(20), lg: pxTovW(40) },
    paddingLeft: { xs: pxToRem(20), md: 0 },
    paddingTop: { xs: pxToRem(20), md: 0 },
  },
  textWithBadge: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    padding: { xs: pxToRem(20), md: 0 },
  },
  cardsContainer: {
    paddingTop: pxTovW(20),
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(25), md: pxTovW(20) },
  },

  twoLineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    width: '90%',
  },
};

interface FetchFuncArg {
  subjectId: string;
  chapterId: string;
  topicId: string;
}

interface IlockedPopupData {
  resourceId?: string;
  lessonId?: string;
  image: string;
  noofTopics?: string;
  title: string;
  moduleId?: number;
  category?: Module_ModuleCategoryEnum;
  lockedStatus: ContentLockStatusType;
}

export default function LessonPlanLists() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  const [lpLoading, setLpLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const [selectedLP, setSelectedLP] = useState<LessonInfo | undefined>();
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [completedResourceId, setCompletedResourceId] = useState<string[]>([]);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  interface DataForDelete {
    resourceId: string;
    resourceTitle: string;
  }
  const [selectedDataForDelete, setSelectedDataForDelete] = useState<
    DataForDelete | undefined
  >();

  const [isLessonPlanButtonDisabled, setIsLessonPlanButtonDisabled] =
    useState(false);
  const [approvalInfo, setApprovalInfo] = useState<ApprovalInfo>();

  const [resourceLoading, setResourceLoading] = useState<
    'loading' | 'completed' | 'error'
  >('completed');
  const [isContentLockPopupOpen, setIsContentLockPopupOpen] = useState(false);
  const [lockedPopupData, setLockedPopData] = useState<IlockedPopupData | null>(
    null
  );
  const [rejectedResourcePopupOpen, setRejectedResourcePopupOpen] =
    useState(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLessonAPIFail, setIsLessonAPIFail] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const teacher_id = getLocalStorage('userId');
  const { subject_id, chapter_id, topic_id } = useParams();
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const featureInfo = user_info?.schoolDetails[0]?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const location = useLocation();

  const {
    selected_topic_info,
    chapter_resources,
    subject_topic_info,
    custom_resource_data,
    subject_chapter_info,
  } = deserify(useAppSelector((state) => state.teach));
  const [contentLockData, setContentLockData] =
    useState<ContentLockModuleData>();
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const { downloadResolution } = deserify(
    useAppSelector((state) => state.offline)
  );
  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    ContentCommonAPIServiceV2ClientWithStatusCodeHandler,
    LessonCommonAPIServiceV1ClientWithStatusCodeHandler,
    LessonTeachAPIServiceV1ClientWithStatusCodeHandler,
    TeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;

  const { getBaseStationConnectionStatus, authenticateAnonymously } =
    useConnectedClassContext();
  const { setSelectedFunction } = useGlobalContext();
  const { addDownload, isDownloading, isOffline } = useDownloadContext();

  const backButtonClick = async () => {
    await aiTopicCloseEvent({
      topicId: Number(topic_id),
      isOffline: isOffline,
    });
    navigate(`${TEACH_TOPIC_SELECTION}/${subject_id}/${chapter_id}`);
  };

  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    dispatch(setBaseStationDetails(undefined));
    dispatch(setCustomResourceData(undefined));
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const lessonPlanCardClickHandler = async (
    lessonInfo: LessonInfo | undefined,
    pathname: string,
    sessionMode: SessionModeEnum
  ) => {
    setIsLessonPlanButtonDisabled(true);
    try {
      if (
        lessonInfo &&
        class_subject_info?.classId &&
        class_subject_info.sectionId
      ) {
        dispatch(setSessionMode(sessionMode));
        if (sessionMode === SessionModeEnum.SESSION_MODE_TEACH) {
          const stationDetails = await getBaseStationConnectionStatus({
            teacherId: teacher_id,
            classId: class_subject_info?.classId,
            sectionId: class_subject_info.sectionId,
          });
          if (stationDetails) {
            //deciding the connected classroom flow
            dispatch(setBaseStationDetails(stationDetails));
            authenticateAnonymously();
          }
        }
        dispatch(setSelectedLessonInfo(lessonInfo));
        dispatch(setLessonPlanRedirectionPath(pathname));
        const response =
          await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.getPreviousLessonSessionInfo(
            {
              teacherId: BigInt(teacher_id),
              lessonId: lessonInfo.lessonId,
            }
          );

        // console.log('lessonSessionInfo: response', response);
        setCompletedResourceId(response.data?.completedResourceIds || []);
        const newSessionInfo =
          await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.createTeacherLessonSession(
            {
              teacherId: BigInt(teacher_id),
              lessonId: lessonInfo.lessonId,
              schoolId: user_info?.schoolDetails[0]?.schoolId,
              academicYear: 0,
              classId: class_subject_info?.classId,
              section: class_subject_info.section,
              subject:
                subject_id && !isNaN(Number(subject_id))
                  ? getTeacherSubjectEnum(
                      Number(subject_id),
                      user_info?.teachClassSubjects
                    )
                  : undefined,
              // teacherLessonSessionId: ,
              sessionResourceIds: completedResourceId || [],
              startTime: Timestamp.fromDate(new Date()),
              sessionStatus: SessionStatusEnum.SESSION_STATUS_STARTED,
              sessionMode: sessionMode,
            }
          );
        if (newSessionInfo.teacherLessonSessionId) {
          dispatch(setUpdatedLessonSessionVisitedResourceInfo({}));
        }
        await interactionEvent({
          url: 'Teacher_lesson_plan_lists',
          context: 'Ready_lesson_plan',
          name: 'LESSON_PLAN_OPEN',
          isOffline: isOffline,
        });
        navigate({
          pathname: `${TEACHING_FLOW}/${subject_id}/${chapter_id}/${topic_id}/${lessonInfo.lessonId}`,
          search: `?${createSearchParams({
            lessonSessionId: newSessionInfo.teacherLessonSessionId.toString(),
          })}`,
        });
      } else {
        dispatch(
          setToastInfo({
            label: 'No Lesson Plan or Class Selected',
            variant: 'error',
            open: true,
          })
        );
      }
      setIsLessonPlanButtonDisabled(false);
    } catch (error) {
      setIsLessonPlanButtonDisabled(false);
      console.log('error:', error);
    }
  };

  // Getting all the Lesson PLans by Module
  const lessonPlans = deserify(
    useAppSelector((state) => state.teach.lessons_by_module)
  );
  // const lockedStatusObject = deserify(
  //   useAppSelector((state) => state.teach.content_lock)
  // );

  async function fetchLessonsByModule(topicId: string) {
    try {
      setLpLoading('loading');

      const response =
        await LessonCommonAPIServiceV1ClientWithStatusCodeHandler.fetchLessonsByModule(
          {
            personId: BigInt(teacher_id),
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            // subjectId: Number(subjectId),
            // chapterId: Number(chapterId),
            moduleId: Number(topicId),
            sectionId: class_subject_info?.sectionId,
          }
        );
      if (response) {
        if (isContentLockFeature && teacher_id) {
          await getLessonLockedStatus(teacher_id);
        }
        setLpLoading('completed');

        if (response?.data) {
          // sort here
          const sortedData = response.data?.lessons.sort((a, b) =>
            a.lastSessionTime && b.lastSessionTime
              ? Number(b.lastSessionTime?.seconds) -
                Number(a.lastSessionTime?.seconds)
              : Number(b.modifiedOn?.seconds) - Number(a.modifiedOn?.seconds)
          );

          response.data.lessons = sortedData;
          dispatch(setLessonsByModule(response.data));

          await aiBookOpenEvent({
            bookId: class_subject_info?.bookId,
            bookSessionId: uuidv4(),
            isOffline: isOffline,
          });
          await aiChapterOpenEvent({
            chapterId: chapter_resources?.chapterId,
            chapterSessionId: uuidv4(),
            isOffline: isOffline,
          });
          await aiTopicOpenEvent({
            topicId: Number(topic_id),
            isOffline: isOffline,
          });
        }
      }

      // setLoading(false);
    } catch (err) {
      setLpLoading('error');
      setIsLessonAPIFail(true);
      console.log(err);
    }
  }

  const handleOpenPopup = (
    ev?: React.MouseEvent,
    // isLocked?: number,
    // filteredVal?: LessonNodeLock,
    lesson?: LessonInfo,
    categories?: ResourceInfo
  ) => {
    ev?.stopPropagation();
    if (lesson && categories) {
      console.log(
        'Both lesson and categories are provided. Please provide only one.'
      );
      return;
    }
    if (lesson) {
      setLockedPopData({
        image: lesson?.posterImageUrl
          ? getMediaBasePath(lesson?.posterImageUrl, 'processedMediaBucket')
          : '',
        noofTopics: lesson?.resourceIds
          ? lesson?.resourceIds?.length.toString()
          : '',
        title: lesson?.title || '',
        moduleId: Number(topic_id),
        category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
        lessonId: lesson?.lessonId || '',
        lockedStatus:
          getLessonContentLockInfo(lesson.lessonId, contentLockData)
            ?.lockStatus ||
          ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED,
      });
    } else if (categories) {
      setLockedPopData({
        image: categories?.posterImageUrl
          ? getMediaBasePath(
              categories?.posterImageUrl,
              categories.resourceCategoryType ===
                ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
                ? 'userDataBucket'
                : 'processedMediaBucket'
            )
          : '',
        title: categories?.title || '',
        moduleId: Number(topic_id),
        category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
        resourceId: categories?.resourceId || '',
        lockedStatus: getCategoryLockedStatus(
          categories.resourceId,
          contentLockData
        ),
      });
    }

    // setSelectedResourceId(resourceId);
    setIsContentLockPopupOpen(true);

    console.log('nbjfhdkhjk', lockedPopupData);
  };
  const handleClosePopup = () => {
    setIsContentLockPopupOpen(false);
    setLockedPopData(null);
  };

  // const handleClosePopup = () => {
  //   setIsPopupOpen(false);
  // };

  // Getting all the Topic/Teaching Resources
  const topicResources = deserify(
    useAppSelector((state) => state.teach.topic_resources)
  );

  async function fetchTopicResources({
    subjectId,
    chapterId,
    topicId,
  }: FetchFuncArg) {
    try {
      setResourceLoading('loading');
      const response =
        await ContentCommonAPIServiceV2ClientWithStatusCodeHandler.fetchTopicResources(
          {
            personId: BigInt(teacher_id),
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            subjectId: Number(subjectId),
            chapterId: Number(chapterId),
            topicId: Number(topicId),
            sectionId: class_subject_info?.sectionId,
          }
        );
      if (response) {
        if (isContentLockFeature && teacher_id) {
          await getLessonLockedStatus(teacher_id);
        }
        setResourceLoading('completed');
        if (response?.data) {
          response.data.categoryResourceMap.forEach((elem) => {
            elem.categoryResources.sort((a, b) => a.rank - b.rank);
          });
          const data = response.data;
          dispatch(setTopicResources(data));
        }
      }

      // setLoading(false);
    } catch (err) {
      setResourceLoading('error');
      // setError(err);
      console.log(err);
    }
  }

  const teachClassSubjects = user_info?.teachClassSubjects.find(
    (classData) =>
      class_subject_info?.classname === classData.className &&
      class_subject_info?.section === classData.sectionName
  );
  const selectedSubject = teachClassSubjects
    ? teachClassSubjects.subjects.find(
        (subjectData) => subjectData.subjectId === Number(subject_id)
      )
    : undefined;

  const [fetchingState, setFetchingState] = useState(false);
  const downloadedSubject = useDownloadedSubjectRequests({
    subjectId: Number(subject_id),
  });
  const [downloadedMetaData, setDownloadedMetaData] = useState<
    Record<number, ChapterMetaInfo | undefined>
  >({});

  const editResource = async (editProps: {
    resourceId: string;
    coverTitle: string;
  }) => {
    try {
      const response =
        await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchResourceEditDetails(
          {
            personId: BigInt(teacher_id),
            resourceId: editProps.resourceId,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
          }
        );
      console.log('response:', response);
      if (response) {
        const tempTopicName = subject_topic_info?.chapterTopics.find(
          (topic) => topic.topicId === Number(topic_id)
        );
        dispatch(
          setCustomResourceData({
            resourceId: editProps.resourceId,
            selected_chapter: {
              chapter_name: subject_chapter_info?.response.find(
                (chapter: SubjectChapterMiniInfo) =>
                  Number(chapter_id) === chapter.chapterId
              )?.chapterTitle,
              chapter_id: Number(chapter_id),
            },
            selectedTopic: {
              topicName: tempTopicName?.topicTitle,
              topicId: Number(topic_id),
            },
            resourceTitle: response.coverTitle || editProps.coverTitle,
            resourceTime: response.estimatedTimeInMin,
            // contentLock: response. ,
            custom_resource_cover_image: response.coverImageInfo
              ? createCustomUploadFileResponse([response.coverImageInfo])[0]
              : undefined,
            custom_resource_uploaded_files: createCustomUploadFileResponse(
              response.filesInfo
            ),
          })
        );

        navigate(`${RESOURCE_UPLOAD}/${editProps.resourceId}`);
      }
      console.log('response:', response);
    } catch (error) {
      console.log('error:', error);
      dispatch(
        setToastInfo({
          label: `Error Occurred! While trying to get the data.`,
          variant: 'error',
          open: true,
        })
      );
    }
  };

  const createCustomUploadFileResponse = (
    cloudUploadData: CloudUpload[]
  ): CustomUploadFileResponse[] => {
    return cloudUploadData.map((data) => {
      return {
        uploadFileData: data,
        fileUploadStatus: 'success',
        fileSize: (data.fileSizeInMb || 0) * (1024 * 1024),
      };
    });
  };

  const deleteResource = async () => {
    try {
      const response =
        await TeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler.deleteTeacherContent(
          {
            teacherId: BigInt(teacher_id),
            resourceId: selectedDataForDelete?.resourceId,
            // resourceId: 'gx.sub.board.testing-1.hook3_board',
          }
        );
      console.log('response:', response);
      if (response) {
        dispatch(
          setToastInfo({
            label: `${selectedDataForDelete?.resourceTitle} deleted successfully`,
            variant: 'info',
            open: true,
          })
        );

        window.location.reload();
      }
      console.log('response:', response);
    } catch (error) {
      console.log('error:', error);
    }
  };

  useEffect(() => {
    if (subject_id && chapter_id && topic_id) {
      fetchTopicResources({
        subjectId: subject_id,
        chapterId: chapter_id,
        topicId: topic_id,
      });
    }

    if (topic_id) {
      fetchLessonsByModule(topic_id);
    }

    if (
      subject_id &&
      chapter_id &&
      topic_id &&
      !isNaN(Number(subject_id)) &&
      !isNaN(Number(chapter_id)) &&
      !isNaN(Number(subject_id))
    ) {
      (async () => {
        setFetchingState(true);
        const indexDbData = await findDownloadedSubjectByParams({
          subjectId: Number(subject_id),
        });
        const meta = deepClone(downloadedMetaData);
        if (indexDbData.length > 0) {
          const data = indexDbData[0];
          const offlineData =
            await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.getOfflineMetaInfo(
              {
                chapterId: Number(chapter_id),
                topicId: Number(topic_id),
              }
            );
          meta[Number(chapter_id)] = offlineData.data;
        }
        setDownloadedMetaData(meta);
        setFetchingState(false);
      })();
    }
  }, [chapter_id]);

  const getLessonLockedStatus = async (teacherId: string) => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.moduleLockInfoFetch(
          {
            teacherId: BigInt(teacherId),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            moduleId: Number(topic_id),
            category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
          }
        );
      const data = response?.data;
      if (data) {
        setContentLockData(data);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const handleSubmit = async (ev?: React.MouseEvent) => {
    ev?.stopPropagation();
    try {
      // Ensure essential values are present before making the API call
      if (!lockedPopupData?.moduleId || !lockedPopupData?.category) {
        throw new Error('Module ID and category must be provided');
      }

      // Build the moduleInfo object dynamically, including resourceId only if it exists
      const moduleInfo = {
        moduleId: lockedPopupData?.moduleId,
        category: lockedPopupData?.category,
        lockStatus: lockedPopupData.lockedStatus,
        ...(lockedPopupData.lessonId && {
          lessonId: String(lockedPopupData.lessonId),
        }),
        ...(lockedPopupData.resourceId && {
          resourceId: String(lockedPopupData.resourceId),
        }),
      };

      console.log('Module info:', moduleInfo);

      // Perform the API call
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.teacherContentLockUpdate(
          {
            teacherId: BigInt(teacher_id),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            moduleInfo,
          }
        );

      // Check if the response is valid and handle accordingly
      if (response) {
        console.log('API response:', response);
        await getLessonLockedStatus(teacher_id);
        handleClosePopup(); // Close the popup upon successful submission
        setLockedPopData(null); // Clear the lockedPopupData state
        // Refresh lesson lock status
      } else {
        throw new Error('Unexpected API response');
      }
    } catch (error) {
      // Enhanced error handling
      console.error('Submission error:', error);

      // Display a user-friendly error message
      dispatch(
        setToastInfo({
          label: 'Something went wrong',
          variant: 'error',
          open: true,
        })
      );
    }
  };

  const handleLockedRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = getEnumKeyByEnumValue(
      ContentLockStatusType,
      Number(event.target.value)
    );
    const currentData = cloneObject(lockedPopupData);
    if (val && currentData) {
      currentData.lockedStatus = ContentLockStatusType[val];
      setLockedPopData(currentData);
    }
  };
  // useEffect(() => {
  //   if (subject_id && !isNaN(Number(subject_id))) {
  //     (async () => {
  //       const indexDbData = await findDownloadedSubjectByParams({
  //         subjectId: Number(subject_id),
  //       });
  //       if (indexDbData.length > 0) {
  //         const data = indexDbData[0];
  //         setDownloadedSubject(data);
  //       }
  //     })();
  //   }
  // }, [currentDownload]);

  console.log(contentLockData, 'contentLockData');

  return (
    <Box sx={styles.root}>
      <Box sx={styles.leftPanel}>
        <Box sx={styles.header}>
          <Typography variant="h1" sx={styles.twoLineClamp}>
            {selected_topic_info?.topicTitle}
          </Typography>
          <Typography variant="cardText" sx={{ color: '#007CDC' }}>
            {/* Class 8A | Science */}
            {class_subject_info
              ? 'Class ' +
                class_subject_info?.classname +
                class_subject_info?.section +
                ' | ' +
                class_subject_info?.subject
              : ''}
          </Typography>
        </Box>

        {lpLoading === 'loading' ? (
          <ReadylessonShimmer />
        ) : lessonPlans && lessonPlans.lessons.length > 0 ? (
          <ReadymadeHomeworkSection
            isError={lpLoading === 'error'}
            sectionTitle="Ready Lesson Plans"
            contentBoxSx={{ width: { md: isIpadOnly ? '100%' : pxTovW(741) } }}
            items={
              lessonPlans.lessons?.map((lesson, lessonIndex) => {
                return (
                  <LessonPlanInfoPanel
                    key={lessonIndex}
                    variant={isIpad ? 'small' : 'large'}
                    image={getMediaBasePath(
                      lesson.posterImageUrl,
                      'processedMediaBucket'
                    )}
                    mainHeading={lesson.title}
                    onClick={() => {
                      setSelectedLP(lesson);
                      setOpenPopUp(true);
                    }}
                    iconDetails={[
                      {
                        iconName: 'clock',
                        text: `${lesson.estimatedTimeInMin || 0} Min`,
                      },
                      {
                        iconName: 'questions',
                        text:
                          lesson.resourceIds.length > 1
                            ? `${lesson.resourceIds.length} resources`
                            : `${lesson.resourceIds.length} resource`,
                      },
                    ]}
                    isLocked={
                      isContentLockFeature &&
                      contentLockData &&
                      getLessonContentLockInfo(lesson.lessonId, contentLockData)
                        ?.lockStatus ===
                        ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                        ? true
                        : false
                    }
                    status={<LessonPlanInfoPanelStatus lesson={lesson} />}
                    rootStyle={{
                      marginBottom: { md: pxTovW(20) },
                      width: {
                        xs: pxToRem(199),
                        md: pxTovW(350),
                        lg: pxTovW(295),
                      },
                      height: {
                        xs: pxToRem(241),
                        md: pxTovW(420),
                        lg: pxTovW(332),
                      },
                    }}
                  >
                    {!fetchingState && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '15px',
                          right: '10px',
                          display: 'flex',
                          gap: { xs: pxToRem(5), md: pxTovW(5) },
                        }}
                      >
                        {isContentLockFeature && (
                          <LockToggleButton
                            isLocked={
                              contentLockData
                                ? getLessonContentLockInfo(
                                    lesson.lessonId,
                                    contentLockData
                                  )?.lockStatus
                                : false
                            }
                            handleOpenPopup={(ev) => {
                              handleOpenPopup(ev, lesson);
                            }}
                          />
                        )}
                        <DownloadButtonWrapper
                          user={new TeacherLoginResponseType(user_info)}
                          downloadedSubject={
                            downloadedSubject
                              ? new DownloadedSubject(downloadedSubject)
                              : undefined
                          }
                          downloadedMetaData={downloadedMetaData}
                          setDownloadedMetaData={setDownloadedMetaData}
                          subjectId={Number(subject_id)}
                          classId={class_subject_info?.classId}
                          sectionId={class_subject_info?.sectionId}
                          chapterId={Number(chapter_id)}
                          topicId={Number(topic_id)}
                          lessonPlanId={lesson.lessonId}
                          title={`Lesson Plan - ${lesson?.title}`}
                          interactionEventUrl={'Teacher_lesson_plan_lists'}
                          interactionEventContext={'ready_lesson_plan'}
                        />
                      </Box>
                    )}
                  </LessonPlanInfoPanel>
                );
              })
              // []
            }
          />
        ) : (
          //   <Box sx={{ p: { xs: pxToRem(20), md: pxTovW(40) } }}>
          //   <NoContentCard
          //     variant="info"
          //     icon="cards"
          //     text="No cards to show"
          //   />
          // </Box>

          <NoContentCard
            variant="white"
            icon="coming-soon-yellow"
            text="No Lessons to show"
            rootStyle={{
              width: { xs: '100vw', md: pxTovW(741) },
              boxSizing: 'border-box',
              flexDirection: 'column',
              gap: { xs: pxToRem(20), md: pxTovW(20) },
            }}
          />
        )}
      </Box>

      <Box sx={styles.rightPanel}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={styles.textWithBadge}>
            <Typography variant="h2">Teaching Resources</Typography>
            <Typography variant={isIpadOnly ? 'h3' : 'h4'}>
              <ChipBadge
                label={
                  topicResources?.categoryResourceMap
                    ?.map((obj) => obj.categoryResources.length)
                    .reduce((a, b) => a + b, 0) || 0
                }
                color="primary"
                size="small"
              />
            </Typography>
          </Box>

          {useFeatureEnabled(
            user_info?.schoolDetails[0].featuresPlanInfo,
            Feature.CONTENT_UPLOAD
          ) && (
            <Button
              onClick={() => {
                const option = subject_topic_info?.chapterTopics?.find(
                  (topic) => topic.topicId === Number(topic_id)
                );
                dispatch(
                  setCustomResourceData({
                    ...custom_resource_data,
                    selected_chapter: {
                      chapter_name: subject_chapter_info?.response.find(
                        (chapter: SubjectChapterMiniInfo) =>
                          Number(chapter_id) === chapter.chapterId
                      )?.chapterTitle,
                      chapter_id: Number(chapter_id),
                    },
                    selectedTopic: {
                      topicName: option?.topicTitle,
                      topicId: option?.topicId,
                    },
                  })
                );

                navigate(`${RESOURCE_UPLOAD}/${chapter_id}`);
              }}
              sx={{
                width: { xs: pxToRem(81), md: pxTovW(151) },
                height: { xs: pxToRem(26), md: pxTovW(48) },
                borderRadius: { xs: pxToRem(14), md: pxTovW(30) },
                marginRight: { xs: pxToRem(10), md: 'unset' },
                display: 'flex',
                gap: { xs: pxToRem(5), md: pxTovW(6) },
                alignSelf: 'center',
                background: '#0AA34F',
                '&:hover': {
                  background: '#0AA34F',
                },
              }}
            >
              <IconWrapper
                name="upload"
                size="md"
                parentFolder="icons"
                type="png"
              />
              <Typography
                sx={{
                  fontFamily: 'poppins',
                  fontWeight: '600',
                  fontSize: { xs: pxToRem(11), md: pxTovW(21) },
                  color: 'common.white',
                }}
              >
                Upload
              </Typography>
            </Button>
          )}
        </Box>

        {resourceLoading === 'loading' ? (
          <ResourceShimmer />
        ) : resourceLoading === 'error' ? (
          <Box sx={{ p: { xs: pxToRem(20), md: pxTovW(40) } }}>
            <NoContentCard variant="error" icon="error" text="Error Occured" />
          </Box>
        ) : !topicResources ||
          topicResources.categoryResourceMap.length === 0 ? (
          <NoContentCard
            variant="soon"
            icon="hourglass-web"
            text="Coming Soon!"
            rootStyle={{
              height: { xs: pxToRem(150), md: pxTovW(212) },
              boxSizing: 'border-box',
              mt: { xs: pxToRem(20), md: pxTovW(40) },
            }}
          />
        ) : (
          <Box sx={styles.cardsContainer}>
            <>
              {topicResources.categoryResourceMap.map(
                (resource, resourceIndex) => (
                  <SectionListWithTopCarousel
                    key={resourceIndex}
                    title={resource.categoryTitle}
                    subtitle={resource.categoryDescription}
                    itemsToShow={2}
                    // containerMdWidth={isIpadOnly ? pxTovW(960) : pxTovW(550)}
                    containerMdWidth={pxTovW(850)}
                    items={resource.categoryResources.map(
                      (category, categoryIndex) => (
                        <ContentDetailCard
                          approvalStatus={
                            category?.schoolContentInfo?.approvalInfo
                              ?.approvalStatus
                          }
                          resourceStatus={
                            category.resourceCategoryType ===
                              ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES &&
                            category.createdBy ===
                              user_info?.teacherProfileId && (
                              <ResourceStatus
                                status={
                                  category?.schoolContentInfo?.approvalInfo
                                    ?.approvalStatus
                                }
                                handleOpenPopup={(ev?: React.MouseEvent) => {
                                  ev?.stopPropagation();
                                  setRejectedResourcePopupOpen(true);
                                  setSelectedDataForDelete({
                                    resourceId: category.resourceId,
                                    resourceTitle: category.title,
                                  });
                                  if (
                                    category?.schoolContentInfo?.approvalInfo
                                  ) {
                                    setApprovalInfo(
                                      category?.schoolContentInfo?.approvalInfo
                                    );
                                  }
                                }}
                              />
                            )
                          }
                          locked={
                            isContentLockFeature &&
                            (category.resourceCategoryType ===
                              ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES &&
                            category.createdBy === user_info?.teacherProfileId
                              ? category?.schoolContentInfo?.approvalInfo
                                  ?.approvalStatus ===
                                ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_APPROVED
                              : true) && (
                              <LockToggleButton
                                isLocked={getCategoryLockedStatus(
                                  category.resourceId,
                                  contentLockData
                                )}
                                handleOpenPopup={(ev) => {
                                  handleOpenPopup(ev, undefined, category);
                                }}
                              />
                            )
                          }
                          onClick={async () => {
                            await interactionEvent({
                              url: 'Teacher_lesson_plan_lists',
                              context: resource.categoryTitle
                                .toLowerCase()
                                .replace(/ /g, '_'),
                              name: getResourceType(
                                resource.categoryResources[0].resourceType
                              ),
                              isOffline: isOffline,
                            });
                            category?.schoolContentInfo?.approvalInfo
                              ?.approvalStatus ===
                            ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_REJECTED
                              ? setRejectedResourcePopupOpen(true)
                              : onResourceClick(
                                  navigate,
                                  {
                                    resourceId: category.resourceId,
                                    subjectId: Number(subject_id),
                                    chapterId: Number(chapter_id),
                                    topicId: Number(topic_id),
                                    approvalStatus:
                                      category?.schoolContentInfo?.approvalInfo
                                        ?.approvalStatus,
                                  },
                                  SessionModeEnum.SESSION_MODE_TEACH
                                );
                          }}
                          showVideoIcon={
                            category.resourceType ===
                            Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
                          }
                          key={categoryIndex}
                          // variant={isIpadOnly ? 'large' : 'small'}
                          variant={'large'}
                          image={getMediaBasePath(
                            category.posterImageUrl,
                            category.resourceCategoryType ===
                              ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
                              ? 'userDataBucket'
                              : 'processedMediaBucket'
                          )}
                          heading={category.title}
                          iconDetails={iconDetailsCDC(category)}
                          editResourceFunction={
                            category.resourceType ===
                              Resource_ResourceEnum.RESOURCE_TYPE_TEACHER_UPLOAD &&
                            category.createdBy === user_info?.teacherProfileId
                              ? {
                                  editResource: () => {
                                    editResource({
                                      resourceId: category.resourceId,
                                      coverTitle: category.title,
                                    });
                                  },
                                  deleteResource: () => {
                                    setSelectedDataForDelete({
                                      resourceId: category.resourceId,
                                      resourceTitle: category.title,
                                    });
                                    setConfirmDeletePopup(true);
                                  },
                                }
                              : undefined
                          }
                          isLocked={
                            isContentLockFeature &&
                            getCategoryLockedStatus(
                              category.resourceId,
                              contentLockData
                            ) ===
                              ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                          }
                        />
                      )
                    )}
                  />
                )
              )}
            </>
          </Box>
        )}
      </Box>

      <InfoPopup
        iconName="homework2"
        popupText={[
          <Typography variant="h2">Do you want to select?</Typography>,
          <Box
            sx={{
              marginTop: { xs: pxToRem(41), lg: pxTovW(64) },
              display: 'flex',
              gap: { xs: pxToRem(20), lg: pxTovW(20) },
            }}
          >
            <PrimaryButton
              sx={{
                width: { xs: pxToRem(140), lg: pxTovW(226) },
                height: { xs: pxToRem(53), lg: pxTovW(85) },
              }}
              disabled={isLessonPlanButtonDisabled}
              onClick={async () => {
                lessonPlanCardClickHandler(
                  selectedLP,
                  location.pathname,
                  SessionModeEnum.SESSION_MODE_TEACH
                );
                await interactionEvent({
                  url: 'Teacher_lesson_plan_lists',
                  context: 'ready_lesson_plan',
                  name: 'TEACH',
                  isOffline: isOffline,
                });
              }}
            >
              <Typography variant="h2" color="white">
                TEACH
              </Typography>
            </PrimaryButton>
            <PrimaryButton
              sx={{
                width: { xs: pxToRem(140), lg: pxTovW(226) },
                height: { xs: pxToRem(53), lg: pxTovW(85) },
                backgroundColor: '#007CDC',
              }}
              disabled={isLessonPlanButtonDisabled}
              onClick={async () => {
                lessonPlanCardClickHandler(
                  selectedLP,
                  location.pathname,
                  SessionModeEnum.SESSION_MODE_PREPARE_LESSON
                );
                await interactionEvent({
                  url: 'Teacher_lesson_plan_lists',
                  context: 'ready_lesson_plan',
                  name: 'PREPARE',
                  isOffline: isOffline,
                });
              }}
            >
              <Typography variant="h2" color="white">
                PREPARE
              </Typography>
            </PrimaryButton>
          </Box>,
        ]}
        background="#007CDC"
        handleClose={() => {
          setSelectedLP(undefined);
          setOpenPopUp(false);
        }}
        open={openPopUp}
      />

      <ActionsPopup
        open={confirmDeletePopup}
        handleClose={() => {
          setConfirmDeletePopup(false);
        }}
        fontSmall
        iconName="delete"
        popupText={`Are you sure you want to delete - ${selectedDataForDelete?.resourceTitle}?`}
        yesClickHandler={async () => {
          deleteResource();
          setConfirmDeletePopup(false);
        }}
        noClickHandler={async () => {
          setConfirmDeletePopup(false);
        }}
      />
      {lockedPopupData && (
        <ContentLockPopup
          open={isContentLockPopupOpen}
          onClose={handleClosePopup}
          onSubmit={(ev?: React.MouseEvent) =>
            lockedPopupData && handleSubmit(ev)
          }
          selectedValue={lockedPopupData.lockedStatus}
          onRadioChange={handleLockedRadioChange}
          lockDataObj={lockedPopupData}
        />
      )}
      {approvalInfo?.approvalStatus ===
        ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_REJECTED && (
        <ResourceRejectedPopup
          open={rejectedResourcePopupOpen}
          handleClose={() => {
            setRejectedResourcePopupOpen(false);
          }}
          fontSmall
          iconName="exclamationerror"
          popupText={`Are you sure you want to delete this resource?`}
          tryAgainClickHandler={async () => {
            setRejectedResourcePopupOpen(false);
            if (selectedDataForDelete) {
              editResource({
                resourceId: selectedDataForDelete?.resourceId,
                coverTitle: selectedDataForDelete?.resourceTitle,
              });
            }
          }}
          removeClickHandler={async () => {
            setRejectedResourcePopupOpen(false);
            setConfirmDeletePopup(true);
          }}
          approvalInfo={approvalInfo}
        />
      )}
    </Box>
  );
}

const iconDetailsCDC = (resource: ResourceInfo) => {
  const retValue = [
    {
      iconName: 'clock',
      text: `${resource.estimatedTimeInMin} Min`,
    },

    {
      iconName: resourceTypeName(resource.resourceType).icon,
      text: resourceTypeName(resource.resourceType).name,
    },
  ];

  return retValue;
};

interface InfoDisplayPanelStatus {
  lesson: LessonInfo;
}
const LessonPlanInfoPanelStatus = ({ lesson }: InfoDisplayPanelStatus) => {
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const taughtTime = getHumanReadableTimestampString(
    lesson.lastSessionTime
  )?.split(' ');
  const editTime = getHumanReadableTimestampString(lesson.modifiedOn)?.split(
    ' '
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: `${pxTovW(2)} solid #E7E7E7D9`,
        pt: { xs: pxToRem(5), md: pxTovW(5) },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: pxToRem(5), md: pxTovW(5) },
        }}
      >
        <ImageWrapper
          name="geneo-blue"
          type="png"
          parentFolder="icons"
          styles={{
            width: { xs: pxToRem(18), md: pxTovW(28) },
            height: { xs: pxToRem(18), md: pxTovW(28) },
            borderRadius: '50%',
          }}
          path={
            getMediaBasePath(lesson.teacherProfileImageUrl) ||
            firstLetterImage(lesson.teacherName) ||
            firstLetterImage('Geneo')
          }
        />

        <Typography variant="smallText" fontWeight="bold">
          {user_info?.teacherProfileId.toString() ===
          lesson.createdBy.toString()
            ? 'You'
            : lesson.teacherName || 'Geneo'}
        </Typography>
      </Box>

      <Box>
        {lesson.lastSessionTime ? (
          <Typography variant="subText" color="text.disabled">
            {taughtTime && Number(taughtTime[0]) < 2
              ? 'Taught Today'
              : 'Taught ' + taughtTime?.join(' ')}
          </Typography>
        ) : (
          editTime &&
          lesson.teacherName &&
          !lesson.lastSessionTime && (
            <Typography variant="subText" color="text.disabled">
              {Number(editTime[0]) < 2
                ? 'Recently Edited'
                : 'Edited ' + editTime?.join(' ')}
            </Typography>
          )
        )}
      </Box>
    </Box>
  );
};

const getCategoryLockedStatus = (
  resourceId: string,
  contentLockData?: ContentLockModuleData
) => {
  return contentLockData?.moduleUpdatedLockInfo?.lockedResourceIds?.includes(
    resourceId
  )
    ? ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
    : ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED;
};

const getLessonContentLockInfo = (
  lessonId: string,
  contentLockData?: ContentLockModuleData
) => {
  return contentLockData?.moduleUpdatedLockInfo?.lessonsLockInfo.find(
    (val) => val.lessonId === lessonId
  );
};
