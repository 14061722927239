import React, { useState } from 'react';
import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { OnlineStatusTag, pxToRem, pxTovW, theme } from '@geneo2-web/shared-ui';

const HoverBox = ({
  Component,
  filter,
  selectedFilter,
}: {
  Component: JSX.Element;
  filter: string;
  selectedFilter: string;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        width: { xs: pxToRem(70), md: pxTovW(200), lg: pxTovW(155) },
        height: { xs: pxToRem(65), md: pxTovW(120), lg: pxTovW(104) },
        paddingY: pxTovW(10),
        paddingX: { xs: pxToRem(1), md: 0 },
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
        justifyContent: 'center',
        // bgcolor: 'primary.light',
        transition: '0.3s',
        overflow: 'hidden',
        bgcolor: 'transparent',
        borderRadius: 2,
      }}
    >
      {isHovered || selectedFilter === filter ? (
        <>
          {/* Message Bubble */}
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: { xs: pxToRem(70), md: pxTovW(200), lg: pxTovW(143) },
              height: { xs: pxToRem(50), md: pxTovW(100), lg: pxTovW(84) },
              backgroundColor: '#EFEFEF',
              opacity: 0.8,
              borderRadius: pxToRem(10),
              top: '10%',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {Component}
          </Box>
          {/* Triangle Pointer */}
          <Box
            sx={{
              position: 'absolute',
              width: 0,
              height: 0,
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '10px solid rgb(239, 239, 239,0.8)',
              top: { xs: pxToRem(55), md: pxTovW(110), lg: pxTovW(95) },
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          ></Box>
        </>
      ) : (
        Component
      )}
    </Box>
  );
};
interface IFilterComponent {
  filter: string;
  value: string;
  percentage: number;
}
interface IProps {
  filterData: IFilterComponent[];
  handleFilterFunction?: (value: string) => void;
}
export const FilterComponent = (props: IProps) => {
  const { filterData, handleFilterFunction } = props;
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const [selectedFilter, setSelectedFilter] = useState<string>('ALL');
  return (
    <Box
      sx={{
        display: 'flex',
        width: { xs: '100%', lg: pxTovW(794) },
        marginX: { xs: 'auto', md: '0' },
        justifyContent: 'space-between',
        borderRadius: { xs: pxToRem(5), md: pxToRem(10) },
        // backgroundColor: '#FFFFFF',
        border: { md: '1px solid #E0DFDE' },
        paddingX: { xs: pxToRem(5), md: pxTovW(5) },
        paddingY: { xs: pxToRem(15), md: pxTovW(5) },
        // gap: pxToRem(10),
        boxSizing: 'border-box',
        boxShadow: `0px 0px ${pxToRem(10)} #0000001F`,
        marginBottom: pxTovW(20),
        // backgroundColor: 'red',
      }}
    >
      {filterData.map((elem, index) => (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          onClick={() => {
            console.log(elem.filter);
            setSelectedFilter(elem.filter);
            handleFilterFunction && handleFilterFunction(elem.filter);
          }}
        >
          {' '}
          <HoverBox
            filter={elem.filter}
            selectedFilter={selectedFilter}
            Component={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: pxTovW(2),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: pxToRem(5),
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  {elem.filter !== 'ALL' && elem.filter !== 'NOT_SUBMITTED' && (
                    <Box
                      sx={{
                        width: { xs: pxToRem(7), md: pxTovW(13) },
                        background:
                          elem.filter === 'EXCELLENT'
                            ? '#16E817'
                            : elem.filter === 'AVERAGE'
                            ? '#FBD749'
                            : '#FC6D66',
                        height: { xs: pxToRem(7), md: pxTovW(14) },
                        borderRadius: '10px',
                      }}
                    ></Box>
                  )}
                  <Typography
                    variant={isIpad ? 'smallText' : 'cardText'}
                    sx={{ zIndex: 1, margin: 'auto' }}
                  >
                    {(() => {
                      switch (elem.filter) {
                        case 'EXCELLENT':
                          return 'Excellent';
                        case 'AVERAGE':
                          return 'Average';
                        case 'LOW':
                          return 'Low';
                        case 'NOT_SUBMITTED':
                          return 'Not Submitted';
                        default:
                          return 'All';
                      }
                    })()}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: pxToRem(5) }}>
                  <Typography variant="smallText" sx={{ zIndex: 1 }}>
                    {elem.value}
                  </Typography>
                  <Typography variant="smallText" sx={{ zIndex: 1 }}>
                    ({elem.percentage}%)
                  </Typography>
                </Box>
              </Box>
            }
          />
          {index !== props.filterData.length - 1 && (
            <Divider
              orientation="vertical"
              sx={{
                width: '1px',
                height: '50%',
                backgroundColor: '#E0DFDE',
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};
