import {
  IconWrapper,
  ImageWrapper,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Divider, Modal, Slide, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LearningOutcomes } from '@protos/analysis_management/analysis.teacher.apis_pb';
interface IProps {
  open: boolean;
  onClose: () => void;
  title: string;
  learningOutcomes: LearningOutcomes[];
}
export const LearningOutcomePopup = (props: IProps) => {
  const { open, onClose, learningOutcomes, title } = props;
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: pxToRem(20), md: pxToRem(20) },
        width: { xs: 'unset', md: 'max-content' },
        alignItems: { xs: 'center', md: 'unset' },
        height: 'max-content',
        margin: 'auto',
      }}
    >
      <>
        <Slide
          direction="up"
          in={open}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <Box
            sx={{
              margin: 'auto',
              width: { xs: '80%', md: pxTovW(1000), lg: pxTovW(813) },
              // height: { xs: pxToRem(500), md: pxTovW(630) },
              // minHeight: { xs: pxToRem(400), md: pxTovW(670) },
              backgroundColor: 'common.white',
              borderRadius: { xs: pxToRem(10), md: pxToRem(15) },
              paddingX: { xs: pxToRem(20), md: pxToRem(40) },
              paddingY: { xs: pxToRem(20), md: pxToRem(40) },
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                borderBottom: '1px solid #E0DFDE',
                paddingBottom: { xs: pxToRem(5), md: pxToRem(10) },
              }}
            >
              <Typography variant="h2">{title}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: pxToRem(20),
                marginTop: { xs: pxToRem(10), md: pxTovW(20) },
                maxHeight: { xs: pxToRem(300), md: pxTovW(300) },
                overflowY: 'auto',
              }}
            >
              {learningOutcomes.map((elem, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      gap: pxToRem(10),
                      alignItems: 'center',
                      paddingBottom: pxToRem(10),
                      width: '100%',
                      borderBottom:
                        learningOutcomes.length - 1 === index
                          ? 'none'
                          : '1px solid #E0DFDE',
                    }}
                  >
                    <DoneRoundedIcon
                      sx={{
                        color: '#007CDC',
                        height: pxToRem(20),
                        width: pxToRem(20),
                      }}
                    />
                    <Typography variant="h4">{elem.learningOutcome}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Slide>

        <ImageWrapper
          name="close-yellow"
          type="png"
          parentFolder="icons"
          onClick={() => onClose()}
          styles={{
            height: { xs: pxToRem(50), md: pxTovW(50) },
            width: { xs: pxToRem(50), md: pxTovW(50) },
            cursor: 'pointer',
          }}
        />
      </>
    </Modal>
  );
};
