import {
  HwProceedButton,
  IStyles,
  InfoBar,
  InputField,
  PrimaryButton,
  deserify,
  getDeviceType,
  getLocalStorage,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, Typography } from '@mui/material';
import {
  BloomLevelEnum,
  Module_ModuleCategoryEnum,
  Question,
} from '@protos/content_management/content.db_pb';
import {
  TaskCreationStatusEnum,
  TaskEnum,
} from '@protos/learning_management/lms.db_pb';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../reduxStore/reduxHooks';

import {
  calculateMinMaxTime,
  totalNoQuestions,
} from '../../../../utils/functions';
import { interactionEvent } from '../../../Auth/auth.events';
import { setToastInfo } from '../../../Home/reducer/homeDashboard.slice';
import {
  resetSelectedTasksInfo,
  setModuleFilteredQuestions,
  setSelectedTasksInfo,
  setSubmittedAssessmentId,
} from '../../reducer/assessmentFlow.slice';
import DifficultyNos from './components/DifficultyNos';
import QuestionsList from './components/QuestionsList';
import ReviewHeader from './components/ReviewHeader';
import { canEdit, createTaskInfoModel, updateApiResponse } from './functions';
import {
  ASSESSMENT_ASSIGN,
  FORMATIVE,
  HOMEWORK,
} from '../../../../routeHandling/RoutesNomenclature';
import SelectedChapters from './components/SelectedChapters';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';
import { BloomLevel } from '@protos/analysis_management/analysis.assessment.apis_pb';
import { BloomLevelComponent } from './components/BloomLevelComponent';

const styles: IStyles = {
  root: {
    backgroundColor: 'neutral.paleGrey',
    // height: '100vh',
    marginBottom: pxTovW(10),
    maxHeight: {
      xs: `calc(100vh - ${pxToRem(60)})`,
      md: `calc(100vh - ${pxTovW(100)})`,
    },
    display: 'flex',
    flexDirection: 'column',
    overflow: { md: 'hidden', xs: 'auto' },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { md: pxTovW(40) },
    padding: { md: pxToRem(20), lg: `${pxTovW(15)} ${pxTovW(240)}` },
    overflow: 'scroll',
    height: '100%',
  },
  leftPanel: {
    flexBasis: { md: pxTovW(550), lg: pxTovW(400) },
    display: 'flex',
    flexDirection: 'column',
    gap: { md: pxTovW(30) },
  },
  rightPanel: {
    flexBasis: { lg: pxTovW(1000) },
    paddingBottom: { xs: pxToRem(70), md: 0 },
    overflow: { xs: 'initial', md: 'scroll' },
  },
};
interface IProps {
  setQuestionBankDisplayFlag: Dispatch<SetStateAction<boolean>>;
}
export default function ReviewFormativeAssessment(props: IProps) {
  const location = useLocation();
  const pathname = location.pathname;
  const isFormativeAssessment = pathname.includes(FORMATIVE);
  console.log(
    'isFormativeAssessmentisFormativeAssessment',
    isFormativeAssessment
  );
  const { setQuestionBankDisplayFlag } = props;
  const navigate = useNavigate();
  const android = window.Android;
  const deviceType = getDeviceType();
  const { setSelectedFunction } = useGlobalContext();
  const dispatch = useAppDispatch();
  const { module_id } = useParams();
  const teacher_id = getLocalStorage('userId');
  const { isOffline } = useDownloadContext();
  const [quesLoading, setQuesLoading] = useState(false);
  const [quesError, setQuesError] = useState(false);
  const { class_subject_info } = deserify(
    useAppSelector((state) => state.homeDashboard)
  );

  const {
    LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler,
    LmsHomewokTeacherAPIServiceV2ClientWithStatusCodeHandler,
    LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler,
    LmsAssessmentTeacherAPIServiceV2WithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const {
    questions_count,
    module_filtered_questions,
    chapterwise_topic,
    selected_tasks_info,
    fetched_assessment_details,
  } = deserify(useAppSelector((state) => state.assessment));
  const handleInputFocus = async () => {
    // navigate(`${HOMEWORK_QUESTIONBANK}/${module_id}`);
    setQuestionBankDisplayFlag(true);
    await interactionEvent({
      url: 'Teacher_current_homework',
      context: 'My_homework',
      name: 'SEARCH',
      isOffline: isOffline,
    });
  };
  const handleback = () => {
    console.log('selected function');
    dispatch(resetSelectedTasksInfo());
    // navigate(`${HOMEWORK_CREATE}/${module_id}`);
    navigate(-1);
  };
  useEffect(() => {
    setSelectedFunction(() => handleback);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  useEffect(() => {
    try {
      if (deviceType === 'android' && android?.disableAppRefresh) {
        android.disableAppRefresh();
        console.log('App refresh enabled on Android for home page');
      }
    } catch (error) {
      console.log(
        'An error occurred while attempting to enable app refresh on Android:'
      );
    }
  }, []);

  const getModuleQuestions = async (moduleId: string) => {
    try {
      setQuesLoading(true);
      const response =
        await LmsAssessmentTeacherAPIServiceV2WithStatusCodeHandler.getModuleQuestionsWithFilters(
          {
            teacherId: teacher_id,
            subjectId: class_subject_info?.subjectId,
            moduleIds: [Number(moduleId)],
            requiredDifficultyLevelsCountInfo: questions_count,
          }
        );
      if (response.data) {
        const data = response.data;
        if (module_filtered_questions) {
          const updatedResponse = updateApiResponse(
            data,
            module_filtered_questions,
            questions_count
          );
          dispatch(
            setModuleFilteredQuestions({ questions: updatedResponse.questions })
          );
          const tasksInfo = createTaskInfoModel(updatedResponse.questions);
          dispatch(setSelectedTasksInfo(tasksInfo));
        } else {
          dispatch(setModuleFilteredQuestions(data));
        }
      }
      setQuesLoading(false);
    } catch (err) {
      console.log(err);
      setQuesLoading(false);
      setQuesError(true);
      console.error(err);
    }
  };
  // const handlingQuestionsOrder = () => {};

  const updateClickHandler = async () => {
    await interactionEvent({
      url: 'Teacher_current_homework',
      context: 'My_homework',
      name: 'UPDATE',
      isOffline: isOffline,
    });

    if (module_id) {
      getModuleQuestions(module_id);
    }
  };
  const proceedClickhandler = async () => {
    await interactionEvent({
      url: 'Teacher_current_homework',
      context: 'My_homework',
      name: 'PROCEED',
      isOffline: isOffline,
    });

    if (
      questions_count.noOfHighQuestions === 0 &&
      questions_count.noOfMediumQuestions === 0 &&
      questions_count.noOfLowQuestions === 0
    ) {
      dispatch(
        setToastInfo({
          variant: 'error',
          label: 'Add atleast one question ',
          open: true,
        })
      );
    } else {
      const highQuestionCount = module_filtered_questions?.questions.filter(
        (ques) => ques.questionMeta?.difficultyLevel === 1
      ).length;
      const mediumQuestionCount = module_filtered_questions?.questions.filter(
        (ques) => ques.questionMeta?.difficultyLevel === 2
      ).length;
      const lowQuestionCount = module_filtered_questions?.questions.filter(
        (ques) => ques.questionMeta?.difficultyLevel === 3
      ).length;
      if (
        highQuestionCount === questions_count.noOfHighQuestions &&
        mediumQuestionCount === questions_count.noOfMediumQuestions &&
        lowQuestionCount === questions_count.noOfLowQuestions
      ) {
        submitHomework(TaskCreationStatusEnum.TASK_CREATION_STATUS_APPROVED);
      } else {
        dispatch(
          setToastInfo({
            variant: 'error',
            label:
              'Please click update and review questions before proceeding ',
            open: true,
          })
        );
      }
    }
    try {
      if (deviceType === 'android' && android?.enableAppRefresh) {
        console.log('enableAppRefresh called');
        android.enableAppRefresh();
      }
    } catch (error) {
      console.log(
        'An error occurred while attempting to enable app refresh on Android:'
      );
    }
  };
  const submitHomework = async (status: TaskCreationStatusEnum) => {
    // repeated TaskInfoModel selected_tasks_info = 7 [(google.api.field_behavior) = REQUIRED];
    try {
      const resposne =
        await LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler.assessmentCreationSubmit(
          {
            teacherId: teacher_id,
            subjectId: class_subject_info?.subjectId,
            sectionId: class_subject_info?.sectionId,
            taskName: 'new Assessment',
            moduleId: Number(module_id),
            category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
            selectedTasksInfo: selected_tasks_info,
            creationStatus: status,
            sourceGeneoAssessmentId: fetched_assessment_details?.teacherId
              ? undefined
              : fetched_assessment_details?.assessmentId,
            taskType: isFormativeAssessment
              ? TaskEnum.TASK_FORMATIVE_ASSESSMENT_CUSTOM
              : TaskEnum.TASK_HOMEWORK_CUSTOM,
            chapterIdsInfo: isFormativeAssessment
              ? fetched_assessment_details?.taskMetaInfo?.chapterInfo
                ?.chapterTopicsInfo
              : undefined,
          }
        );
      if (resposne.data) {
        console.log('resposne:', resposne);
        const hwId = resposne.data.assessment?.assessmentId;
        dispatch(setSubmittedAssessmentId(hwId));
        isFormativeAssessment
          ? navigate(`${FORMATIVE}${ASSESSMENT_ASSIGN}`)
          : navigate(`${HOMEWORK}${ASSESSMENT_ASSIGN}`);
      }
    } catch (err) {
      console.log(err);
      console.error(err);
    }
  };
  const totalMarks = (question?: Question[]) => {
    if (!question) {
      return 0;
    }
    const marks = question.reduce(
      (acc, curr) =>
        acc +
        (curr.question?.model.value?.commonQuestionContent?.marks[0] || 0),
      0
    );
    return marks;
  };
  const timeOfQuestions = () => {
    let time = 0;
    if (module_filtered_questions) {
      time = module_filtered_questions.questions.reduce((acc, curr) => {
        const c = curr.question?.model.value;
        const currentTime = c ? c.commonQuestionContent?.time || 0 : 0;
        return acc + currentTime;
      }, 0);
      return (time / 60).toFixed(2);
    }
    return `${calculateMinMaxTime(questions_count).min}-${calculateMinMaxTime(questions_count).max
      }`;
  };
  const getBloomLevelData = () => {
    if (!module_filtered_questions || !module_filtered_questions.questions) {
      return [];
    }

    const bloomLevelMapping = [
      {
        title: 'Retention',
        color: '#005DAA',
        type: BloomLevelEnum.BLOOMLEVEL_RETENTION,
      },
      {
        title: 'Analysis',
        color: '#F1A33B',
        type: BloomLevelEnum.BLOOMLEVEL_ANALYSIS,
      },
      {
        title: 'Application',
        color: '#009383',
        type: BloomLevelEnum.BLOOMLEVEL_APPLICATION,
      },
      {
        title: 'Comprehension',
        color: '#5DC152',
        type: BloomLevelEnum.BLOOMLEVEL_COMPREHENSION,
      },
    ];

    const bloomLevelData = bloomLevelMapping.map(({ title, color, type }) => {
      const filteredQuestions = module_filtered_questions.questions.filter(
        (question) => question.questionMeta?.bloomLevel === type
      );
      return {
        title,
        Questions: filteredQuestions.length
          ? (filteredQuestions.length /
              module_filtered_questions.questions.length) *
            100
          : 0,
        color,
      };
    });

    return bloomLevelData;
  };
  return (
    <Box sx={styles.root}>
      <ReviewHeader
        isFormativeAssessment={isFormativeAssessment}
        disabled={totalNoQuestions(questions_count) === 0}
        proceedClickhandler={proceedClickhandler}
      />
      <Box sx={styles.mainContainer}>
        <Box sx={styles.leftPanel}>
          <Box
            sx={{
              padding: { xs: pxToRem(20), md: 0 },
              display:
                canEdit(teacher_id, fetched_assessment_details) === true &&
                  isFormativeAssessment === false
                  ? 'block'
                  : 'none',
            }}
          >
            <InputField
              variant="outlined"
              fullWidth
              boldtext={true}
              sx={{
                borderRadius: { xs: pxToRem(30), md: pxTovW(40) },
                border: '1px solid #CCE6FE',
              }}
              placeholder="Add From Question Bank"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" color="primary" />
                  </InputAdornment>
                ),
              }}
              onFocus={handleInputFocus}
            />
          </Box>
          <Box>
            <DifficultyNos
              mobileVariant="horizontal"
              desktopVariant="vertical"
              disabled={
                isFormativeAssessment ||
                !canEdit(teacher_id, fetched_assessment_details)
              }
            />
            <Box>
              <SelectedChapters assessmentInfo={fetched_assessment_details} />
            </Box>

            {(!isFormativeAssessment ||
              canEdit(teacher_id, fetched_assessment_details)) && (
                <Box
                  sx={{
                    display: {
                      xs: 'block',
                      md: 'none',
                      width: '60%',
                      margin: 'auto',
                      marginTop: pxToRem(10),
                      marginBottom: pxToRem(10),
                    },
                  }}
                >
                  <PrimaryButton
                    onClick={updateClickHandler}
                  // disabled={
                  //   questions_count.noOfHighQuestions === 0 &&
                  //   questions_count.noOfLowQuestions === 0 &&
                  //   questions_count.noOfMediumQuestions === 0
                  // }
                  >
                    Update
                  </PrimaryButton>
                </Box>
              )}
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <InfoBar
              contentList={[
                {
                  iconName: 'questions',
                  heading: `${totalNoQuestions(questions_count)}`,
                  subHeading: 'Questions',
                },
                {
                  iconName: 'clock',
                  heading: timeOfQuestions(),
                  subHeading: 'Minutes',
                },
                {
                  iconName: 'marks',
                  heading: `${totalMarks(
                    module_filtered_questions?.questions
                  )}`,
                  subHeading: 'Marks',
                },
              ]}
            />
          </Box>
          <Box>
            <BloomLevelComponent bloomLevelData={getBloomLevelData()} />
          </Box>
          {(!isFormativeAssessment ||
            canEdit(teacher_id, fetched_assessment_details)) && (
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <PrimaryButton
                  onClick={updateClickHandler}
                // disabled={
                //   questions_count.noOfHighQuestions === 0 &&
                //   questions_count.noOfLowQuestions === 0 &&
                //   questions_count.noOfMediumQuestions === 0
                // }
                >
                  Update
                </PrimaryButton>
              </Box>
            )}
        </Box>
        <Box sx={styles.rightPanel}>
          <QuestionsList
            isFormative={isFormativeAssessment}
            title={<Typography variant="h2">Review Questions</Typography>}
            subtitle={!isFormativeAssessment ? "Replace, Re-order, or Remove" : ''}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          position: 'fixed',
          bottom: 10,
          left: 12,
        }}
      >
        <HwProceedButton
          clickHandler={proceedClickhandler}
          disabled={totalNoQuestions(questions_count) === 0}
          tabs={[
            { quantity: `${totalNoQuestions(questions_count)}`, title: 'Qs' },
            {
              quantity: timeOfQuestions(),
              title: 'Mins',
            },
            {
              quantity: `${totalMarks(module_filtered_questions?.questions)}`,
              title: 'Marks',
            },
          ]}
        />
      </Box>
    </Box>
  );
}
