import { Timestamp } from '@bufbuild/protobuf';
import {
  ImageWrapper,
  InfoDisplayPanel,
  NewSectionList,
  deserify,
  firstLetterImage,
  getDifficultyLevelString,
  getHumanReadableTimestampString,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
  theme,
  setLocalStorage,
  getEnvConfig,
  IClassAndSubjectSelected,
} from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { HomeworkTask } from '@protos/learning_management/lms.hw.common.apis_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  ASSESSMENT_CURRENT,
  HOMEWORK,
  HOMEWORK_COMPLETED,
  HOMEWORK_ONGOING,
} from '../../../routeHandling/RoutesNomenclature';
import { getSubjectsMap } from '../../../utils/icons';
import { interactionEvent } from '../../Auth/auth.events';
import { convertToSequenceInfo } from '../../Homework/ReviewHomework/functions';
import {
  resetCreatedAssessmentStatus,
  resetSelectedTasksInfo,
  setCreatedAssessmentStatus,
  setFetchedAssessmentDetails,
  setModuleFilteredQuestions,
  setSelectedTasksInfo,
} from '../../Assessment/reducer/assessmentFlow.slice';
import {
  setAssessmentListData,
  setSelectedAssessmentId,
} from '../../ManageHomework/reducer/manageHomework.slice';
import SectionListSckeleton from '../shimmer';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { AssessmentEnum } from '@protos/learning_management/lms.db_pb';
import { AssessmentListEnum } from '@protos/learning_management/lms.assessment.student.apis_pb';
// import {  } from '../reducer/homeDashboard.slice';

import { setTeacherHwList } from '../reducer/homeDashboard.slice';
import { AssessmentTask } from '@protos/learning_management/lms.assessment.common.apis_pb';
interface IProps {
  seeAllClickHandler: () => void;
}
export default function HomeHomeworksList(props: IProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const { seeAllClickHandler } = props;
  const navigate = useNavigate();
  const { isOffline } = useDownloadContext();
  // const [homeworkList, setHomeworkList] = useState<HomeworkTask[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const dispatch = useAppDispatch();
  const config = getEnvConfig();
  const prevClassSubjectInfo = useRef<IClassAndSubjectSelected | undefined>(
    undefined
  );
  const isFirstRender = useRef(false); // Tracks if it's the initial render
  const teacher_profile_id = getLocalStorage('userId');
  const { assessment_list_data } = useAppSelector(
    (state) => state.manageHomework
  );
  const { class_subject_info, teacher_hw_list } = deserify(
    useAppSelector((state) => state.homeDashboard)
  );
  const {
    LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler,
    LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler,
    LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();

  const subMap = getSubjectsMap();

  useEffect(() => {
    dispatch(resetSelectedTasksInfo());
    dispatch(resetCreatedAssessmentStatus());
  }, []);

  useEffect(() => {
    const lastFetchTime = getLocalStorage('homeAPILastTime'); // Retrieve the last fetch time
    const currentTime = new Date().getTime();
    console.log(class_subject_info, 'class_subject_info');
    const shouldFetchFromAPI =
      !lastFetchTime ||
      (lastFetchTime &&
        currentTime - Number(lastFetchTime) >=
          Number(config.homeExpirationTime));

    if (shouldFetchFromAPI || !assessment_list_data) {
      getTeacherLessonList(teacher_profile_id);
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = true;
      prevClassSubjectInfo.current = class_subject_info; // Initialize the ref
      return;
    }

    // Convert objects to strings for deep comparison
    const prevValueString = JSON.stringify(prevClassSubjectInfo.current);
    const currentValueString = JSON.stringify(class_subject_info);
    if (prevValueString !== currentValueString) {
      getTeacherLessonList(teacher_profile_id);
    }

    // Update the ref to the current value
    prevClassSubjectInfo.current = class_subject_info;
  }, [class_subject_info]);

  const getTeacherLessonList = async (teacherId: string) => {
    try {
      setLoading(true);
      // const response = await LmsTeacherHWAPIsSer({
      //   teacherId: BigInt(teacherId),
      // });
      // const response =
      //   await LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler.getTeacherHomeworkList(
      //     {
      //       teacherId: BigInt(teacherId),
      //       sectionId: class_subject_info?.sectionId,
      //       subjectId: class_subject_info?.subjectId,
      //     }
      //   );
      const response =
        await LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler.getTeacherAssessmentsList(
          {
            teacherId: BigInt(teacherId),
            subjectId: class_subject_info?.subjectId,
            sectionId: class_subject_info?.sectionId,
            assessmentType: AssessmentEnum.ASSESSMENT_HOMEWORK,
          }
        );
      const data = response.assessmentList;
      if (data?.assessmentList) {
        const hw_data = data.assessmentList;
        dispatch(setAssessmentListData(hw_data));
        const list = [
          ...data.assessmentList.assigned,
          // ...data.homeworkList.ended,
        ];

        // dispatch(setAssessmentListData(data.assessmentList));
        // const list = [
        //   ...data.assessmentList.assigned,
        //   ...data.assessmentList.ended,
        // ];

        //even after sending a subject id as payload all the homeworks are received,
        //even if we use filter at our end then only few combination of (subject and class) have data
        dispatch(setTeacherHwList(list));
        // setHomeworkList(list);
        setLocalStorage('homeAPILastTime', String(Date.now())); // Update fetch time
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
      dispatch(setTeacherHwList(undefined));
      console.log(err);
    }
  };

  const cardClickHandler = async (
    homeworkId: number,
    homeworkTargetDate?: Timestamp,
    moduleId?: number
  ) => {
    if (!homeworkTargetDate) {
      return;
    }
    if (!homeworkTargetDate) {
      if (moduleId) {
        getHomeworkDetails(homeworkId, moduleId.toString());
        return;
      }
    }

    await interactionEvent({
      url: 'Teacher_Home',
      context: 'Homeworks',
      name: 'HOMEWORK_OPEN',
      isOffline: isOffline,
    });

    const time = homeworkTargetDate.toDate();
    const isEnded = hasTimePassed(time.getTime().toString());
    switch (isEnded) {
      case false:
        dispatch(setSelectedAssessmentId(homeworkId));
        navigate(HOMEWORK_ONGOING);
        break;
      case true:
        dispatch(setSelectedAssessmentId(homeworkId));
        navigate(HOMEWORK_COMPLETED);
        break;
      default:
        break;
    }
  };

  const getHomeworkDetails = async (homeworkId: number, moduleId: string) => {
    try {
      const response =
        await LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchAssessmentContent(
          {
            personId: teacher_profile_id,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            assessmentId: homeworkId,
          }
        );
      if (response.data) {
        const HwData = response.data;
        dispatch(
          setCreatedAssessmentStatus(HwData.assessment?.taskCreationStatus)
        );
        dispatch(
          setModuleFilteredQuestions({ questions: HwData.assessmentContent })
        );
        dispatch(setFetchedAssessmentDetails(HwData.assessment));
        const sequenceInfo = convertToSequenceInfo(
          HwData.questionsSequenceInfo
        );
        if (sequenceInfo) {
          dispatch(setSelectedTasksInfo(sequenceInfo));
        }
        navigate(`${HOMEWORK}${ASSESSMENT_CURRENT}/${moduleId}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  function hasTimePassed(milliseconds: string) {
    const currentTime = new Date().getTime();
    const targetTime = Number(milliseconds);
    return targetTime <= currentTime;
  }

  return loading === true ? (
    <SectionListSckeleton />
  ) : (
    <Box>
      <NewSectionList
        noContentMessage="No Homeworks for Today"
        isError={error}
        rightPanelWidth="1214"
        itemsPerPage={4}
        sectionTitle="Homeworks"
        handleSeeAll={seeAllClickHandler}
        items={teacher_hw_list?.slice(0, 20).map((hw) => (
          <InfoDisplayPanel
            defaultImage="homework-v1"
            image={getMediaBasePath(
              hw.assessmentPosterImgUrl,
              'processedMediaBucket'
            )}
            cardClickHandler={() =>
              cardClickHandler(
                hw.assessmentId,
                new Timestamp(hw.assessmentTargetDate),
                hw.moduleId
              )
            }
            variant={!isMobile && isIpad ? 'large' : 'small'}
            submissionType={hw.submissionType}
            blueSubText={`${hw.class}${hw.section} - ${hw.subject}`}
            mainHeading={hw.assessmentTitle}
            iconDetails={[
              { iconName: 'clock', text: `${hw.estimatedTimeInMin} mins` },
              {
                iconName: 'questions',
                text: `${hw.assessmentContentInfo?.numberOfQuestions}`,
              },
              {
                iconName: 'bar-graph',
                text: `${getDifficultyLevelString(hw.difficultyLevel)}`,
              },
            ]}
            status={<LessonPlanInfoPanelStatus lesson={hw} />}
          />
        ))}
      />
    </Box>
  );
}

interface InfoDisplayPanelStatus {
  lesson: AssessmentTask;
}
const LessonPlanInfoPanelStatus = ({ lesson }: InfoDisplayPanelStatus) => {
  // const taughtTime = getHumanReadableTimestampString(
  //   lesson.lastSessionTime
  // )?.split(' ');
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const editTime = getHumanReadableTimestampString(
    lesson.lastModifiedTime
  )?.split(' ');

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: `${pxTovW(2)} solid #E7E7E7D9`,
        pt: { xs: pxToRem(5), md: pxTovW(5) },
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: pxToRem(5), md: pxTovW(5) },
        }}
      >
        <ImageWrapper
          name="geneo-blue"
          type="png"
          parentFolder="icons"
          styles={{
            width: { xs: pxToRem(18), md: pxTovW(28) },
            height: { xs: pxToRem(18), md: pxTovW(28) },
            borderRadius: '50%',
          }}
          path={
            getMediaBasePath(lesson.teacherProfileImageUrl) ||
            firstLetterImage(lesson.teacherName) ||
            firstLetterImage('Geneo')
          }
        />
        <Typography variant="smallText" fontWeight="bold">
          {user_info?.teacherProfileId.toString() ===
          lesson?.teacherId?.toString()
            ? 'You'
            : lesson.teacherName || 'Geneo'}
        </Typography>
      </Box>

      {lesson.teacherName && (
        <Typography variant="subText" color="text.disabled">
          {editTime && Number(editTime[0]) < 2
            ? 'Recently Edited'
            : 'Edited ' + editTime?.join(' ')}
        </Typography>
      )}
    </Box>
  );
};
