import {
  ChapterResources,
  ChapterTopicInfo,
  CourseIndexInfo,
  FetchSubjectChapterInfo,
  TopicBasicInfo,
} from '@protos/content_management/content.common.apis_pb';
import {
  CloudUpload,
  TaskCreationStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { HomeworkTask } from '@protos/learning_management/lms.hw.common.apis_pb';
import {
  ModuleFilteredQuestions,
  QuestionStatsList,
  TaskInfoModel,
} from '@protos/learning_management/lms.hw.teacher.apis_pb';
import { createSlice } from '@reduxjs/toolkit';
import { calculateQuestionsCount } from '../../Homework/ReviewHomework/functions';
import {
  AssessmentsByType,
  AssessmentTask,
} from '@protos/learning_management/lms.assessment.common.apis_pb';
import { TeacherAssessAssignmentSubmit } from '@protos/learning_management/lms.assessment.teacher.apis_pb';

interface Chapter {
  chapterId: number;
  chapterTitle: string;
}

interface Topic {
  topicId: number;
  topicTitle: string;
}
export interface IAssessmentFlowState {
  assesment_flow_data?: AssessmentsByType;
  chapter_topic_data?: CourseIndexInfo;
  selected_chapter?: Chapter;
  selected_topic?: Topic;
  questions_count: IRequiredQuestionCountInfoForDifficultyLevel;
  module_filtered_questions: ModuleFilteredQuestions | undefined;
  selected_tasks_info?: TaskInfoModel[] | undefined;
  filtered_questions: ModuleFilteredQuestions | undefined;
  subjectwise_chapters_info?: FetchSubjectChapterInfo;
  chapterwise_topic?: ChapterTopicInfo;
  chapter_resources?: ChapterResources;
  submitted_assessment_id?: number;
  created_assessment_details?: TeacherAssessAssignmentSubmit;
  createdAssessmentStatus?: TaskCreationStatusEnum;
  fetched_assessment_details?: AssessmentTask;
  questions_stats?: QuestionStatsList;
  assessment_instructions: string[];
  custom_homework?: AssessmentTask;
  draft_uploaded_files?: CustomUploadFileResponse[];
  temporary_section_ids?: number[];
}
export interface IRequiredQuestionCountInfoForDifficultyLevel {
  noOfLowQuestions: number;
  noOfMediumQuestions: number;
  noOfHighQuestions: number;
}
export interface CustomUploadFileResponse {
  uploadFileData: CloudUpload;
  fileUploadStatus:
    | 'pending'
    | 'success'
    | 'Invalid Type'
    | 'Size Exceeded'
    | 'Upload Error';
  fileSize: number;
}

const initialState: IAssessmentFlowState = {
  assesment_flow_data: undefined,
  chapter_topic_data: undefined,
  selected_chapter: undefined,
  selected_topic: undefined,
  //homework shifted state
  subjectwise_chapters_info: undefined,
  chapterwise_topic: undefined,
  chapter_resources: undefined,
  filtered_questions: undefined,
  questions_count: {
    noOfLowQuestions: 0,
    noOfMediumQuestions: 0,
    noOfHighQuestions: 0,
  },
  module_filtered_questions: undefined,
  assessment_instructions: [
    'Answer all questions to complete the Homework assignment.',
    'Feel free to retry the Homework for better understanding.',
    'Ensure Homework completion by the specified Deadline.',
  ],
  custom_homework: undefined,
  draft_uploaded_files: [],
};

export const assessmentFlowSlice = createSlice({
  name: 'assessmentFlow',
  initialState,
  reducers: {
    setAssessmentData: (state, action) => {
      state.assesment_flow_data = action.payload;
    },
    setCourseIndexInfo: (state, action) => {
      state.chapter_topic_data = action.payload;
    },
    setSelectedChapter: (state, action) => {
      state.selected_chapter = action.payload;
    },
    setSelectedTopic: (state, action) => {
      state.selected_topic = action.payload;
    },
    resetSelectedChapter: (state) => {
      state.selected_chapter = undefined;
    },
    resetSelectedTopic: (state) => {
      state.selected_topic = undefined;
    },
    resetAssessmentFlowState: (state) => {
      return initialState;
    },
    setLowQuestionsCount: (state, action) => {
      if (state.questions_count) {
        const type = action.payload.type;
        if (type === 'inc') {
          state.questions_count.noOfLowQuestions += 1;
        } else if (
          type === 'dec' &&
          state.questions_count.noOfLowQuestions > 0
        ) {
          state.questions_count.noOfLowQuestions -= 1;
        }
      }
    },
    setMediumQuestionsCount: (state, action) => {
      if (state.questions_count) {
        const type = action.payload.type;
        if (type === 'inc') {
          state.questions_count.noOfMediumQuestions += 1;
        } else if (
          type === 'dec' &&
          state.questions_count.noOfMediumQuestions > 0
        ) {
          state.questions_count.noOfMediumQuestions -= 1;
        }
      }
    },
    setHighQuestionsCount: (state, action) => {
      if (state.questions_count) {
        const type = action.payload.type;
        if (type === 'inc') {
          state.questions_count.noOfHighQuestions += 1;
        } else if (
          type === 'dec' &&
          state.questions_count.noOfHighQuestions > 0
        ) {
          state.questions_count.noOfHighQuestions -= 1;
        }
      }
    },
    setQuestionsCount: (state) => {
      const question_list = JSON.parse(
        JSON.stringify(state.module_filtered_questions)
      );
      const newCount = calculateQuestionsCount(question_list);
      state.questions_count = newCount;
    },
    setModuleFilteredQuestions: (state, action) => {
      state.module_filtered_questions = action.payload;
    },
    deleteModuleFilteredQuestionsById: (state, action) => {
      const questionIdToDelete = action.payload;
      if (state.module_filtered_questions?.questions) {
        state.module_filtered_questions.questions =
          state.module_filtered_questions.questions.filter(
            (q) => q.questionId !== questionIdToDelete
          );
      }
    },
    addModuleFilteredQuestion: (state, action) => {
      if (state.module_filtered_questions) {
        state.module_filtered_questions.questions.push(action.payload);
      }
    },
    setSubjectWiseChaptersInfo: (state, action) => {
      // console.log('payload', action.payload);
      state.subjectwise_chapters_info = action.payload;
    },

    setChapterWiseTopicInfo: (state, action) => {
      state.chapterwise_topic = action.payload;
    },

    setChapterResources: (state, action) => {
      state.chapter_resources = action.payload;
    },
    setFilteredQuestions: (state, action) => {
      state.filtered_questions = action.payload;
    },
    setSelectedTasksInfo: (state, action) => {
      state.selected_tasks_info = action.payload;
    },
    setSubmittedAssessmentId: (state, action) => {
      state.submitted_assessment_id = action.payload;
    },
    setCreatedAssessmentDetails: (state, action) => {
      state.created_assessment_details = action.payload;
    },
    setCreatedAssessmentStatus: (state, action) => {
      state.createdAssessmentStatus = action.payload;
    },
    resetCreatedAssessmentStatus: (state) => {
      state.createdAssessmentStatus = initialState.createdAssessmentStatus;
    },
    setFetchedAssessmentDetails: (state, action) => {
      state.fetched_assessment_details = action.payload;
    },
    setQuestionStats: (state, action) => {
      state.questions_stats = action.payload;
    },
    setAssessmentInstructions: (state, action) => {
      state.assessment_instructions = action.payload;
    },
    setCustomHomework: (state, action) => {
      state.custom_homework = action.payload;
    },
    setDraftUploadedFiles: (state, action) => {
      state.draft_uploaded_files = action.payload;
    },
    setTemporarySectionIds: (state, action) => {
      state.temporary_section_ids = action.payload;
    },
    resetAssessmentInstructions: (state) => {
      state.assessment_instructions = initialState.assessment_instructions;
    },
    resetFetchedAssessmentDetails: (state) => {
      state.fetched_assessment_details =
        initialState.fetched_assessment_details;
    },
    resetSelectedTasksInfo: (state) => {
      state.questions_count = initialState.questions_count;
    },

    resetCustomHomework: (state) => {
      state.custom_homework = initialState.custom_homework;
    },
    resetDraftUploadedFiles: (state) => {
      state.draft_uploaded_files = initialState.draft_uploaded_files;
    },
  },
});

export const {
  setAssessmentData,
  setCourseIndexInfo,
  setSelectedChapter,
  setSelectedTopic,
  resetSelectedChapter,
  resetSelectedTopic,
  resetAssessmentFlowState,
  setLowQuestionsCount,
  setMediumQuestionsCount,
  setHighQuestionsCount,
  setModuleFilteredQuestions,
  setSubjectWiseChaptersInfo,
  setChapterWiseTopicInfo,
  setChapterResources,
  setFilteredQuestions,
  setSelectedTasksInfo,
  setSubmittedAssessmentId,
  setCreatedAssessmentDetails,
  deleteModuleFilteredQuestionsById,
  addModuleFilteredQuestion,
  resetSelectedTasksInfo,
  setCreatedAssessmentStatus,
  setFetchedAssessmentDetails,
  setQuestionStats,
  setCustomHomework,
  setDraftUploadedFiles,
  resetCreatedAssessmentStatus,
  resetFetchedAssessmentDetails,
  setQuestionsCount,
  setAssessmentInstructions,
  setTemporarySectionIds,
  resetAssessmentInstructions,
  resetCustomHomework,
  resetDraftUploadedFiles,
} = assessmentFlowSlice.actions;

export default assessmentFlowSlice.reducer;
