import { Code, ConnectError, Interceptor } from '@bufbuild/connect';
import {
  Loader as AppLoader,
  CommonServiceClientContextProvider,
  ManageSession,
  callTeacherEventsApi,
  clearLocalStorageKeys,
  deleteOfflineAccessKeyFromCache,
  getLocalStorage,
  pushUserDetailsV1ToAndroid,
  setLocalStorage,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { resetAnalyticsState } from '../../pages/Analytics/reducer/analytics.slice';
import { resetAuthState } from '../../pages/Auth/reducer/auth.slice';
import { resetHomeDashboardState } from '../../pages/Home/reducer/homeDashboard.slice';

import { resetManageHWState } from '../../pages/ManageHomework/reducer/manageHomework.slice';
import { resetTeachState } from '../../pages/Teach/reducer/teach.slice';
import { useAppSelector } from '../../reduxStore/reduxHooks';
import { LOGIN } from '../../routeHandling/RoutesNomenclature';
import { ConnectedClassContextProvider } from './ConnectedClassContextProvider';
import {
  DownloadContextProviderV2,
  useDownloadContext,
} from './DownloadContextProviderV2';
import { useGlobalContext } from './GlobalContextProvider';
import * as Sentry from '@sentry/react';
import { resetAssessmentFlowState } from '../../pages/Assessment/reducer/assessmentFlow.slice';

interface IProviderProps {
  children?: ReactNode;
}

export function ServiceClientProvider(props: IProviderProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAppLoader } = useGlobalContext();
  const { is_logged_in_offline, user_info } = useAppSelector(
    (state) => state.auth
  );
  const activeSessionId = getLocalStorage('activeSession');
  useEffect(() => {
    if (user_info?.teacherProfileId) {
      Sentry.setUser({
        id: user_info.teacherProfileId.toString(),
        email: user_info.email,
        username: user_info.userName, // Modify according to your user object structure
        name: user_info.firstName,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user_info]);

  const handleLogout = () => {
    dispatch(resetAuthState());
    dispatch(resetHomeDashboardState());
    dispatch(resetAssessmentFlowState());
    dispatch(resetManageHWState());
    dispatch(resetTeachState());
    dispatch(resetAnalyticsState());
    // localStorage.clear();
    clearLocalStorageKeys();
    deleteOfflineAccessKeyFromCache();
    navigate(LOGIN);
  };

  const loginInterceptor: Interceptor = (next) => async (req) => {
    if (
      is_logged_in_offline &&
      ![
        'validateLoginSession',
        'validateUser',
        'teacherLoginWithUsername',
      ].includes(req.method.name)
    ) {
      throw new ConnectError('Unverified user', Code.NotFound);
    }
    const res = await next(req);
    const token = res.header.get('X-Refresh-Token');
    const profileLoginDetailsId = getLocalStorage('profileLoginDetailsId');
    if (token && user_info && profileLoginDetailsId) {
      pushUserDetailsV1ToAndroid(
        user_info.teacherProfileId.toString(),
        token,
        profileLoginDetailsId,
        activeSessionId.toString()
      );
    }
    return res;
  };

  const headerInterceptor: Interceptor = (next) => async (req) => {
    if (process.env.NX_GENEO_FRONTEND_VERSION) {
      req.header.append(
        'X-Geneo-Version',
        process.env.NX_GENEO_FRONTEND_VERSION
      );
    }
    req.header.append('X-Application-Type', 'teacher');
    const res = await next(req);
    return res;
  };

  const getResponseStatusCodeFunctions = () => {
    return {
      '401': handleLogout,
    };
  };

  return (
    <CommonServiceClientContextProvider
      responseStatusCodeFunction={getResponseStatusCodeFunctions()}
      interceptors={[loginInterceptor, headerInterceptor]}
    >
      <DownloadContextProviderV2>
        <ConnectedClassContextProvider>
          <UpdateOfflineStatus />
          <TeacherEvents />
          <ManageSession role={ProfileRolesEnum.PROFILE_ROLE_TEACHER} />
          {showAppLoader && <AppLoader sx={{ position: 'fixed' }} />}
          <Outlet />
        </ConnectedClassContextProvider>
      </DownloadContextProviderV2>
    </CommonServiceClientContextProvider>
  );
}

export const TeacherEvents = () => {
  let isFetching = false;
  const POSTING_EVENTS_INTERVAL = 1000;
  const commonServiceClientContext = useCommonServiceClientContext();
  const { isOffline } = useDownloadContext();
  const { is_logged_in_offline } = useAppSelector((state) => state.auth);

  const fetchData = async () => {
    // needs discussion
    let auth = getLocalStorage('auth');
    if (auth === 'true' || auth === 'false') {
      auth = JSON.parse(auth);
    }
    // Skip if a fetch is already in progress || user is not logged in || if app is offline
    if (isFetching || !auth || isOffline) return;
    // setIsFetching(true);
    isFetching = true;
    try {
      // getLocalStorage('isOffline') === 'false' &&
      await callTeacherEventsApi(commonServiceClientContext);
    } catch (error) {
      console.error('Fetch error:', error);
    } finally {
      // setIsFetching(false);
      isFetching = false;
    }
  };

  useEffect(() => {
    const interval = setInterval(fetchData, POSTING_EVENTS_INTERVAL);
    return () => clearInterval(interval); // Cleanup on unmount
  }, [isOffline, is_logged_in_offline]);

  return null;
};

export const UpdateOfflineStatus = () => {
  const { setIsOffline } = useDownloadContext();

  useEffect(() => {
    const updateOnlineStatus = async () => {
      try {
        const pingUrl =
          process.env.NX_GENEO_BACKEND_URL || 'https://www.google.com';
        await fetch(pingUrl, { credentials: 'include' });
        setIsOffline(false);
        setLocalStorage('isOffline', 'false');
      } catch (error) {
        setIsOffline(true);
        setLocalStorage('isOffline', 'true');
      }
    };

    updateOnlineStatus(); // call initially
    const intervalId = setInterval(updateOnlineStatus, 30000); // Ping every 30 seconds
    window.addEventListener('online', updateOnlineStatus); // on status change in navigator - online
    window.addEventListener('offline', updateOnlineStatus); // on status change in navigator - offline
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return null;
};
