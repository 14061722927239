import {
  IStyles,
  ImageWrapper,
  ToggleSwitch,
  deserify,
  pxToRem,
  pxTovW,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';

import { Timestamp } from '@bufbuild/protobuf';
import {
  McqMultipleContentModel,
  McqSingleContentModel,
  PageContentInfo,
  Question,
  TfContentModel,
} from '@protos/content_management/content.db_pb';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  IResponses,
  useConnectedClassContext,
} from '../../../../../app/Context/ConnectedClassContextProvider';
import { useAppSelector } from '../../../../../reduxStore/reduxHooks';
import { calculateMarksMCQM } from '../../../../../utils/functions';
import { ResponseList, convertAnswerOptionstoNumbers } from '../responseList';
import { useGlobalContext } from '../../../../../app/Context/GlobalContextProvider';
const styles: IStyles = {
  root: {
    boxSizing: 'border-box',
    marginRight: { md: pxToRem(0), lg: pxTovW(240) },
    marginLeft: { md: pxToRem(20), lg: pxTovW(240) },
    padding: {
      xs: `${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)} `,
      md: 0,
    },
    position: 'relative',
  },
};

interface IProps {
  ViewResponses: boolean;
  pageContent?: PageContentInfo;
  doNotShowResponse: boolean;
}
export const LeftInfoPanel = (props: IProps) => {
  const { ViewResponses, pageContent, doNotShowResponse } = props;
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [showResponse, setShowResponse] = useState(false);
  const [questionResponses, setQuestionResponses] = useState<IResponses[]>([]);
  const { questionSessionData, baseStationData } = useConnectedClassContext(); //   const [ViewResponses, setViewResponses] = useState(false);
  const { students_data, question_session_details } = deserify(
    useAppSelector((state) => state.connectedClass)
  );
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isIpadOnly } = useGlobalContext();
  const { LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler } =
    useCommonServiceClientContext();

  const studentList = students_data?.map((student) => {
    const response = questionSessionData?.responses.find(
      (e) => e.keypadSn === student.clickerSerialNo
    );
    return {
      ...student,
      response: response, // Add the response data to each student object
    };
  });
  const location = useLocation();
  const connectedClassResourceSessionId =
    new URLSearchParams(location.search).get(
      'connectedClassResourceSessionId'
    ) || undefined;

  const question = pageContent?.contents[0].model.value as Question;
  const maxMarks = question.question
    ? question?.question.model.value?.commonQuestionContent?.marks.reduce(
        (acc, curr) => acc + curr,
        0
      ) || 0
    : 0;
  const correctAnswer =
    question.question?.model.value instanceof TfContentModel ||
    question.question?.model.value instanceof McqSingleContentModel ||
    question.question?.model.value instanceof McqMultipleContentModel
      ? question.question.model.value.correct
      : undefined;

  const totalStudents = studentList?.length || 0;
  const totalJoined =
    baseStationData?.keyPads.filter((keypad) => keypad.isOnline).length || 0;
  const totalAnswered =
    studentList?.filter((student) => student.response).length || 0;
  const totalCorrectAnswers =
    studentList?.filter(
      (student) =>
        student.response?.response &&
        convertAnswerOptionstoNumbers(student.response?.response) ===
          correctAnswer
    ).length || 0;
  const totalResponseTime =
    studentList?.reduce(
      (acc, student) => acc + (student.response?.timeSpent || 0),
      0
    ) || 0;
  const averageTime = totalAnswered
    ? Math.round((60 * totalResponseTime) / totalAnswered)
    : 0;
  const averageMarks = totalAnswered
    ? ((totalCorrectAnswers / totalAnswered) * maxMarks).toFixed(2)
    : 0;
  const totalMarksForMCQM =
    question?.question?.model.case === 'mcqMultipleContentModel'
      ? studentList?.reduce((acc, curr) => {
          const response = curr.response?.response;
          if (response) {
            const marks =
              calculateMarksMCQM(question, { answer: response.split('') }) ?? 0;
            return acc + marks;
          }
          return acc;
        }, 0)
      : undefined;
  const averageMarksforMcqM =
    totalMarksForMCQM && totalAnswered
      ? (totalMarksForMCQM / totalAnswered).toFixed(2)
      : 0;
  const totalCorrectAnswersForMcqM =
    question?.question?.model.case === 'mcqMultipleContentModel'
      ? studentList?.filter((student) => {
          const response = student.response?.response;
          return (
            response &&
            calculateMarksMCQM(question, { answer: response.split('') }) ===
              maxMarks
          );
        }).length
      : undefined;

  useEffect(() => {
    //hitting submitConnectedClassStudentResponse on each new of modified response
    if (
      questionSessionData &&
      questionSessionData.responses &&
      connectedClassResourceSessionId
    ) {
      // questionSessionData.responses.forEach(async (response) => {
      //   const student = students_data?.find(
      //     (student) => student.clickerSerialNo === response.keypadSn
      //   );
      //   if (student) {
      //     const previousResponse = questionResponses.find(
      //       (prevResponse) => prevResponse.keypadSn === response.keypadSn
      //     );
      //     // Check if the current response is different from the previous one
      //     if (
      //       !previousResponse ||
      //       previousResponse.response !== response.response
      //     ) {
      //       const res =
      //         await LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler.submitConnectedClassStudentResponse(
      //           {
      //             connectedClassSessionId: baseStationData?.sessionId
      //               ? BigInt(baseStationData.sessionId)
      //               : undefined,
      //             connectedClassResourceSessionId: BigInt(
      //               connectedClassResourceSessionId
      //             ),
      //             connectedClassQuestionSessionId:
      //               question_session_details?.connected_class_question_session_id,
      //             connectedClassStudentSessionId: students_data?.find(
      //               (e) => e.studentId === student.studentId
      //             )?.connectedClassSessionStudentId,
      //             questionId: pageContent?.questionIds[0],
      //             studentId: student.studentId,
      //             clickerId: student.clickerId.toString(),
      //             response: response.response,
      //             timeSpent: response.timeSpent,
      //           }
      //         );
      //     }
      //   }
      // });
      // Update previous responses after processing current responses
      setQuestionResponses(questionSessionData.responses);
    }
  }, [questionSessionData]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: { xs: '98%', md: pxTovW(500) },
        gap: { xs: pxToRem(27), md: pxTovW(30) },
        // backgroundColor: 'red',
        // justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        // justifyContent: 'center',
        maxHeight: '100vh',
        marginBottom: { xs: pxToRem(30) },
        // '& *': {
        //   background: doNotShowResponse ? 'lightgrey' : 'white',
        // },
      }}
    >
      {!largeScreen && !ViewResponses && (
        <Typography variant="h2" fontWeight="bold">
          Quiz has started. Now select your answers using clicker
        </Typography>
      )}
      {!largeScreen && !ViewResponses && (
        <Box
          sx={{
            width: '98%',
            borderRadius: { xs: pxToRem(11), md: pxTovW(15) },
            backgroundColor: '#FFFFFFFF',
            boxSizing: 'border-box',
            boxShadow: `0px 0px ${pxTovW(11)} #00000029`,
            padding: { xs: pxToRem(12), md: pxTovW(20) },
            display: 'flex',
            justifyContent: 'space-around',
            background: doNotShowResponse ? 'lightgrey' : 'white',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRight: '1px dashed lightGrey',
              gap: { xs: pxToRem(5), md: pxTovW(10) },
              paddingRight: { xs: pxToRem(27), md: pxTovW(15) },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: { md: pxTovW(10), alignItems: 'center' },
              }}
            >
              <ImageWrapper
                name="joined"
                parentFolder="icons"
                type="png"
                styles={{
                  height: { xs: pxToRem(26), md: pxTovW(40) },
                  width: { xs: pxToRem(26), md: pxTovW(40) },
                }}
              />
              <Typography variant="h3" fontWeight="bold">
                {totalJoined}/{totalStudents}
              </Typography>
            </Box>
            <Typography variant="h4">Joined</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRight: '1px dashed lightGrey',
              gap: { xs: pxToRem(5), md: pxTovW(10) },
              paddingRight: { xs: pxToRem(27), md: pxTovW(15) },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: { xs: pxToRem(5), md: pxTovW(10) },
                alignItems: 'center',
              }}
            >
              <ImageWrapper
                name="answered"
                parentFolder="icons"
                type="png"
                styles={{
                  height: { xs: pxToRem(26), md: pxTovW(40) },
                  width: { xs: pxToRem(26), md: pxTovW(40) },
                }}
              />
              <Typography variant="h3" fontWeight="bold">
                {totalAnswered}/{totalStudents}
              </Typography>
            </Box>
            <Typography variant="h4">Answered</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: { xs: pxToRem(5), md: pxTovW(10) },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: { xs: pxToRem(5), md: pxTovW(10) },
                alignItems: 'center',
              }}
            >
              <ImageWrapper
                name="corrected"
                parentFolder="icons"
                type="png"
                styles={{
                  height: { xs: pxToRem(26), md: pxTovW(40) },
                  width: { xs: pxToRem(26), md: pxTovW(40) },
                }}
              />
              <Typography variant="h3" fontWeight="bold">
                {totalCorrectAnswersForMcqM !== undefined
                  ? totalCorrectAnswersForMcqM
                  : totalCorrectAnswers}
              </Typography>
            </Box>
            <Typography variant="h4">Corrected</Typography>
          </Box>
        </Box>
      )}
      {!largeScreen && ViewResponses && (
        <Box
          sx={{
            width: '95%',
            borderRadius: { xs: pxToRem(11), md: pxTovW(15) },
            backgroundColor: '#FFFFFFFF',
            boxSizing: 'border-box',
            boxShadow: `0px 0px ${pxTovW(11)} #00000029`,
            padding: { xs: pxToRem(12), md: pxTovW(20) },
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: { xs: pxToRem(5) },

            background: doNotShowResponse ? 'lightgrey' : 'white',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRight: '1px dashed lightGrey',
              gap: { xs: pxToRem(5), md: pxTovW(10) },
              paddingRight: { xs: pxToRem(75), md: pxTovW(72) },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: { xs: pxToRem(5), md: pxTovW(10) },
                alignItems: 'center',
              }}
            >
              <ImageWrapper
                name="average-time"
                parentFolder="icons"
                type="png"
                styles={{
                  height: { xs: pxToRem(27), md: pxTovW(40) },
                  width: { xs: pxToRem(27), md: pxTovW(40) },
                }}
              />
              <Typography variant="h3" fontWeight="bold">
                {averageTime} secs
              </Typography>
            </Box>
            <Typography variant="h4">Average Time</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: { md: pxTovW(10), alignItems: 'center' },
              }}
            >
              <ImageWrapper
                name="average-score"
                parentFolder="icons"
                type="png"
                styles={{
                  height: { xs: pxToRem(27), md: pxTovW(40) },
                  width: { xs: pxToRem(27), md: pxTovW(40) },
                }}
              />
              <Typography variant="h3" fontWeight="bold">
                {totalMarksForMCQM !== undefined
                  ? averageMarksforMcqM
                  : averageMarks}
              </Typography>
            </Box>
            <Typography variant="h4">Average Marks</Typography>
          </Box>
        </Box>
      )}
      {(largeScreen || ViewResponses) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: { xs: '100%', md: '98%' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: { md: pxTovW(5) },
              marginBottom: { xs: pxToRem(16), md: pxTovW(16) },
              background: doNotShowResponse ? 'lightgrey' : 'white',
            }}
          >
            <Typography
              variant={
                isMobile ? 'h2' : isIpad ? 'h3' : largeScreen ? 'h2' : 'h2'
              }
              fontWeight="medium"
            >
              View Responses
            </Typography>
            <ToggleSwitch
              checked={doNotShowResponse === true ? false : showResponse}
              handleChange={() =>
                doNotShowResponse === false && setShowResponse(!showResponse)
              }
            />
          </Box>
          <ResponseList
            pageContent={pageContent}
            showResponse={doNotShowResponse === true ? false : showResponse}
          />
        </Box>
      )}
    </Box>
  );
};

const cdcIconDetails = [
  {
    iconName: 'clock',
    text: '20 Min',
  },
  {
    iconName: 'questions',
    text: 'Questions',
  },
];
