import { Box, Typography } from '@mui/material';

import {
  IStyles,
  ImageWrapper,
  InstructionsPanel,
  Loader,
  QuestionContainerWithSolution,
  cloneObject,
  deserify,
  getLocalStorage,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Question } from '@protos/content_management/content.db_pb';
import {
  SubmissionType,
  TaskEnum,
} from '@protos/learning_management/lms.db_pb';
import { HomeworkContent } from '@protos/learning_management/lms.hw.common.apis_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';

import { ViewHwStatCard } from './components/ViewHwStatCard';
import {
  aiHomeworkOpenEvent,
  aiHomeworkViewEvent,
} from '../../../Homework/homework.events';
import { useLmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler } from '@geneo2-web/services-clients';
import { AssessmentContent } from '@protos/learning_management/lms.assessment.common.apis_pb';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';

const styles: IStyles = {
  root: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    p: {
      xs: `${pxToRem(20)} ${pxToRem(18)} `,
      lg: `${pxTovW(37)} ${pxTovW(240)}`,
    },
    gap: { xs: pxToRem(20), lg: pxTovW(30) },
  },
  mainContainer: {
    display: 'flex',
    // alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: { xs: pxToRem(0), md: pxTovW(20) },
    position: 'relative',
    // backgroundColor: 'red',
  },

  physicalSubmission: {
    width: { xs: '95%', md: '100%' },
    height: { xs: pxToRem(286), lg: pxTovW(455) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(17), md: pxTovW(30) },
    justifyContent: 'center',
    textAlign: 'center',
    background: '#F5FAFF',
    border: '1px solid #93CBFF',
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
    margin: 'auto',
    marginBottom: { xs: pxToRem(20), md: 'unset' },
  },

  rightPanel: {
    flexBasis: { lg: pxTovW(793) },

    overflow: 'auto',
  },
  leftPanel: {
    display: 'flex',
    // backgroundColor: 'blue',
    flexDirection: 'column',
    width: { md: pxTovW(800), lg: 'unset' },
  },
  secondaryHeadingBox: {
    mb: { xs: pxToRem(20), md: pxTovW(20) },
    pl: { xs: pxToRem(20), md: '0' },
  },

  userImage: {
    width: { xs: pxToRem(60), md: pxTovW(87) },
    height: { xs: pxToRem(60), md: pxTovW(87) },
  },

  questionBox: {
    height: '70vh',
    overflow: 'auto',
  },

  instructionsPanel: {
    width: { xs: '95%', md: pxTovW(814) },
    height: { md: '70vh' },
    maxHeight: { xs: pxToRem(420), md: pxTovW(666) },
    margin: 'auto',
    marginBottom: { xs: pxToRem(20), md: 'unset' },
    backgroundColor: 'red',
  },
};
interface ViewAssessmentProps {
  status: 'assigned' | 'ended';
}

export const ViewAssessment = (props: ViewAssessmentProps) => {
  const teacher_id = getLocalStorage('userId');
  // const { homework_id } = useParams();
  const [assessmentContent, setAssessmentContent] = useState<
    AssessmentContent | undefined
  >();
  const { isOffline } = useDownloadContext();
  const { current_assessmemt_classStats, selected_assessment_id } = deserify(
    useAppSelector((state) => state.manageHomework)
  );
  // console.log('selected_hw_id', selected_hw_id);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const {
    LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const getHomeworkDetails = async () => {
    try {
      setLoading(true);
      const response =
        await LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchAssessmentContent(
          {
            personId: teacher_id,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            assessmentId: selected_assessment_id,
          }
        );
      if (response.data) {
        await aiHomeworkOpenEvent({
          homeworkId: selected_assessment_id,
          isOffline: isOffline,
        });
        await aiHomeworkViewEvent({
          homeworkId: selected_assessment_id,
          isOffline: isOffline,
        });
        const hw = response.data;
        const taskQuestionInfo =
          hw.questionsSequenceInfo?.taskQuestionInfo || [];
        const sortedQuestionIds = taskQuestionInfo
          .sort((a, b) => a.sequence - b.sequence)
          .map((val) => val.questionId)
          .filter((val) => val !== undefined) as string[];
        const sortedHwArray = sortedQuestionIds
          .map((id) =>
            hw.assessmentContent.find((elem) => elem.questionId === id)
          )
          .filter((elem) => elem !== undefined) as Question[];
        const updatedHw = cloneObject(hw);
        updatedHw.assessmentContent = sortedHwArray;
        setAssessmentContent(updatedHw);
        setLoading(false);
        // console.log(response.data);
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };
  useEffect(() => {
    if (selected_assessment_id && teacher_id) {
      getHomeworkDetails();
    }
  }, []);

  return loading === true ? (
    <Loader />
  ) : error === true ? (
    <Typography>Error Occured</Typography>
  ) : (
    <Box sx={styles.root}>
      <Typography variant="h1" fontWeight="bold">
        View Assessment
      </Typography>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.leftPanel}>
          <ViewHwStatCard
            status={props.status}
            homework={assessmentContent?.assessment}
          />

          {assessmentContent?.assessment?.submissionType ===
            SubmissionType.SUBMISSION_OFFLINE && (
            <Box sx={styles.physicalSubmission}>
              <Typography variant="h2" fontWeight={600}>
                Physical Submission
              </Typography>
              <ImageWrapper
                name="physicalSubmission"
                type="png"
                styles={{
                  width: { xs: pxToRem(219), lg: pxTovW(313) },
                  height: { xs: pxToRem(153), lg: pxTovW(241) },
                  alignSelf: 'center',
                }}
              />
              <Typography
                variant="h3"
                sx={{
                  width: { xs: pxToRem(219), lg: pxTovW(313) },
                  alignSelf: 'center',
                }}
              >
                Submit Your Homework Directly To Your Teacher
              </Typography>
            </Box>
          )}
        </Box>

        {assessmentContent?.assessment?.taskType !==
        TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM ? (
          <Box sx={styles.rightPanel}>
            <Box sx={styles.secondaryHeadingBox}>
              <Typography variant="h2" fontWeight="bold">
                Assessment Questions
              </Typography>
            </Box>
            {assessmentContent?.assessmentContent.map((que, quePos) => (
              <Box
                key={quePos}
                sx={{
                  marginBottom: { xs: pxToRem(20), md: pxTovW(20) },
                  px: { xs: pxToRem(16), md: 0 },
                }}
              >
                <QuestionContainerWithSolution
                  questionNumber={quePos + 1}
                  question={que}
                  showAnswer={true}
                  showQuestionStats
                  disableInput={true}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              width: { xs: '95%', md: pxTovW(950), lg: pxTovW(814) },
            }}
          >
            <InstructionsPanel
              customSx={{
                // backgroundColor: 'red',
                display: 'flex',
                flexGrow: 1,
                width: '100%',
              }}
              Katexvalue={
                assessmentContent.assessment.assessmentInstructions[0]
              }
              AttachmentList={
                assessmentContent.assessment.taskUploads.filter(
                  (e) => e.isDeleted === false
                ) || undefined
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
