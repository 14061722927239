import { Box, Typography } from '@mui/material';

import {
  IStyles,
  IconWrapper,
  ImageWrapper,
  InfoBar,
  ScoreProgressBar,
  formatDateAsDayMonth,
  getDifficultyLevelString,
  getMediaBasePath,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';

import { TaskEnum } from '@protos/learning_management/lms.db_pb';
import { HomeworkTask } from '@protos/learning_management/lms.hw.common.apis_pb';
// import InfoBar from '../../ReviewHomework/components/InfoBar';
import { useGlobalContext } from '../../../../../app/Context/GlobalContextProvider';
import ViewReportButton from '../../../../ManageHomework/components/ViewReportButton';
import { AssessmentTask } from '@protos/learning_management/lms.assessment.common.apis_pb';

const styles: IStyles = {
  root: {
    // border: '1px solid red',
    mb: { xs: pxToRem(20), md: pxTovW(20) },
    p: {
      // xs: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
      md: `${pxTovW(0)}`,
    },
    width: { md: '100%', lg: 'unset' },
  },

  userDetailBox: {
    display: 'flex',
    gap: { xs: pxToRem(10), md: pxTovW(20) },
    // mt: { xs: pxToRem(16), md: pxTovW(22) },
  },
  userImage: {
    width: { xs: pxToRem(62), lg: pxTovW(124) },
    height: { xs: pxToRem(62), lg: pxTovW(124) },
    borderRadius: { xs: pxToRem(15), lg: pxTovW(15) },
    objectFit: 'cover',
  },
  userNameBox: {
    flexGrow: '1',
    width: { xs: '80%', lg: pxTovW(350) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(5), md: pxTovW(3) },
  },
  scoreProgressBarBox: {
    width: '70%',
    mt: { xs: pxToRem(10), md: pxTovW(20) },
    display: { xs: 'none', md: 'inline' },
  },

  marksBox: {
    width: { md: pxTovW(512) },
    mt: { xs: pxToRem(21), md: pxTovW(32) },
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: {
      xs: `0px 0px ${pxTovW(30)} #0000001F`,
      md: `0px 0px ${pxTovW(10)} #0000001F`,
    },
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
    p: {
      xs: `${pxToRem(15)} ${pxToRem(20)}`,
      md: `${pxTovW(25)} ${pxTovW(30)}`,
    },
  },
};

interface IProps {
  homework?: AssessmentTask;
  status: 'assigned' | 'ended';
}
export const ViewHwStatCard = (props: IProps) => {
  const { homework, status } = props;
  const { isIpadOnly } = useGlobalContext();
  return (
    <Box sx={styles.root}>
      <Box sx={styles.userDetailBox}>
        <ImageWrapper
          name="lessonImage1"
          type="png"
          parentFolder="tempAssets"
          // onClick={flagClickHandler}
          styles={styles.userImage}
          path={getMediaBasePath(
            homework?.assessmentPosterImgUrl,
            'processedMediaBucket'
          )}
        />

        <Box sx={styles.userNameBox}>
          <Typography
            variant={isIpadOnly ? 'h1' : 'h2'}
            fontWeight="bold"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              wordWrap: 'break-word',
            }}
          >
            {homework?.assessmentTitle}
          </Typography>

          <Typography
            variant={isIpadOnly ? 'bodyText' : 'button'}
            color="primary"
            fontWeight="regular"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {`${homework?.class}${homework?.section} ${homework?.subject} | ${homework?.moduleName}`}
          </Typography>

          {status === 'assigned' ? (
            <Box sx={styles.scoreProgressBarBox}>
              <ScoreProgressBar
                score={
                  Math.round(Number(homework?.classScore?.toFixed(2))) || 0
                }
                variant="lg"
              />
            </Box>
          ) : null}
        </Box>
      </Box>

      {homework?.taskType !== TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM ? (
        <Box sx={styles.marksBox}>
          <MarksComp
            icon="clock"
            label={`${homework?.estimatedTimeInMin} Minutes`}
          />
          <MarksComp
            icon="questions"
            label={`${homework?.assessmentContentInfo?.numberOfQuestions} Questions`}
          />
          <MarksComp
            icon="bar-graph"
            label={`${getDifficultyLevelString(homework?.difficultyLevel)}`}
          />
        </Box>
      ) : (
        <Box
          sx={{
            mt: { xs: pxToRem(21), md: pxTovW(32) },
          }}
        >
          <InfoBar
            contentList={[
              {
                iconName: 'calender',
                heading: formatDateAsDayMonth(homework?.assessmentTargetDate),
                subHeading: 'Deadline',
              },
              {
                iconName: 'profile',
                heading: `${homework?.studentsSubmissionCount}/${homework?.assignedStudentsCount}`,
                subHeading: 'Submitted',
              },
              ...(homework?.maxMarks
                ? [
                    {
                      iconName: 'marks',
                      heading: homework?.maxMarks?.toString() || '0',
                      subHeading: 'Marks',
                    },
                  ]
                : []),
            ]}
          />
        </Box>
      )}
      {status === 'assigned' &&
        homework?.assessmentId &&
        homework?.taskType !== TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM && (
          <ViewReportButton homeworkId={homework.assessmentId} />
        )}
    </Box>
  );
};

//^ Local component

const markStyles: IStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: { xs: pxToRem(15), md: pxTovW(15) },
  },

  icon: {
    width: { xs: pxToRem(31), md: pxTovW(31) },
    height: { xs: pxToRem(31), md: pxTovW(31) },
  },
};

interface IMarksComp {
  icon: string;
  label: string;
}
const MarksComp = ({ icon, label }: IMarksComp) => {
  return (
    <Box sx={markStyles.root}>
      <IconWrapper
        name={icon}
        parentFolder="icons"
        type="png"
        customSx={markStyles.icon}
      />

      <Typography variant="h3" color="text.primary" fontWeight="bold">
        {label}
      </Typography>
    </Box>
  );
};
